import React from 'react'

const Loading = () => {
    return (
        <React.Fragment>
            <div className="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <h2>Please wait</h2>
            </div>
        </React.Fragment>
    )
}

export default Loading