import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GreenOutButton, RedOutButton } from '../components/Buttons';
import { useAuth } from '../components/context/Auth';
import { Danger, Eye, EyeHide, Spinner } from '../components/icons/Icon';
import styles from './Home.module.css';

const Home = () => {
  const navigate = useNavigate();
  const { setDesignation, setManagerId, setManagerName, setLoggedIn } = useAuth();
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
    error: '',
  });
  const [msg, setMsg] = useState("")
  const [show, setShow] = useState(false)
  const divStyle = {
    display: show ? 'block' : 'none'
  };
  const [eyeSH, setEyeSH] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);

    var raw = JSON.stringify({
      "loginId": credentials.username,
      "password": credentials.password,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "managers/login", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "success") {
          sessionStorage.setItem("designations", result.user.designation)
          sessionStorage.setItem("designation", result.user.designation[0])
          sessionStorage.setItem("name", result.user.name)
          sessionStorage.setItem("managerId", result.user.managerId)
          setDesignation(result.user.designation[0])
          setManagerId(result.user.managerId)
          setManagerName(result.user.name)
          setLoggedIn(true)
          navigate('/dashboard')
        } else {
          setCredentials({ ...credentials, error: result.msg })
        }
        setMsg(result.msg)
        setLoading(false)
      })
      .catch(error => console.log('error', error));
  }

  var designations = sessionStorage.getItem("designation");
  useEffect(() => {
    if (designations) {
      setDesignation(designations.split(',')[0]);
    }
  }, [setDesignation, designations])

  return (
    <React.Fragment>
      <div className={styles.AppHome}>
        <h1>Warning!</h1>
        <Danger className={styles.danger} />
        <h2>The Site ahead contains Malware &amp; Virus!</h2>
        <p>Attackers may trick you into doing something dangerous like installing software which may harm your computer or revealing your personal information (for example, passwords, phone numbers or credit cards).</p>
        <div >
          <GreenOutButton title="Back to Safety" css={styles.btnW} />
          <RedOutButton title="Proceed Anyway" css={styles.btnW + " " + styles.ms50 + " " + styles.px10} handleSubmit={() => setShow(true)} />
        </div>
      </div>

      {/* ------------------------Login Modal-------------- */}
      <div
        className={styles.custom_modal}
        onClick={() => setShow(false)}
        style={divStyle}>
        <div className={`${styles.center} ${styles.text_center} ${styles.modal_content}`} onClick={e => e.stopPropagation()} >
          <div className={styles.center}>
            <h1>Login</h1>
            <input
              type="text"
              placeholder='Enter Login Id'
              className={styles.loginInput}
              value={credentials.username}
              onChange={e => setCredentials({ ...credentials, username: e.target.value })}
            />
            <br />
            <br />
            <input
              type={eyeSH ? "text" : "password"}
              placeholder='Enter Password'
              className={styles.loginInput}
              value={credentials.password}
              onChange={e => setCredentials({ ...credentials, password: e.target.value })}
            />
            {eyeSH ?
              <EyeHide className={styles.loginEye} onClick={() => setEyeSH(false)} />
              :
              <Eye className={styles.loginEye} onClick={() => setEyeSH(true)} />
            }
            <div className={styles.err}>{msg}</div>
            <br />
            <br />
            <GreenOutButton title={loading ? <Spinner size="15" /> : "Login"} handleSubmit={() => { handleClick() }} />

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home