import React, { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../components/context/Auth';
import styles from '../pages/InventoryMob.module.css'
import { Search } from '../../components/icons/Icon';
import ModalMob from '../components/ModalMob';
import { BlackOutButton } from '../../components/Buttons';
import DispatchModal from '../components/DispatchModal';
const CollectionInfoMob = () => {
    const { managerId } = useAuth()
    // const navigate = useNavigate();
    const params = useParams()
    const [searchparams] = useSearchParams()
    const product_id = searchparams.get("product_id")
    const collection_id = searchparams.get("collection_id")
    const collection_name = params.collection;
    const [inputSearch, setInputSearch] = useState('')
    const [items, setItems] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [modalShownext, setModalShownext] = useState(false)
    const [collectionInfoModal, setCollectionInfoModal] = useState(false)
    const [msgShow, setMsgShow] = useState("");
    const [loading, setLoading] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [totalItems, setTotalItems] = useState({})
    const [collectionInfo, setCollectionInfo] = useState({
        warehouse: '',
        description: '',
        hscode: '',
        unit: '',
        odp: '',
        dp: '',
        dmrp: '',
        cu: '',
        cp: '',
        cmrp: '',
        mcp: '',
        vm: '',
        accessories: [],
    })

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            setItems([])
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "product_id": product_id,
                "collection_id": collection_id,
                "warehousename": collectionInfo.warehouse,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/collection-info", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        if (result.totalItems) {
                            setTotalItems(prevState => ({
                                ...prevState,
                                weight: result.totalItems.weight ? result.totalItems.weight : 0,
                                openingstock: result.totalItems.openingstock ? result.totalItems.openingstock : 0,
                                purchase: result.totalItems.purchase ? result.totalItems.purchase : 0,
                                sold: result.totalItems.sold ? result.totalItems.sold : 0,
                                adjust: result.totalItems.adjust ? result.totalItems.adjust : 0,
                                closing: result.totalItems.closing ? result.totalItems.closing : 0,
                            }))
                        }
                        if (result.warehouses) {
                            setWarehouses(result.warehouses)
                        }
                        if (result.collectionInfo) {
                            setCollectionInfo(prevState => ({
                                ...prevState,
                                description: result.collectionInfo.inventdesc ? result.collectionInfo.inventdesc : "",
                                hscode: result.collectionInfo.hscode ? result.collectionInfo.hscode : "",
                                unit: result.collectionInfo.unit ? result.collectionInfo.unit : "",
                                odp: result.collectionInfo.odp ? result.collectionInfo.odp : "",
                                dp: result.collectionInfo.dp ? result.collectionInfo.dp : "",
                                dmrp: result.collectionInfo.dmrp ? result.collectionInfo.dmrp : "",
                                cu: result.collectionInfo.cu ? result.collectionInfo.cu : "",
                                cp: result.collectionInfo.cp ? result.collectionInfo.cp : "",
                                cmrp: result.collectionInfo.cmrp ? result.collectionInfo.cmrp : "",
                                mcp: result.collectionInfo.mcp ? result.collectionInfo.mcp : "",
                                vm: result.collectionInfo.vm ? result.collectionInfo.vm : "",
                                accessories: result.collectionInfo.accessories ? result.collectionInfo.accessories : "",
                            }))
                        }
                        if (result.items) {
                            setItems(result.items)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, product_id, collection_id, collectionInfo.warehouse])

    const handleSubmitCollectionInfo = () => {
        setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "collection_id": collection_id,
            "inventdesc": collectionInfo.description,
            "hscode": collectionInfo.hscode,
            "unit": collectionInfo.unit,
            "odp": collectionInfo.odp,
            "dp": collectionInfo.dp,
            "cu": collectionInfo.cu,
            "cp": collectionInfo.cp,
            "mcp": collectionInfo.mcp,
            "vm": collectionInfo.vm,
            "dmrp": collectionInfo.dmrp,
            "cmrp": collectionInfo.cmrp,
            "accessories": collectionInfo.accessories
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "inventory/update-collection", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setMsgShow(result.message)
                    setCollectionInfoModal(true)
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <div>
            <h1 className={styles.main_heading1}>{collection_name.toUpperCase()}</h1>
            {loading ? <div className={'loadingMain'}>
                <img src='/wallicon.gif' alt='walliconGIF' />
            </div> :
                <div>
                    <div className={styles.statusCard_div}>
                        <div className={styles.statuscard1}>
                            <div className={styles.statusCard}>
                                <span className={styles.statusOrange}>{totalItems.weight}</span>
                                <span className={styles.statusOrange}>Weight</span>
                            </div>
                        </div>
                        <div className={styles.statuscard1}>
                            <div className={styles.statusCard}>
                                <span className={styles.statusNew}>{totalItems.openingstock}</span>
                                <span className={styles.statusNew}>Opening</span>
                            </div>
                        </div>
                        <div className={styles.statuscard1}>
                            <div className={styles.statusCard}>
                                <span className={styles.statusYellow}>{totalItems.purchase}</span>
                                <span className={styles.statusYellow}>Purchase</span>
                            </div>
                        </div>
                        <div className={styles.statuscard1}>
                            <div className={styles.statusCard}>
                                <span className={styles.statusGreen}>{totalItems.sold}</span>
                                <span className={styles.statusGreen}>Sold</span>
                            </div>
                        </div>
                        <div className={styles.statuscard1}>
                            <div className={styles.statusCard}>
                                <span className={styles.statusRed}>{totalItems.adjust}</span>
                                <span className={styles.statusRed}>Adjust</span>
                            </div>
                        </div>
                        <div className={styles.statuscard1}>
                            <div className={styles.statusCard}>
                                <span className={styles.statusWhite}>{totalItems.closing}</span>
                                <span className={styles.statusWhite}>Closing</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.ItemsCollection_div}>
                        <div className={styles.seacrh_div}>
                            <label className={styles.search_Icon_filter}>{inputSearch.length === 0 && <Search color="#000" />}</label>
                            <input
                                type="search"
                                placeholder='Search item'
                                className={styles.seacrh_inputField_filter}
                                value={inputSearch}
                                onChange={(e) => { setInputSearch(e.target.value) }}
                            />
                        </div>

                        <select
                            className={styles.selectDropdown_filter}
                            value={collectionInfo.warehouse}
                            onChange={(e) => setCollectionInfo({ ...collectionInfo, warehouse: e.target.value })}
                        >
                            <option value="">All Warehouse</option>
                            {warehouses && warehouses.map((item, index) =>
                                <option key={index} value={item.warehousename}>{item.warehousename}</option>
                            )}
                        </select>

                        <button className={styles.collectionbtn} onClick={() => setModalShow(true)}>
                            Collection Info
                        </button>
                    </div>


                    <div className={styles.item_collection}>Items In Collection</div>
                    <div>
                        {items && items.map((item, index) =>
                            <div className={styles.box} key={index}>
                                <div className={styles.mob_flex}>
                                    <h6 className={styles.product}>
                                        {item.itemNo}
                                    </h6>
                                    <div
                                        className={styles.status_div2}>
                                        <input type="radio" defaultChecked={item.status === "Active" ? true : false} className={styles.status_active} />
                                        &nbsp;
                                        <span className={styles.status}>Active</span>
                                    </div>
                                </div>
                                <div className={styles.flex_coll}>
                                    <div className={styles.imagediv}>
                                        <img src='/assets/noimage.jpg' alt='' className={styles.product_img} />
                                    </div>
                                    {/* <div className={styles.box_div}> */}
                                    <div>
                                        <div>
                                            <div className={styles.colltext}>Weight</div>
                                            <input
                                                type='number'
                                                className={styles.coll_box}
                                                value={item.weight}
                                                onChange={(e) => {
                                                    const list = [...items];
                                                    list[index]["weight"] = e.target.value;
                                                    setItems(list);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div className={styles.colltext}>Purchase</div>
                                            <input
                                                type='number'
                                                className={styles.coll_box}
                                                value={item.purchase}
                                                onChange={(e) => { }}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div className={styles.colltext}>Opening</div>
                                            <input
                                                type='number'
                                                className={styles.coll_box}
                                                value={item.openingstock}
                                                onChange={(e) => {
                                                    const list = [...items];
                                                    list[index]["openingstock"] = e.target.value;
                                                    setItems(list);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div className={styles.colltext}>Sold</div>
                                            <input
                                                type='number'
                                                className={styles.coll_box}
                                                value={item.sold}
                                                onChange={(e) => { }}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div className={styles.colltext}>Adjust</div>
                                            <input
                                                type='number'
                                                className={styles.coll_box}
                                                value={item.adjust}
                                                onChange={(e) => { }}
                                                disabled
                                            />
                                        </div>
                                        <div >
                                            <div className={styles.colltext}>Closing</div>
                                            <input
                                                type='number'
                                                className={styles.coll_box}
                                                value={item.closing}
                                                onChange={(e) => { }}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.outsourcedProduct}>
                                    <div className={styles.outsourcetext}>Outsourced Product</div>
                                    <div className={styles.outsourcedStatus}>
                                        <div>
                                            <input type="radio" disabled={item.outsourcedProduct === "Yes" ? false : true} defaultChecked={item.outsourcedProduct === "Yes" ? true : false} className={styles.status_active} /> Yes</div>
                                        <div className={styles.margin_left}>
                                            <input type="radio" disabled={item.outsourcedProduct === "No" ? false : true} defaultChecked={item.outsourcedProduct === "No" ? true : false} className={styles.status_active} /> No</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }



            <ModalMob
                show={modalShow}
                close={setModalShow}
                closeBtn={true}
                heading=" "
                content={
                    <div className={styles.dispatch_select}>
                        <div className={styles.dot_flex}>
                            <div >&#9679;</div>&nbsp;
                            <div className={styles.color} onClick={() => { setModalShownext(true); setModalShow(false) }}>&#9679;</div>
                        </div>
                        <div className={styles.collectionInfo_div}>
                            <div className={styles.collection_div}>
                                {/* <div className={styles.details_div}>
                                    <div className={styles.details_txt}>Warehouse</div>
                                    <select
                                        className={styles.selectDropdown}
                                        value={collectionInfo.warehouse}
                                        onChange={(e) => setCollectionInfo({ ...collectionInfo, warehouse: e.target.value })}
                                    >
                                        <option value="">All Warehouse</option>
                                        {warehouses && warehouses.map((item, index) =>
                                            <option key={index} value={item.warehousename}>{item.warehousename}</option>
                                        )}
                                    </select>
                                </div> */}
                                <div className={styles.details_div}>
                                    <div className={styles.details_txt}>Description</div>
                                    <input
                                        type='text'
                                        className={styles.inputField}
                                        value={collectionInfo.description}
                                        onChange={(e) => setCollectionInfo({ ...collectionInfo, description: e.target.value })}
                                    />
                                </div>
                                <div className={styles.details_div}>
                                    <div className={styles.details_txt}>HS Code</div>
                                    <input
                                        type='text'
                                        className={styles.inputField}
                                        value={collectionInfo.hscode}
                                        onChange={(e) => setCollectionInfo({ ...collectionInfo, hscode: e.target.value })}
                                    />
                                </div>
                                <div className={styles.details_div}>
                                    <div className={styles.details_txt}>Unit</div>
                                    <select
                                        className={styles.selectDropdown}
                                        value={collectionInfo.unit}
                                        onChange={(e) => setCollectionInfo({ ...collectionInfo, unit: e.target.value })}
                                    >
                                        <option value="" hidden>Select</option>
                                        <option value="Roll">Roll</option>
                                        <option value="Pcs">Pcs</option>
                                        <option value="Box">Box</option>
                                        <option value="Sq Meter">Sq Meter</option>
                                        <option value="Meter">Meter</option>
                                        <option value="Sq Feet">Sq Feet</option>
                                        <option value="Feet">Feet</option>
                                        <option value="Kg">Kg</option>
                                    </select>
                                </div>
                                <div className={styles.details_div}>
                                    <div className={styles.details_txt}>Dealer MRP</div>
                                    <input
                                        type='number'
                                        className={styles.inputField}
                                        value={collectionInfo.dmrp}
                                        onChange={(e) => setCollectionInfo({ ...collectionInfo, dmrp: e.target.value })}
                                    />
                                </div>
                                <div className={styles.details_div}>
                                    <div className={styles.details_txt}>Offline Price</div>
                                    <input
                                        type='number'
                                        className={styles.inputField}
                                        value={collectionInfo.odp}
                                        onChange={(e) => setCollectionInfo({ ...collectionInfo, odp: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.collection_flex}>
                            <button className={styles.collection_button} onClick={() => setModalShow(false)}>CANCEL</button>
                            <button className={styles.collection_button} onClick={() => { setModalShownext(true); setModalShow(false) }}>NEXT</button>
                        </div>
                    </div>
                }
            />
            <ModalMob
                show={modalShownext}
                close={setModalShownext}
                closeBtn={true}
                heading=' '
                content={
                    <div className={styles.dispatch_select}>
                        <div className={styles.dot_flex}>
                            <div className={styles.color} onClick={() => { setModalShow(true); setModalShownext(false) }}>&#9679;</div>&nbsp;
                            <div>&#9679;</div>
                        </div>
                        <div className={styles.collectionInfo_div}>

                            <div className={styles.details_div}>
                                <div className={styles.details_txt}>Dealer Price</div>
                                <input
                                    type='number'
                                    className={styles.inputField}
                                    value={collectionInfo.dp}
                                    onChange={(e) => setCollectionInfo({ ...collectionInfo, dp: e.target.value })}
                                />
                            </div>
                            <div className={styles.details_div}>
                                <div className={styles.details_txt}>Cust Unit</div>
                                <select
                                    className={styles.selectDropdown}
                                    value={collectionInfo.cu}
                                    onChange={(e) => setCollectionInfo({ ...collectionInfo, cu: e.target.value })}
                                >
                                    <option value="" hidden>Select</option>
                                    <option value="Roll">Roll</option>
                                    <option value="Pcs">Pcs</option>
                                    <option value="Box">Box</option>
                                    <option value="Sq Meter">Sq Meter</option>
                                    <option value="Meter">Meter</option>
                                    <option value="Sq Feet">Sq Feet</option>
                                    <option value="Feet">Feet</option>
                                    <option value="Kg">Kg</option>
                                </select>
                            </div>
                            <div className={styles.details_div}>
                                <div className={styles.details_txt}>Cust MRP</div>
                                <input
                                    type='number'
                                    className={styles.inputField}
                                    value={collectionInfo.cmrp}
                                    onChange={(e) => setCollectionInfo({ ...collectionInfo, cmrp: e.target.value })}
                                />
                            </div>
                            <div className={styles.details_div}>
                                <div className={styles.details_txt}>Cust Price </div>
                                <input
                                    type='number'
                                    className={styles.inputField}
                                    value={collectionInfo.cp}
                                    onChange={(e) => setCollectionInfo({ ...collectionInfo, cp: e.target.value })}
                                />
                            </div>
                            <div className={styles.details_div}>
                                <div className={styles.details_txt}>&#x3e;Cust Price</div>
                                <input
                                    type='number'
                                    className={styles.inputField}
                                    value={collectionInfo.mcp}
                                    onChange={(e) => setCollectionInfo({ ...collectionInfo, mcp: e.target.value })}
                                />
                            </div>
                            <div className={styles.details_div}>
                                <div className={styles.details_txt}>Volume Metric</div>
                                <input
                                    type='text'
                                    className={styles.inputField}
                                    value={collectionInfo.vm}
                                    onChange={(e) => setCollectionInfo({ ...collectionInfo, vm: e.target.value })}
                                />
                            </div>

                            {
                                collectionInfo.accessories && collectionInfo.accessories.map((item, index) =>
                                    <div key={index} className={styles.details_div}>
                                        <div className={styles.details_txt}>{item.accessoriesName}</div>
                                        <input
                                            type='text'
                                            className={styles.inputField}
                                            value={item.accessoriesPrice}
                                            onChange={(e) => setCollectionInfo({
                                                ...collectionInfo, accessories: [...collectionInfo.accessories.map((obj1, i1) => {
                                                    if (i1 === index) {
                                                        obj1.accessoriesPrice = e.target.value;
                                                    }
                                                    return obj1;
                                                })
                                                ]
                                            })
                                            }
                                        />
                                    </div>
                                )}
                        </div>
                        <div className={styles.collection_flex}>
                            <button className={styles.collection_button} onClick={() => { setModalShownext(false); setModalShow(true) }}>BACK</button>
                            <BlackOutButton btnType="button" title="SAVE" css={styles.collection_button} handleSubmit={() => { handleSubmitCollectionInfo() }} />
                        </div>
                    </div>
                }
            />
            <DispatchModal
                show={collectionInfoModal}
                close={setCollectionInfoModal}
                closeBtn={true}
                heading="COLLECTION STATUS"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <BlackOutButton title="CANCEL" css={styles.collection_button} handleSubmit={() => setCollectionInfoModal(false)} />
                            <BlackOutButton title="OK" css={styles.collection_button} handleSubmit={() => setCollectionInfoModal(false)} />
                        </div>
                    </div>
                }
            />

        </div>
    )
}
export default CollectionInfoMob;