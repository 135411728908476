import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AddDealerModal from '../../components/AddDealerModal'
import { GreenOutButton, } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth'
import { AddDealerIcon, Ban, Call, Checked, Clock, Messanger, Search, WhatsApp } from '../../components/icons/Icon'
import Pagination from '../../components/Paginantion'
// import { AddDealerModal } from '../ordermanager/SaleOrders'
import styles from "./Dealer.module.css"

const Dealers = () => {

  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;

  const [showAddDealer, setShowAddDealer] = useState(false)
  const [dealerData, setDealerData] = useState(false)
  const { managerId } = useAuth()
  const [dealersData, setDealersData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
    window.removeEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
  }, [])

  const [searchDealerData, setSearchDealerData] = useState({
    searchDealerName: '',
    sortByStatus: '',
  })
  const [pages, setPages] = useState("");

  useEffect(() => {
    if (managerId) {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
        "keyword": searchDealerData.searchDealerName,
        "sortKey": searchDealerData.sortByStatus
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + `kyc/dealers?page=${page}&limit=${width > 1600 ? '18' : '15'}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            if (result.data) {
              setDealersData(result.data)
            }
            if (result.pages) {
              setPages(result.pages ? result.pages : "")
            }
          }
        })
        .catch(error => console.log('error', error))
        .finally(() => setLoading(false))

    }
  }, [managerId, searchDealerData.sortByStatus, searchDealerData.searchDealerName, page, width])

  useEffect(() => {
    if (dealerData.dealerId) {
      navigate("/dealers/" + dealerData.dealerId)
    }
  }, [dealerData.dealerId, navigate])

  return (
    <React.Fragment>

      <AddDealerModal
        show={showAddDealer}
        close={setShowAddDealer}
        setData={setDealerData}
      />

      <div className={styles.right_main}>
        <div className={styles.addDealers}>
          <h1 className={styles.dealers}>
            Users
          </h1>
          <GreenOutButton btnType="button" title={<><AddDealerIcon />&nbsp;&nbsp;ADD USER</>} css={styles.addDealerButton} handleSubmit={() => { setShowAddDealer(!showAddDealer) }} />
        </div>

        <div className={styles.Dealer_main_container}>
          <div className={styles.dealer_header}>
            <div
              className={styles.dealer_search_div}>
              <input type="search"
                placeholder='Search'
                className={styles.dealer_search}
                value={searchDealerData.searchDealerName}
                onChange={(e) => setSearchDealerData({ ...searchDealerData, searchDealerName: e.target.value })}
              />
              <Search color="#ffffff" css={styles.dealer_search_icon} />
            </div>
            <div className={styles.dealer_header_right}>
              <div className={styles.dealer_Sort_By}>
                <label className={styles.dealer_sortBy_txt}>Sort By </label>
                <select
                  className={styles.dealer_sortBy_select}
                  value={searchDealerData.sortByStatus}
                  onChange={(e) => setSearchDealerData({ ...searchDealerData, sortByStatus: e.target.value })}
                >
                  <option className={styles.dealer_sortBy_color} value="">View All</option>
                  <option className={styles.dealer_sortBy_color} value="Referral">Referral</option>
                  <option className={styles.dealer_sortBy_color} value="Widthout OTP">Widthout OTP</option>
                  <option className={styles.dealer_sortBy_color} value="Managers Created">Managers Created</option>
                  <option className={styles.dealer_sortBy_color} value="Self Created">Self Created</option>
                  <option className={styles.dealer_sortBy_color} value="New">New</option>
                  <option className={styles.dealer_sortBy_color} value="newestfirst">Newest First</option>
                  <option className={styles.dealer_sortBy_color} value="Pending">Not Verified</option>
                  <option className={styles.dealer_sortBy_color} value="Verified">Verified</option>
                  <option className={styles.dealer_sortBy_color} value="Ascending">Ascending</option>
                  <option className={styles.dealer_sortBy_color} value="Descending">Descending</option>
                </select>
              </div>
              <div className={styles.dealer_header_select_days}>
                <div className={`${styles.dealer_days_select} ${styles.days_active}`}>All</div>
                <div className={styles.dealer_days_select}>15 Days</div>
                <div className={styles.dealer_days_select}>15-30 Days</div>
                <div className={styles.dealer_days_select}>30 Days</div>
              </div>
            </div>
          </div>
          <div className={styles.dealer_main_div}>
            {
              loading ? <div className={'loadingMainDealer'}>
                <img src='/wallicon.gif' alt='walliconGIF' />
              </div> : dealersData.length === 0 ? <div className={'loadingMainDealer'}>
                <h1>No Data Found</h1>
              </div> :
                dealersData && dealersData.map((item, index) =>
                  <div className={styles.dealer_card} key={index}>
                    <div className={styles.dealer_card_div}>
                      <div onClick={() => { navigate("/dealers/" + item.dealerId) }} className={styles.cursor_pointer} >
                        <div className={styles.dealer_status} >
                          {item.status === "Rejected" ?
                            <Ban color="#F93E3E" css={styles.dealer_status_icon} />
                            : item.status === "Verified" ? <Checked color="#1FFC33" css={styles.dealer_status_icon} />
                              : item.status === "Pending" ? <Clock color="yellow" css={styles.dealer_status_icon} />
                                : <div className={styles.newIcon_div}>
                                  <div className={styles.newIcon}>New</div>
                                </div>
                          }
                        </div>


                        {!item.profileImage ?
                          <div className={styles.name_char}><span className={styles.name_char_mb}>{item && item.name.charAt(0).toUpperCase()}</span></div>
                          :
                          <img src={process.env.REACT_APP_S3URL + item.profileImage} className={styles.dealer_image} alt='dealer_image' />
                        }

                        <h4 className={styles.dealer_name}>{item.name}</h4>
                        <div className={styles.dealeStore_owner}>{item.designation}</div>
                        <div className={item.storeName ? "" : styles.storeName_hide}>
                          <div className={styles.dealeStore_owner}>at</div>
                          <div className={styles.dealeStore_owner}>{item.storeName}</div>
                        </div>
                      </div>
                      <div className={styles.dealer_btn_div}>
                        <button className={styles.dealer_Btn}>
                          <Messanger color="#ffffff" width={16} />
                        </button>
                        <a href={'tel:+91' + item.mobile} className={styles.dealer_Btn}>
                          <Call color="#ffffff" width={16} />
                        </a>
                        <a target="_blank" rel="noreferrer" href={"https://wa.me/+91" + item.whatsapp} className={styles.dealer_Btn}>
                          <WhatsApp width={16} />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
          </div>
          <Pagination
            total={pages}
            current={page}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Dealers