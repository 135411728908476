import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Complaints, DashBoard, Dealers, Order, ProductIcon, WarehouseIcon, } from '../components/icons/Icon'
import styles from './Menus.module.css'

const AdminMenu = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <React.Fragment>
            {/* <div className={styles.menuItem_div} onClick={() => navigate("/dashboard")}>
                <DashBoard css={styles.iconImage} color={pathname === "/dashboard" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/dashboard" ? styles.active_leftMenu : ""}>DASHBOARD</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/users")}>
                <Dealers css={styles.iconImage} color={pathname === "/users" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/users" ? styles.active_leftMenu : ""}>USERS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/orders")}>
                <Order css={styles.iconImage} color={pathname === "/orders" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/orders" ? styles.active_leftMenu : ""}>ORDERS</span>
            </div> */}

            {/* <div className={styles.menuItem_div} onClick={() => navigate("/recipts")}>
                <ReciptsIcon css={styles.iconImage} color={pathname === "/recipts" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/recipts" ? styles.active_leftMenu : ""}>RECIPTS</span>
            </div> */}

            {/* <div className={styles.menuItem_div} onClick={() => navigate("/dispatchdashboard")}>
                <PackersIcon css={styles.iconImage} color={pathname === "/dispatchdashboard" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/dispatchdashboard" ? styles.active_leftMenu : ""}>DISPATCH</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/packingorder")}>
                <PackersIcon css={styles.iconImage} color={pathname === "/packingorder" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/packingorder" ? styles.active_leftMenu : ""}>PACKING ORDERS</span>
            </div> */}


            {/* <div className={styles.menuItem_div} onClick={() => navigate("/packers")}>
                <DispatchIcon css={styles.iconImage} color={pathname === "/packers" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/packers" ? styles.active_leftMenu : ""}>PACKERS</span>
            </div> */}

            {/* <div className={styles.menuItem_div} onClick={() => navigate("/inventory")} >
                <InventoryIcon css={styles.iconImage} color={pathname === "/inventory" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/inventory" ? styles.active_leftMenu : ""}>INVENTORY</span>
            </div> */}


            <div className={ styles.menuItem_div } onClick={ () => navigate( "/products" ) } >
                <ProductIcon css={styles.iconImage} color={pathname === "/products" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/products" ? styles.active_leftMenu : ""}>PRODUCTS</span>
            </div>

            {/* <div className={styles.menuItem_div} onClick={() => navigate("/complaints")} >
                <Complaints css={styles.iconImage} color={pathname === "/complaints" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/complaints" ? styles.active_leftMenu : ""}>COMPLAINTS</span>
            </div>  */}

            <div className={styles.menuItem_div} onClick={() => navigate("/jobs")} >
                <Complaints css={styles.iconImage} color={pathname === "/jobs" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/jobs" ? styles.active_leftMenu : ""}>JOBS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/applicants")} >
                <Complaints css={styles.iconImage} color={pathname === "/applicants" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/applicants" ? styles.active_leftMenu : ""}>APPLICANTS</span>
            </div>

            <div className={styles.menuItem_div} onClick={() => navigate("/enquires")} >
                <Complaints css={styles.iconImage} color={pathname === "/enquires" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/enquires" ? styles.active_leftMenu : ""}>ENQUIRES</span>
            </div>

            <div className={ styles.menuItem_div } onClick={ () => navigate( "/managers" ) }>
                <WarehouseIcon css={styles.iconImage} color={pathname === "/warehouses" ? "#24ABF8" : "#ffffff"} />
                <span className={pathname === "/warehouses" ? styles.active_leftMenu : ""}>WAREHOUSE</span>
            </div>

        </React.Fragment>
    )
}

export default AdminMenu