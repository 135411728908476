import React, { useEffect, useState } from 'react'
import { GreenButton, GreenOutButton } from './Buttons'
import { FileHandler } from './DragAndDrop'
import { DeleteIcon, Spinner } from './icons/Icon'
import styles from './PaymentModal.module.css'
// import AvatarEditor from 'react-avatar-editor'

const PaymentModal = ({ show, close, handleRecipt, reciptData, setReciptData, isImage, setisImage, totalAmount, accounts, setLoadingReceipt, loadingReceipt, closeBtn, reciptsInfo }) => {

    // const [cancelUpdateRefund, setCancelUpdateRefund] = useState('')


    //---------------- image upload--------------------------
    // const [showModal, setShowModal] = useState(false)
    // const [inputImg, setInputImg] = useState('')
    const [error, setError] = useState({
        receiptImage: '',
    })
    // const [msg, setMsg] = useState("")
    // const [progressPercent, setProgressPercent] = useState(false)

    // const onInputChange = (e) => {
    //     let url = URL.createObjectURL(e.target.files[0]);
    //     setInputImg(url)
    //     setShowModal(true)
    // }
    // useEffect(() => {
    //     if (isImage) {
    //         setInputImg(isImage[0])
    //         setShowModal(true)
    //     }
    // }, [isImage])

    // var editor = "";
    // const [picture, setPicture] = useState({
    //     cropperOpen: false,
    //     img: null,
    //     zoom: 1,
    //     croppedImg: "",
    //     position: {
    //         x: 0,
    //         y: 0,
    //     },
    // });

    // const handleScale = e => {
    //     const scale = parseFloat(e.target.value)
    //     setPicture({
    //         ...picture,
    //         zoom: scale
    //     });
    // }
    // const handlePositionChange = position => {
    //     setPicture({
    //         ...picture,
    //         position
    //     });
    // }

    // const setEditorRef = (ed) => {
    //     editor = ed;
    // };

    // const handleSave = (e) => {
    //     if (setEditorRef) {
    //         const canvasScaled = editor.getImageScaledToCanvas();
    //         canvasScaled.toBlob((blob) => {
    //             setImgData(blob)
    //         });
    //     }
    // };
    // --------------------------------------------

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [show,])

    const handleSubmitRecipt = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if ((reciptData.paymentMethod === "Photo") && !isImage) {
            setError({ ...error, receiptImage: "Please upload Receipt image" })
            alert("Please upload Receipt image")
        }
        else if (form.checkValidity() === false) {
            // if(!isImage){
            //     setError({ ...error, receiptImage: "Please upload Receipt image" })
            // }
            event.preventDefault();
            event.stopPropagation();
        }

        else {
            handleRecipt()
            setError({ ...error, receiptImage: "" })
        }
    };

    const receiptId = reciptsInfo ? reciptsInfo.length && reciptsInfo[0].receiptId : ''

    return show && (
        <>
            <div className={styles.reciptModal}>
                <div className={styles.reciptModal_content}>
                    <form
                        onSubmit={handleSubmitRecipt}
                    >
                        <div className={styles.reciptHeader_div} >
                            <h2 className={styles.reciptHeading}>Receipt</h2>
                            {(reciptData.paymentMethod === 'POD') && reciptData.receiptId &&
                                <div className={styles.recipt_tableDiv}>
                                    <div className={styles.recipt_table_cell}><span>Date</span><span><b>{reciptData.receiptDate}</b></span></div>
                                    <div className={styles.recipt_table_cell}><span>Recipt Id</span><span><b>{reciptData.receiptId}</b></span></div>
                                    <div className={styles.recipt_table_cell}><span>Reciver</span><span><b>{reciptData.receiver && reciptData.receiver}</b></span></div>
                                    <div className={styles.recipt_table_cell}><span>Order Id</span><span><b>{reciptData.orderId}</b></span></div>
                                </div>
                            }
                        </div>
                        <div className={styles.reciptContentDiv}>

                            <div className='d-flex'>
                                <div className='w-100 me-1'>
                                    <span className={styles.method}>Method</span>
                                    <select required value={reciptData.paymentMethod} onChange={(e) => { setReciptData(''); setisImage(''); setReciptData(prevState => ({ ...prevState, paymentMethod: e.target.value })) }} className={styles.methodSelect}>
                                        {reciptData.paymentMethod === 'Online' ?
                                            <option value='Online'>Online</option> :
                                            <>
                                                <option value='Photo'>Photo</option>
                                                <option value='Cash'>Cash</option>
                                                <option value='POD'>Pay On Delivery</option>
                                            </>}
                                    </select>
                                </div>

                                <div className='w-100 ms-1'>
                                    <span className={styles.method}>Total Amount</span>
                                    <input
                                        type='number'
                                        placeholder='Enter Amount'
                                        className={styles.amount_input}
                                        value={totalAmount}
                                        disabled
                                    //    onChange={(e) => setReciptData(prevState => ({ ...prevState, paymentAmount: e.target.value }))} 
                                    />
                                </div>
                            </div>

                            <div className='d-flex mt-3'>
                                {
                                    reciptData.paymentMethod === 'POD' &&
                                    <div className='w-100 me-1'>
                                        <span className={styles.submethod_txt}>Sub Method</span>
                                        <select required={reciptData.paymentMethod !== 'POD' ? true : false} value={reciptData.paymentType} onChange={(e) => setReciptData(prevState => ({ ...prevState, paymentType: e.target.value }))} className={styles.reciptMethod_select}>
                                            <option value=''>Select</option>
                                            <option value='Cash'>Cash</option>
                                            {/* <option value='PayNow'>Pay Now</option> */}
                                            <option value='Photo'>Photo</option>
                                        </select>
                                    </div>
                                }

                                {reciptData.paymentMethod === 'Cash' || reciptData.paymentType === 'Cash' ? '' :
                                    <div className='w-100 ms-1'>
                                        <span className={styles.submethod_txt}>Account</span>
                                        <select
                                            required={receiptId ? true : false}
                                            value={reciptData.accountNumber}
                                            onChange={(e) => setReciptData(prevState => ({
                                                ...prevState, accountNumber: e.target.value
                                            }))}
                                            className={styles.reciptMethod_select}
                                        >
                                            <option value='' hidden>Select</option>
                                            {accounts && accounts.map((item, index) =>
                                                <option key={index} value={item.accountNumber}>
                                                    {item.acHolderName + " " + item.accountNumber}
                                                </option>)}
                                        </select>
                                    </div>
                                }

                            </div>

                                {/* {
                                    (reciptData.paymentType === 'PayNow') &&
                                    <div className={styles.recipt_payNow}>
                                        <span>Bank : ICICI BANK</span>
                                        <span>Account No : 777705005807</span>
                                        <span>UPI Id : Demo@upi</span>
                                        <span>Ref / UTR No : 777705005807</span>
                                    </div>
                                } */}

                            {((reciptData.paymentType !== 'Cash') && (reciptData.paymentMethod === 'Photo' || reciptData.paymentMethod === 'POD' || reciptData.paymentMethod === 'Online')) &&
                                <>
                                    {(isImage || reciptData.transactionScreenshot) &&
                                        <>
                                            <div className={styles.reciptImage_div}>
                                                <div className={styles.Image_div}>
                                                    <img
                                                        src={isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot}
                                                        alt='Recipt'
                                                        onClick={() => window.open(isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot, "mozillaWindow", "popup")}
                                                        className={styles.reciptImage}
                                                    />
                                                </div>
                                                <div className={styles.reciptImage_delete} onClick={() => { setisImage(); setReciptData(prevState => ({ ...prevState, transactionScreenshot: '' })) }}><DeleteIcon />
                                                    {/* <span>Delete</span> */}
                                                </div>
                                            </div>
                                            <p role='button' className={styles.viewRecipt} onClick={() => window.open(isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot, "mozillaWindow", "popup")}>*Click To View Screenshot</p>
                                        </>
                                    }
                                    {(!isImage && !reciptData.transactionScreenshot) &&
                                        <>
                                            <div className={styles.dragImage_div}>
                                                <FileHandler
                                                    setisImage={setisImage}
                                                    styles={styles}
                                                    error={error}
                                                />
                                            </div>
                                        </>
                                    }
                                    <input
                                        required={reciptData.paymentMethod !== 'POD' ? true : false}
                                        type='text'
                                        placeholder='Ref / UTR Number / Transaction Id'
                                        className={styles.recipt_inputField_ref}
                                        value={reciptData.transactionId}
                                        onChange={(e) => setReciptData(prevState => ({
                                            ...prevState, transactionId: e.target.value
                                        }))}
                                    />
                                </>
                            }

                            <input
                                required
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                placeholder='Enter Transcation Amount (in Rs)'
                                className={styles.recipt_inputField}
                                value={reciptData.paymentAmount}
                                onChange={(e) => setReciptData(prevState => ({
                                    ...prevState,
                                    paymentAmount: e.target.value
                                }))}
                            // required
                            />

                            <div className={styles.recipt_btn}>
                                <GreenOutButton title="CANCEL" handleSubmit={() => { close() }} />
                                <GreenButton
                                    disabled={(reciptData.status === "Received" || reciptData.status === "Cancelled") ? true : false}
                                    title={loadingReceipt ? <Spinner size="15" /> : "SAVE"}
                                    btnType='submit'
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default PaymentModal
