import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
import { LargeEyeIcon } from '../../components/icons/Icon'
import styles from '../inventorymanager/Inventories.module.css'

const Inventory = () => {

    const { managerId } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/products", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setData(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId])


    return (
        <React.Fragment>
            <div className={styles.main_Container}>
                <h1 className={styles.inventory_heading}>Inventory</h1>
                <div className={styles.inventory_scroll_div}>
                    {
                        loading ? <div className={'loadingMain'}>
                            <img src='/wallicon.gif' alt='walliconGIF' />
                        </div> :

                            data && data.map((item, index) =>
                                <div key={index} className={styles.InventoryCard} onClick={() => navigate('/inventory/' + item.product_name.replace(/\s/g, "-").toLowerCase() + "?product_id=" + item._id)}>
                                    <div className={styles.productName_div}>
                                        <div className={styles.activeRadioBtn}>
                                            <input type="radio" defaultChecked={item.status === "Active" ? true : false} />
                                            <span className={styles.status_active}>Active</span>
                                        </div>
                                        <h2 className={styles.productName}>{item.product_name}</h2>
                                    </div>
                                    <div className={styles.produts_details_div}>
                                        <div className={styles.view_div}>
                                            <span className={styles.qty}>{item.collCount}</span>
                                            <span className={styles.qty_txt}>Colls</span>
                                        </div>
                                        <div className={styles.view_div}>
                                            <span className={styles.qty}>{item.itemCount}</span>
                                            <span className={styles.qty_txt}>Items</span>
                                        </div>
                                        <div className={styles.view_div}>
                                            <span className={styles.qty}>{item.qty.toFixed(2)}</span>
                                            <span className={styles.qty_txt}>Qty</span>
                                        </div>
                                    </div>
                                    <div className={styles.view_div}>
                                        <span><LargeEyeIcon color="#FFFFFF" /></span>
                                        <span className={styles.qty_txt}>View</span>
                                    </div>
                                </div>
                            )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Inventory
// ?product_name=