import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { GreenOutButton } from '../../../components/Buttons'
import { AddDealerIcon } from '../../../components/icons/Icon'
import styles from "../../kyc/Dealer.module.css"
import { useAuth } from '../../../components/context/Auth'

const Jobs = () => {
    const { managerId } = useAuth();
    const navigate = useNavigate();
    const [jobs, setJobs] = useState();
    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "careers/jobs", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setJobs(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId])
    return (
        <React.Fragment>
            <div className={styles.right_main}>
                <div className={styles.addDealers}>
                    <h1 className={styles.dealers}>
                        Jobs
                    </h1>
                    <GreenOutButton
                        btnType="button"
                        title={<><AddDealerIcon />&nbsp;&nbsp;POST JOB</>}
                        css={styles.addDealerButton} handleSubmit={() => { navigate('/job') }}
                    />
                </div>
                <div className={styles.Dealer_main_container}>
                    <Row className='mx-0 pt-3'>
                        {jobs?.map((item, index) =>
                            <Col key={index} md="3" className='mb-3'>
                                <div className='border bg-dark p-1' role='button' onClick={() => { navigate('/job?jobId='+ item.jobId) }}>
                                    {/* <img src={process.env.REACT_APP_S3URL + item.banner} alt='image1' height={250} width={'100%'} /> */ }
                                    <h5>{item.jobTitle}</h5>
                                    <div>
                                        Experience : {item.experience}
                                    </div>
                                    <div>
                                        Location : {item.location}
                                    </div>
                                    <div>
                                        Date Posted : {new Date(item?.createdAt).toISOString().split('T')[0]}
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Jobs