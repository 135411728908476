import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
import { useAuth } from '../../components/context/Auth';
import { Facebook, Instagram, Pinterest, Spinner, Twitter, Youtube } from '../../components/icons/Icon';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import ImageCrop from './ImageCrop';
import styles from "./Kyc.module.css"
import SearchPlace from './SearchPlace';

const Kyc = () => {

    const navigate = useNavigate()
    const { managerId } = useAuth();
    const params = useParams();
    const dealerId = parseInt(params.id)
    const [address, setAddress] = useState({
        place_id: "",
        formatted_address: ""
    })
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [managers, setManagers] = useState([])
    const [statusData, setStatusData] = useState()
    const [genralInfo, setGenralInfo] = useState({
        name: '',
        product: [],
        storeName: '',
        mobile: '',
        email: '',
        designation: '',
        whatsapp: '',
        gstuin: '',
        street: '',
        pincode: '',
        city: '',
        state: '',
        country: '',
        facebook: '',
        instagram: '',
        youtube: '',
        pinterest: '',
        twitter: '',
        storeDesc: '',
        experience: '',
        google: '',
        nrne: '',
        catalogStatus: '',
        profileImage: '',
        storeImg: '',
        leadBlock: '',
        status: '',
        managerId: '',
    })
    const [rejectionList, setRejectionList] = useState({})
    const [msgShow, setMsgShow] = useState("");
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [showRevertbackModal, setShowRevertbackModal] = useState(false)
    const [showLeadsBlockModal, setShowLeadsBlockModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [error, setError] = useState({
        profileImage: '',
        storeImg: '',
        selectedProducts: '',
    })

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!genralInfo.profileImage) {
            setError({ ...error, profileImage: "Please upload profile image" })
        }
        else if (!genralInfo.storeImg) {
            setError({ ...error, storeImg: "Please upload store image" })
        }
        // else if (selectedProducts.length ? selectedProducts.find(obj => !obj.status === 'Active'):"") {
        //     setError({ ...error, selectedProducts: "Please select atleast one product" })
        // }
        else {
            updateKyc()
        }
        // setValidated(true);
    };


    useEffect(() => {
        if (dealerId) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);

            var raw = JSON.stringify({
                "managerId": managerId,
                "dealerId": dealerId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "kyc/dealer", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data) {
                            setGenralInfo(prevState => ({
                                ...prevState,
                                name: result.data.name ? result.data.name : "",
                                profileImage: result.data.profileImage ? result.data.profileImage : "",
                                storeImg: result.data.storeImg ? result.data.storeImg : "",
                                storeName: result.data.storeName ? result.data.storeName : "",
                                mobile: result.data.mobile ? result.data.mobile : "",
                                email: result.data.email ? result.data.email : "",
                                designation: result.data.designation ? result.data.designation : "",
                                whatsapp: result.data.whatsapp ? result.data.whatsapp : "",
                                gstuin: result.data.gstuin ? result.data.gstuin : "",
                                street: result.data.street ? result.data.street : "",
                                pincode: result.data.pincode ? result.data.pincode : "",
                                city: result.data.city ? result.data.city : "",
                                state: result.data.state ? result.data.state : "",
                                country: result.data.country ? result.data.country : "",
                                facebook: result.data.facebook ? result.data.facebook : "",
                                instagram: result.data.instagram ? result.data.instagram : "",
                                youtube: result.data.youtube ? result.data.youtube : "",
                                pinterest: result.data.pinterest ? result.data.pinterest : "",
                                twitter: result.data.twitter ? result.data.twitter : "",
                                storeDesc: result.data.storeDesc ? result.data.storeDesc : "",
                                experience: result.data.experience ? result.data.experience : "",
                                google: result.data.google ? result.data.google : "",
                                nrne: result.data.nrne ? result.data.nrne : "",
                                catalogStatus: result.data.catalogStatus ? result.data.catalogStatus : "",
                                leadBlock: result.data.leadBlock ? result.data.leadBlock : false,
                                managerId: result.data.managerId ? result.data.managerId : "",
                                status: result.data.status ? result.data.status : "",
                                product: result.products ? result.products : "",
                            }))
                            setAddress(prevState => ({
                                ...prevState,
                                place_id: result.data.place_id ? result.data.place_id : "",
                                formatted_address: result.data.formatted_address ? result.data.formatted_address : ""
                            }))
                        }
                        if (result.products) {
                            setAllProducts(result.products)
                            setSelectedProducts(result.data.selectedProducts)
                        }
                        if (result.managers) {
                            setManagers(result.managers)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [dealerId, managerId])

    const selectModal = (info) => {
        setShowModal(!showModal)
    }
    const [hw, setHW] = useState({ width: 0, height: 0, imgName: "profile" })
    const [progressPercent, setProgressPercent] = useState({
        profileImage: false,
        storeImg: false,
        qrCode: false,
        adharImg: false,
        gstuinImg: false
    })
    const [inputImg, setInputImg] = useState('')
    const onInputChange = (e) => {
        let url = URL.createObjectURL(e.target.files[0]);
        setInputImg(url)
        selectModal()
    }

    const updateKyc = () => {
        setMsgShow("")
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "dealerId": dealerId,
            "name": genralInfo.name,
            "managerId": genralInfo.managerId,
            "designation": genralInfo.designation,
            "street": genralInfo.street,
            "city": genralInfo.city,
            "state": genralInfo.state,
            "country": genralInfo.country,
            "pincode": genralInfo.pincode,
            "whatsapp": genralInfo.whatsapp,
            "email": genralInfo.email,
            "gstuin": genralInfo.gstuin,
            "storeName": genralInfo.storeName,
            "storeDesc": genralInfo.storeDesc,
            "experience": genralInfo.experience,
            "facebook": genralInfo.facebook,
            "twitter": genralInfo.twitter,
            "instagram": genralInfo.instagram,
            "youtube": genralInfo.youtube,
            "pinterest": genralInfo.pinterest,
            "place_id": address.place_id,
            "formatted_address": address.formatted_address,
            "nrne": genralInfo.nrne,
            "catalogStatus": genralInfo.catalogStatus,
            "selectedProducts": selectedProducts,
            "rejectionList": rejectionList,
            "leadBlock": genralInfo.leadBlock,
            "status": genralInfo.status,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "kyc/updatedealer", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                }
                else {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                    setStatusData(result.status)
                }
            })
            .catch(error => console.log('error', error))
            .finally(()=>setLoading(false))
    }
    var points = new Array(100);
    for (var i = 0; i < 5; i++) {
        points[i] = i + 1;
    }
    const [leadBlock, setLeadBlock] = useState()

    // console.log('allProducts', allProducts);
    // console.log('selectedProducts', selectedProducts);
    
    return (
        <React.Fragment>

            <Modal
                show={showRejectModal}
                close={setShowRejectModal}
                closeBtn={true}
                heading="KYC REJECTED"
                content={
                    <div className={styles.text_center}>
                        <h3>KYC REJECTED</h3>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowRejectModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => { setShowRejectModal(false) }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showAcceptModal}
                close={setShowAcceptModal}
                closeBtn={true}
                heading={statusData === 'failed' ? 'ERROR' : "KYC STATUS"}
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => { navigate("/dealers") }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showRevertbackModal}
                close={setShowRevertbackModal}
                closeBtn={true}
                heading="REJECT LIST"
                content={
                    <div className={styles.text_center}>
                        <div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Profile Image</span>
                                <span className={styles.rejectionList_text}>{rejectionList.profileImage}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.profileImage) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'profileImage')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, profileImage: "please select valid profile image" }) }
                                        }}
                                        checked={rejectionList.profileImage ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Store Image</span>
                                <span className={styles.rejectionList_text}>{rejectionList.storeImg}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.storeImg) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'storeImg')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, storeImg: "please select valid store image" }) }
                                        }}
                                        checked={rejectionList.storeImg ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Dealer Name</span>
                                <span className={styles.rejectionList_text}>{rejectionList.dealerName} </span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.dealerName) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'dealerName')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, dealerName: "please enter valid dealer name" }) }
                                        }}
                                        checked={rejectionList.dealerName ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Email Address</span>
                                <span className={styles.rejectionList_text}>{rejectionList.emailAddress}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.emailAddress) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'emailAddress')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, emailAddress: "please enter valid email address" }) }
                                        }}
                                        checked={rejectionList.emailAddress ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Mobile No.</span>
                                <span className={styles.rejectionList_text}>{rejectionList.mobileNumber}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.mobileNumber) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'mobileNumber')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, mobileNumber: "please enter valid mobile number" }) }
                                        }}
                                        checked={rejectionList.mobileNumber ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>GSTUIN</span>
                                <span className={styles.rejectionList_text}>{rejectionList.gstuin}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.gstuin) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'gstuin')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, gstuin: "please enter valid GST number" }) }
                                        }}
                                        checked={rejectionList.gstuin ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Designation</span>
                                <span className={styles.rejectionList_text}>{rejectionList.designation}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.designation) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'designation')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, designation: "please enter valid designation name" }) }
                                        }}
                                        checked={rejectionList.designation ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Store Name</span>
                                <span className={styles.rejectionList_text}>{rejectionList.storeName}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.storeName) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'storeName')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, storeName: "please enter valid trade name" }) }
                                        }}
                                        checked={rejectionList.storeName ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Whatsapp</span>
                                <span className={styles.rejectionList_text}>{rejectionList.whatsapp}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.whatsapp) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'whatsapp')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, whatsapp: "please enter valid whatsapp number" }) }
                                        }}
                                        checked={rejectionList.whatsapp ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>City</span>
                                <span className={styles.rejectionList_text}>{rejectionList.city}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.city) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'city')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, city: "please enter valid city name" }) }
                                        }}
                                        checked={rejectionList.city ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Country</span>
                                <span className={styles.rejectionList_text}>{rejectionList.country}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.country) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'country')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, country: "please enter valid country name" }) }
                                        }}
                                        checked={rejectionList.country ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Pincode</span>
                                <span className={styles.rejectionList_text}>{rejectionList.pincode}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.pincode) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'pincode')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, pincode: "please enter valid pincode number" }) }
                                        }}
                                        checked={rejectionList.pincode ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>State</span>
                                <span className={styles.rejectionList_text}>{rejectionList.state}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.state) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'state')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, state: "please enter valid state name" }) }
                                        }}
                                        checked={rejectionList.state ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectionList_div}>
                                <span className={styles.rejectionList}>Street</span>
                                <span className={styles.rejectionList_text}>{rejectionList.street}</span>
                                <span className={styles.rejectionList_checkbox}>
                                    <input
                                        type="checkbox"
                                        onClick={() => {
                                            if (rejectionList.street) {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'street')
                                                )
                                                setRejectionList(withoutA)
                                            }
                                            else { setRejectionList({ ...rejectionList, street: "please enter valid street name" }) }
                                        }}
                                        checked={rejectionList.street ? true : false}
                                        className={styles.rejectCheckbox}
                                    />
                                </span>
                            </div>
                            <div className={styles.rejectListModal_btn}>
                                <RedOutButton title="CANCEL" handleSubmit={() => setShowRevertbackModal(false)} />
                                <GreenButton title="ASK TO CHECK" handleSubmit={() => { setShowRevertbackModal(false) }} />
                            </div>
                        </div>
                    </div>
                }
            />

            <Modal
                show={showLeadsBlockModal}
                close={setShowLeadsBlockModal}
                closeBtn={true}
                heading="Lead Block"
                content={
                    <div className={styles.text_center}>
                        <h3>Are you sure want to {genralInfo.leadBlock === "Active" ? "enable" : "disable"} organic leads</h3>
                        <div className={styles.leadModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowLeadsBlockModal(false)} />
                            <GreenButton title="OK"
                                handleSubmit={() => {
                                    setGenralInfo(prevState =>
                                    ({
                                        ...prevState,
                                        leadBlock: leadBlock
                                    }));
                                    setShowLeadsBlockModal(false)
                                }} />
                        </div>
                    </div>
                }
            />

            <ImageCrop
                displayModal={showModal}
                closeModal={selectModal}
                inputImg={inputImg}
                hw={hw}
                setInputImg={setInputImg}
                setProgressPercent={setProgressPercent}
                setAddData={setGenralInfo}
                dealerId={dealerId}
            />
            <form onSubmit={handleSubmit} className={styles.kyc_main_container_div}>
                <div className={styles.text_underline}>
                    <span onClick={() => navigate(-1)}>
                        &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
                    </span>
                </div>
                <div className={styles.dflex_space_between}>
                    <div className={styles.demo}>
                        {genralInfo.storeName}
                    </div>
                    <div className={styles.kyc_btn_div}>
                        <RedOutButton btnType="button" title="REJECT" css="" handleSubmit={() => setShowRejectModal(true)} />
                        <GreenOutButton btnType="button" title="REVERT BACK" css={styles.margin_LR} handleSubmit={() => setShowRevertbackModal(true)} />
                        <GreenButton btnType="submit" disabled={rejectionList.length > 0 ? true : false} title={loading? <Spinner size="15" /> :"KYC SUCCESFUL"} css="" />
                    </div>
                </div>

                <div className={styles.kyc_sub_main_div}>

                    <div className={styles.kyc_scroll_div}>

                        <div className={styles.profileAndStore}>
                            <div>
                                <div className={styles.d_flex_align_center}>


                                    <label className={error.profileImage && genralInfo.profileImage.length === 0 ? styles.profileImage_alert : styles.profileImage} >
                                        {
                                            progressPercent.profileImage && !genralInfo.profileImage ?
                                                <Loading />
                                                :
                                                !genralInfo.profileImage ? <div className={error.profileImage && genralInfo.profileImage.length === 0 ? styles.profile_bg_alert : styles.profile_bg}>
                                                    Upload Profile Image
                                                </div> :
                                                    <img
                                                        src={`${process.env.REACT_APP_S3URL + genralInfo.profileImage}?${new Date().getTime()}`}
                                                        alt="Profile"
                                                        className={styles.profile_Image_div}
                                                        title={genralInfo.name}
                                                    />
                                        }
                                        <input
                                            type="file"
                                            accept='image/*'
                                            className={styles.d_none}
                                            onChange={(e) => { onInputChange(e); selectModal(); setHW({ width: 200, height: 200, imgName: "profile" }) }}
                                        />
                                    </label>
                                    <div className={styles.kyc_toggle_btn_profile_img}>
                                        <label
                                            className={!rejectionList.profileImage ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'profileImage')
                                                )

                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.profileImage ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, profileImage: "please select valid profile image" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.d_flex_align_center}>
                                    <div className="">Dealer Name: {genralInfo.name} &nbsp;</div>
                                    <div className={styles.kyc_toggle_btn_profile_img}>
                                        <label
                                            className={!rejectionList.dealerName ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'dealerName')
                                                )

                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.dealerName ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, dealerName: "please enter valid dealer name" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label className={error.storeImg && genralInfo.storeImg.length === 0 ? styles.storeImage_alert : styles.storeImage}>
                                        {progressPercent.storeImg && !genralInfo.storeImg ?
                                            <Loading />
                                            :

                                            !genralInfo.storeImg ? <div className={error.storeImg && genralInfo.storeImg.length === 0 ? styles.store_bg_alert : styles.store_bg}>
                                                Upload Store Image

                                            </div> :
                                                <img
                                                    src={`${process.env.REACT_APP_S3URL + genralInfo.storeImg}?${new Date().getTime()}`}
                                                    alt="store"
                                                    className={styles.store_Image_div}
                                                    title={genralInfo.storeName}
                                                />
                                        }
                                        <input
                                            type="file"
                                            accept='image/*'
                                            className={styles.d_none}
                                            onChange={(e) => { onInputChange(e); selectModal(); setHW({ width: 1920, height: 720, imgName: "store" }) }}
                                        />
                                    </label>

                                </div>
                                <div>
                                    <div className={styles.d_flex_align_center}>Store Image-1920x680</div>
                                    <div className={styles.kyc_toggle_btn_profile_img}>
                                        <label
                                            className={!rejectionList.storeImg ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'storeImg')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.storeImg ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, storeImg: "please select valid store image" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row_mt_20}>
                            <div className={styles.col3}>
                                <div className={styles.fs_20_mt_10}>Experience</div>
                                <select
                                    required
                                    value={genralInfo.experience}
                                    onChange={(e) => setGenralInfo({ ...genralInfo, experience: e.target.value })}
                                    className={styles.bg_Transparent_w_85_border_p_10}>
                                    <option className={styles.bg_gray_dark} value="" hidden>-- Select --</option>
                                    {points.map((item, index) => <option key={index} value={index + 1} className={styles.bg_gray_dark}>{item}&nbsp;Year&#43;</option>)}
                                </select>
                                <div className={styles.fs_20_mt_10}>Facebook </div>
                                <div className={styles.position_relative}>
                                    <div className={styles.socialMedia_icons}>
                                        <Facebook />
                                    </div>
                                    <input

                                        value={genralInfo.facebook}
                                        onChange={(e) => setGenralInfo({ ...genralInfo, facebook: e.target.value })}
                                        type="text"
                                        name="facebook"
                                        placeholder="Enter Facebook Url"
                                        className={styles.bg_Transparent_w_75_border_p_10_ml_30}
                                    />
                                </div>
                                <div className={styles.fs_20_mt_10}>Instagram</div>
                                <div className={styles.position_relative}>
                                    <div className={styles.socialMedia_icons}>
                                        <Instagram />
                                    </div>
                                    <input

                                        value={genralInfo.instagram}
                                        onChange={(e) => setGenralInfo({ ...genralInfo, instagram: e.target.value })}
                                        type="text"
                                        name="instagram"
                                        placeholder="Enter Instagram Url"
                                        className={styles.bg_Transparent_w_75_border_p_10_ml_30}
                                    />
                                </div>
                                <div className={styles.fs_20_mt_10}>Youtube</div>
                                <div className={styles.position_relative}>
                                    <div className={styles.socialMedia_icons}>
                                        <Youtube />
                                    </div>
                                    <input

                                        value={genralInfo.youtube}
                                        onChange={(e) => setGenralInfo({ ...genralInfo, youtube: e.target.value })}
                                        type="text"
                                        name="youtube"
                                        placeholder="Enter Youtube Url"
                                        className={styles.bg_Transparent_w_75_border_p_10_ml_30}
                                    />
                                </div>
                                <div className={styles.fs_20_mt_10}>Pinterest</div>
                                <div className={styles.position_relative}>
                                    <div className={styles.socialMedia_icons}>
                                        <Pinterest />
                                    </div>
                                    <input

                                        value={genralInfo.pinterest}
                                        onChange={(e) => setGenralInfo({ ...genralInfo, pinterest: e.target.value })}
                                        type="text"
                                        name="pinterest"
                                        placeholder="Enter Pinterest Url"
                                        className={styles.bg_Transparent_w_75_border_p_10_ml_30}
                                    />
                                </div>
                                <div className={styles.fs_20_mt_10}>Twitter</div>
                                <div className={styles.position_relative}>
                                    <div className={styles.socialMedia_icons}>
                                        <Twitter />
                                    </div>
                                    <input

                                        value={genralInfo.twitter}
                                        onChange={(e) => setGenralInfo({ ...genralInfo, twitter: e.target.value })}
                                        type="text"
                                        name="twitter"
                                        placeholder="Enter Twitter Url"
                                        className={styles.bg_Transparent_w_75_border_p_10_ml_30}
                                    />
                                </div>
                            </div>
                            <div className={styles.col9}>
                                <div className={styles.fs_20_mt_10}>Products they want to sell</div>
                                <div className={styles.row_br_5_bg_dark_p_10}>
                                    {
                                        allProducts && allProducts.map((item, index) =>
                                            <div key={index} className={styles.productName_center}>
                                                <button type="button"
                                                    className={selectedProducts.find(val => val.product_id === item.product_id
                                                        //  && val.status === "Active"
                                                         ) ?
                                                        styles.productBtn 
                                                        // : selectedProducts.find(val => val.product_id === item.product_id && val.status === "Pending") ?
                                                        //     styles.product_pending 
                                                            :
                                                            styles.productDisableBtn
                                                    }
                                                    onClick={() => {
                                                        if (selectedProducts.filter((val) => val.product_id === item.product_id && val.status === "Pending").length !== 0) {
                                                            selectedProducts.forEach((obj, idx) => {
                                                                if (obj.product_id === item.product_id && obj.status === "Pending") {
                                                                    const newUsers = [...selectedProducts];
                                                                    newUsers[idx].status = 'Active';
                                                                    setSelectedProducts(newUsers);
                                                                }
                                                            })
                                                        }
                                                        else if (selectedProducts.filter((val) => val.product_id === item.product_id).length === 0) {
                                                            setSelectedProducts([...selectedProducts, {
                                                                product_name: item.product_name,
                                                                product_id: item.product_id,
                                                                status: "Active",
                                                            }])
                                                        } else {
                                                            let arr = selectedProducts;
                                                            let arr1 = arr.filter((val) => val.product_id !== item.product_id);
                                                            setSelectedProducts(arr1);
                                                        }
                                                    }}
                                                >
                                                    {selectedProducts.find(val => val.product_name === item.product_name) ?
                                                        <span className={styles.plus_product}>&#10004;</span> :
                                                        <span className={styles.plus_product}>&nbsp;&#10011;&nbsp;</span>
                                                    }
                                                    {item.product_name}
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                                {error.selectedProducts && <div className={styles.input_field_alert}>{error.selectedProducts}</div>}

                                <div className={styles.row}>
                                    <div className="col-12">
                                        <div className={styles.fs_20_mt_10}>About {genralInfo.storeName} <span className="fs_14">(This Paragraph will be visible in their website)</span></div>
                                        <textarea
                                            required
                                            value={genralInfo.storeDesc}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, storeDesc: e.target.value })}
                                            name="storeDesc"
                                            rows="10"
                                            placeholder="Enter About Demo Interiors"
                                            className={styles.bg_Transparent_w_98_border_p_10}
                                        />
                                        <div className={styles.text_end_text_danger_fs_12}>*First 50-160 letters will be used for your Search Engine ranking.</div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className={styles.kyc_sub_heading}>General Info</div>
                        <div className={styles.row_fs_Kyc20_align_items_center}>
                            <div className={styles.col5}>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Name</div>
                                        <input
                                            required
                                            type="text"
                                            name="Name"
                                            value={genralInfo.name}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, name: e.target.value })}
                                            placeholder="Enter Dealer Name"
                                            className={styles.kyc_input}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.name ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'name')
                                                )

                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.name ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, name: "please enter valid name" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Store Name</div>
                                        <input
                                            required
                                            type="text"
                                            name="tradeName"
                                            value={genralInfo.storeName}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, storeName: e.target.value })}
                                            placeholder="Enter Trade Name"
                                            className={styles.kyc_input}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.storeName ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'storeName')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.storeName ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, storeName: "please enter valid trade name" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Mobile Number</div>
                                        <input
                                            // disabled
                                            required
                                            type="number"
                                            name="mobile"
                                            value={genralInfo.mobile}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, mobile: e.target.value })}
                                            placeholder="Enter Mobile Number"
                                            className={styles.kyc_input}
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.mobileNumber ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'mobileNumber')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.mobileNumber ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, mobileNumber: "please enter valid mobile number" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Email Address</div>
                                        <input
                                            // disabled
                                            required
                                            type="email"
                                            name="email"
                                            value={genralInfo.email}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, email: e.target.value })}
                                            placeholder="Enter Email Address"
                                            className={styles.kyc_input} />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.emailAddress ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'emailAddress')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.emailAddress ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, emailAddress: "please enter valid email address" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.col5}>

                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Designation</div>
                                        <input
                                            required
                                            type="text"
                                            name="designation"
                                            value={genralInfo.designation}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, designation: e.target.value })}
                                            placeholder="Enter Designation"
                                            className={styles.kyc_input}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.designation ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'designation')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.designation ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, designation: "please enter valid designation name" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Whatsapp</div>
                                        <input
                                            required
                                            type="text"
                                            name="whatsapp"
                                            value={genralInfo.whatsapp}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, whatsapp: e.target.value })}
                                            placeholder="Enter Whatsapp Number"
                                            className={styles.kyc_input}
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.whatsapp ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'whatsapp')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.whatsapp ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, whatsapp: "please enter valid whatsapp number" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>GSTUIN (OPTIONAL)</div>
                                        <input
                                            type="text"
                                            name="gstuin"
                                            value={genralInfo.gstuin}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, gstuin: e.target.value })}
                                            placeholder="Enter GSTUIN"
                                            className={styles.kyc_input}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.gstuin ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'gstuin')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.gstuin ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, gstuin: "please enter valid GST number" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.kyc_sub_heading}>Address</div>
                        <div className={styles.kyc_row_fs_Kyc20}>
                            <div className={styles.col5}>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Street</div>
                                        <input
                                            required
                                            value={genralInfo.street}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, street: e.target.value })}
                                            type="text"
                                            placeholder="Enter Street"
                                            className={styles.kyc_input}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.street ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'street')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.street ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, street: "please enter valid street name" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Pincode</div>
                                        <input
                                            required
                                            value={genralInfo.pincode}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, pincode: e.target.value })}
                                            type="text"
                                            placeholder="Enter Pincode"
                                            className={styles.kyc_input}
                                            maxLength="6"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.pincode ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'pincode')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.pincode ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, pincode: "please enter valid pincode number" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>City</div>
                                        <input
                                            required
                                            value={genralInfo.city}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, city: e.target.value })}
                                            type="text"
                                            placeholder="Enter City"
                                            className={styles.kyc_input}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.city ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'city')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.city ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, city: "please enter valid city name" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>State</div>
                                        <input
                                            required
                                            value={genralInfo.state}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, state: e.target.value })}
                                            type="text"
                                            placeholder="Enter State"
                                            className={styles.kyc_input}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.state ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'state')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.state ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, state: "please enter valid state name" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col_10}>
                                        <div className={styles.margin}>Country</div>
                                        <input
                                            required
                                            value={genralInfo.country}
                                            onChange={(e) => setGenralInfo({ ...genralInfo, country: e.target.value })}
                                            type="text"
                                            placeholder="Enter Country"
                                            className={styles.kyc_input}
                                        />
                                    </div>
                                    <div className={styles.kyc_toggle_btn}>
                                        <label
                                            className={!rejectionList.country ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
                                            onClick={() => {
                                                const withoutA = Object.fromEntries(
                                                    Object.entries(rejectionList).filter(([key]) => key !== 'country')
                                                )
                                                setRejectionList(withoutA)
                                            }}
                                        >
                                            &#10003;
                                        </label>
                                        <label
                                            className={rejectionList.country ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
                                            onClick={() => { setRejectionList({ ...rejectionList, country: "please enter valid country name" }) }}
                                        >
                                            X
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.searchPlace_div}>
                                <SearchPlace address={address} setAddress={setAddress} />
                            </div>
                        </div>

                        <div className={styles.kyc_sub_heading}>For Office Use</div>
                        <div className={styles.rowOffice}>
                            <div className={styles.col3}>
                                <div className={styles.margin}>Assign Order Manager</div>
                                <select
                                    value={genralInfo.managerId}
                                    onChange={(e) => setGenralInfo({ ...genralInfo, managerId: e.target.value })}
                                    className={styles.kyc_input_select}>
                                    <option className={styles.bg_gray_dark} hidden>-- Select --</option>
                                    {managers && managers.map((item, index) => (
                                        <option key={index} value={item.managerId} className={styles.bg_gray_dark}>{item.name}</option>
                                    ))}

                                </select>
                            </div>

                            <div className={styles.col3}>
                                <div className={styles.margin}>No Return No Exchange Mandotory Checkbox</div>
                                <div className={styles.dfac}>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, nrne: e.target.value })}
                                            type="radio"
                                            name="nrne"
                                            value="Active"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.nrne === "Active" ? true : false}
                                        />Active
                                    </label>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, nrne: e.target.value })}
                                            type="radio"
                                            name="nrne"
                                            value="Disable"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.nrne === "Disable" ? true : false}
                                        />Disable
                                    </label>
                                </div>
                            </div>
                            <div className={styles.col3}>
                                <div className={styles.margin}>Catalogue</div>
                                <div className={styles.dfac}>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, catalogStatus: e.target.value })}
                                            type="radio"
                                            name="catalogStatus"
                                            value="Active"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.catalogStatus === "Active" ? true : false}
                                        />Active
                                    </label>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, catalogStatus: e.target.value })}
                                            type="radio"
                                            name="catalogStatus"
                                            value="Disable"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.catalogStatus === "Disable" ? true : false}
                                        />Disable
                                    </label>
                                </div>
                            </div>
                            <div className={styles.col3}>
                                <div className={styles.margin}>Organic Leads</div>
                                <div className={styles.dfac}>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => { setLeadBlock(true); setShowLeadsBlockModal(true) }}
                                            type="radio"
                                            name="leadBlock"
                                            value={true}
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.leadBlock === true ? true : false}
                                        />Active
                                    </label>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => { setLeadBlock(false); setShowLeadsBlockModal(true) }}
                                            type="radio"
                                            name="leadBlock"
                                            value={false}
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.leadBlock === false ? true : false}
                                        />Disable
                                    </label>
                                </div>
                            </div>


                            <div className={styles.col8}>
                                <div className={styles.margin}>Status</div>
                                <div className={styles.dfac}>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
                                            type="radio"
                                            name="status"
                                            value="New"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.status === "New" ? true : false}
                                        />New
                                    </label>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
                                            type="radio"
                                            name="status"
                                            value="Pending"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.status === "Pending" ? true : false}
                                        />Pending
                                    </label>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
                                            type="radio"
                                            name="status"
                                            value="Update"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.status === "Update" ? true : false}
                                        />Update
                                    </label>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
                                            type="radio"
                                            name="status"
                                            value="Verified"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.status === "Verified" ? true : false}
                                        />Verified
                                    </label>
                                    <label>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
                                            type="radio"
                                            name="status"
                                            value="Rejected"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.status === "Rejected" ? true : false}
                                        />Rejected
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* <div className={styles.kyc_row_fs_Kyc20}>

                            <div className={styles.OfficeUse_col}>
                                <div className={styles.margin}>Assign Order Manager</div>
                               
                                <div className={styles.margin}>No Return No Exchange Mandotory Checkbox</div>
                                <div className={styles.row_fs_Kyc20_align_items_center}>
                                    <div className={styles.kyc_radioBtn_col_3}>
                                        <input
                                            required
                                            value="Active"
                                            onChange={(e) => setGenralInfo({ ...genralInfo, nrne: e.target.value })}
                                            type="radio"
                                            name="Return"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.nrne === "Active" ? true : false} />Active
                                    </div>
                                    <div className={styles.kyc_col_9}>
                                        <input
                                            required
                                            value="Disable"
                                            onChange={(e) => setGenralInfo({ ...genralInfo, nrne: e.target.value })}
                                            type="radio"
                                            name="Return"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.nrne === "Disable" ? true : false}
                                        />Disable
                                    </div>
                                </div>

                            </div>
                            <div className={styles.OfficeUse_col}>
                                <div className={styles.margin}>Status</div>
                                <div className={`${styles.row} ${styles.align_items_center}`}>
                                    <div className={styles.kyc_radioBtn_col_3}>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
                                            type="radio"
                                            name="status"
                                            value="Verified"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.status === "Verified" ? true : false}
                                        />Active
                                    </div>
                                    <div className={styles.kyc_col_9}>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
                                            type="radio"
                                            name="status"
                                            value="Rejected"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.status === "Rejected" ? true : false} />Disable
                                    </div>
                                </div>

                                <div className={styles.Catalogue}>Catalogue</div>
                                <div className={`${styles.row} ${styles.align_items_center}`}>
                                    <div className={styles.kyc_radioBtn_col_3}>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, catalogStatus: e.target.value })}
                                            type="radio"
                                            name="Catalogue"
                                            value="Active"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.catalogStatus === "Active" ? true : false} />Active
                                    </div>
                                    <div className={styles.kyc_col_9}>
                                        <input
                                            required
                                            onChange={(e) => setGenralInfo({ ...genralInfo, catalogStatus: e.target.value })}
                                            type="radio"
                                            name="Catalogue"
                                            value="Disable"
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.catalogStatus === "Disable" ? true : false}
                                        />Disable
                                    </div>
                                </div>
                            </div>

                            <div className={styles.OfficeUse_col}>
                                <div className={styles.margin}>Organic Leads</div>
                                <div className={`${styles.row} ${styles.align_items_center}`}>
                                    <div className={styles.kyc_radioBtn_col_3}>
                                        <input
                                            onChange={(e) => { setGenralInfo(prevState => ({ ...prevState, leadBlock: "Enable" })); setShowLeadsBlockModal(true) }}
                                            type="radio"
                                            name="leads"
                                            value={true}
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.leadBlock === true ? true : false}
                                            required
                                        />Enable
                                    </div>
                                    <div className={styles.kyc_col_9}>
                                        <input
                                            onChange={(e) => { setGenralInfo(prevState => ({ ...prevState, leadBlock: "Disable" })); setShowLeadsBlockModal(true) }}
                                            type="radio"
                                            name="leads"
                                            value={false}
                                            className={styles.kyc_mouse_radio_Btn}
                                            checked={genralInfo.leadBlock === false ? true : false}
                                            required
                                        />Disable
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </form>
        </React.Fragment >
    )
}

export default Kyc