import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { GreenButton, GreenOutButton, RedOutButton } from '../../../components/Buttons'
import { AddDealerIcon, DeleteIcon } from '../../../components/icons/Icon'
import styles from "../../kyc/Dealer.module.css"
import { useAuth } from '../../../components/context/Auth'

const Job = () => {
    const { managerId } = useAuth()
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get('jobId');
    // console.log(jobId);
    // let id = ""
    const [data, setData] = useState({
        logo: "1",
        banner: "1",
        jobTitle: "",
        location: "",
        experience: "",
        positions: "",
        description: "",
        jobType: "",
        workHours: "",
        salary: "",
        maxSalary: "",
        skills: "",
    })
    const handleChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    const [resp, setResp] = useState([""]);

    const handleChangeResp = (e, index) => {
        const { value } = e.target;
        const list = [...resp];
        list[index] = value;
        setResp(list);
    };

    const handleAddResp = () => {
        const values = [...resp];
        values.push("");
        setResp(values);
    }

    const handleRemoveResp = (i) => {
        const values = [...resp];
        values.splice(i, 1);
        setResp(values);
    }

    const [requ, setRequ] = useState([""]);

    const handleChangeRequ = (e, index) => {
        const { value } = e.target;
        const list = [...requ];
        list[index] = value;
        setRequ(list);
    };

    const handleAddRequ = () => {
        const values = [...requ];
        values.push("");
        setRequ(values);
    }

    const handleRemoveRequ = (i) => {
        const values = [...requ];
        values.splice(i, 1);
        setRequ(values);
    }

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = ( event ) => {
        event.preventDefault();
        const form = event.currentTarget;
        if ( form.checkValidity() === false ) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading( true );
            const myHeaders = new Headers();
            myHeaders.append( "ch-platform", "cHV2FsbGljb25EZWFsZXI6Mml3ZFE2YChickanHaul240S0JCQlhIZF9ldE9SdVcwWHBMZTM2TFU" );
            myHeaders.append( "Content-Type", "application/json" );

            const raw = JSON.stringify( {
                "jobTitle": data.jobTitle,
                "experience": data.experience,
                "positions": data.positions,
                "location": data.location,
                "description": data.description,
                "skills": data.skills,
                "responsibilities": resp,
                "requirements": requ,
                "status": data.status,
                "jobType": data.jobType,
                "workHours": data.workHours
            } );

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch( `${process.env.REACT_APP_URL}careers/postJob/${managerId}?jobId=${jobId ? jobId : ''}`, requestOptions )
                .then( ( response ) => response.json() )
                .then( ( result ) => {
                    alert( result.msg )
                } )
                .catch( ( error ) => console.error( error ) );
        }
        // } else {
        //     setLoading(true)
        //     var myHeaders = new Headers();
        //     myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);

        //     var formdata = new FormData();
        //     formdata.append("jobTitle", data.jobTitle);
        //     formdata.append("experience", data.experience);
        //     formdata.append("positions", data.positions);
        //     formdata.append("location", data.location);
        //     formdata.append("description", data.description);
        //     formdata.append("responsibilities", JSON.stringify(resp));
        //     formdata.append("requirements", JSON.stringify(requ));
        //     formdata.append("logo", data.logo);
        //     formdata.append("banner", data.banner);
        //     formdata.append("jobType", data.jobType);
        //     formdata.append("workHours", data.workHours);
        //     formdata.append("salary", data.salary);
        //     formdata.append("maxSalary", data.maxSalary);
        //     formdata.append("skills", data.skills);
        //     formdata.append("status", data.status);

        //     var requestOptions = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: formdata,
        //         redirect: 'follow'
        //     };

        //     fetch(process.env.REACT_APP_URL + "careers/postJob/" + managerId + "?jobId=" + jobId, requestOptions)
        //         .then(response => response.json())
        //         .then(result => {
        //             alert(result.msg)
        //         })
        //         .catch(error => console.log('error', error))
        //         .finally(() => setLoading(false))
        // }

        setValidated( true );
    };

    useEffect(() => {
        if (jobId && managerId) {
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "jobId": jobId,
                "managerId": parseInt( managerId )
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "careers/job", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(result.data)
                        setRequ(result.data.responsibilities)
                        setResp(result.data.requirements)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [jobId, managerId])
    // console.log(managerId);
    const deleteJob = () => {
        const myHeaders = new Headers();
        myHeaders.append( "ch-platform", "cHV2FsbGljb25EZWFsZXI6Mml3ZFE2YChickanHaul240S0JCQlhIZF9ldE9SdVcwWHBMZTM2TFU" );
        myHeaders.append( "Content-Type", "application/json" );

        const raw = JSON.stringify( {
            "jobId": jobId,
            "managerId": managerId
        } );

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch( "http://localhost:3300/careers/deletejob", requestOptions )
            .then( ( response ) => response.json() )
            .then( ( result ) => {
                alert( result.msg );
                navigate( '/jobs' )
            } )
            .catch( ( error ) => console.error( error ) );
    }

    return (
        <React.Fragment>
            <div className={styles.right_main}>
                <div className={styles.addDealers}>
                    <h1 className={styles.dealers}>
                        POST NEW JOB
                    </h1>
                    <GreenOutButton
                        btnType="button"
                        title={<><AddDealerIcon />&nbsp;&nbsp; JOBS</>}
                        css={styles.addDealerButton} handleSubmit={() => { navigate('/jobs') }}
                    />
                </div>
                <div className={styles.Dealer_main_container}>
                    <Form className='pt-3' noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col md="6">
                                <Row>
                                    {/* <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom01">
                                        <Form.Label>Upload Logo</Form.Label>
                                        {data?.logo ?
                                            <div className='d-flex align-items-center'>
                                                <img src={data?.logo?.name ?
                                                    URL.createObjectURL(data?.logo)
                                                    : process.env.REACT_APP_S3URL + data?.logo
                                                } alt='logo' height={50} className='me-3' />
                                                <DeleteIcon
                                                    onClick={() => setData(prevState => ({
                                                        ...prevState,
                                                        logo: ""
                                                    }))} />
                                            </div>
                                            :
                                            <Form.Control

                                                type="file"
                                                onChange={e => setData(prevState => ({
                                                    ...prevState,
                                                    logo: e.target.files[0]
                                                }))}
                                            />
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom01">
                                        <Form.Label>Upload Banner</Form.Label>
                                        {data?.banner ?
                                            <div className='d-flex align-items-center'>
                                                <img src={data?.banner?.name ?
                                                    URL.createObjectURL(data?.banner)
                                                    : process.env.REACT_APP_S3URL + data?.banner
                                                } alt='banner' height={50} className='me-3' />
                                                <DeleteIcon
                                                    onClick={() => setData(prevState => ({
                                                        ...prevState,
                                                        banner: ""
                                                    }))} />
                                            </div>
                                            :
                                            <Form.Control

                                                type="file"
                                                onChange={e => setData(prevState => ({
                                                    ...prevState,
                                                    banner: e.target.files[0]
                                                }))}
                                            />
                                        }
                                    </Form.Group> */}

                                    <Form.Group as={Col} sm="12" className='mb-3' controlId="validationCustom01">
                                        <Form.Label>Job Title</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name='jobTitle'
                                            value={data.jobTitle}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom04">
                                        <Form.Label>Locations</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name='location'
                                            value={data?.location}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom03">
                                        <Form.Label>Job Type</Form.Label>
                                        <Form.Select
                                            required
                                            className='text-start'
                                            name='jobType'
                                            value={data?.jobType}
                                            onChange={handleChange}
                                        >
                                            <option value={''} hidden>Choose</option>
                                            <option>Hybrid Full Time</option>
                                            <option>Hybrid Part Time</option>
                                            <option>Full Time (on site)</option>
                                            <option>Part Time (on site)</option>
                                            <option>Remote Full Time </option>
                                            <option>Remote Part Time </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom02">
                                        <Form.Label>Experience</Form.Label>
                                        <Form.Select
                                            required
                                            className='text-start'
                                            name='experience'
                                            value={data?.experience}
                                            onChange={handleChange}
                                        >
                                            <option value={''} hidden>Choose</option>
                                            <option >Fresher</option>
                                            { Array.from( { length: 10 } ).map( ( _, index ) => {
                                                return ( <option key={ index } value={ index }>{ index } Year +</option> )
                                            } ) }
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom03">
                                        <Form.Label>Vacancies</Form.Label>
                                        <Form.Select
                                            required
                                            className='text-start'
                                            name='positions'
                                            value={data?.positions}
                                            onChange={handleChange}
                                        >
                                            <option value={''} hidden>Choose</option>
                                            {Array.from({ length: 100 }).map((_, index) =>
                                                <option key={index}>{index + 1}</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom03">
                                        <Form.Label>Working Hours</Form.Label>
                                        <Form.Select

                                            className='text-start'
                                            name='workHours'
                                            value={data?.workHours}
                                            onChange={handleChange}
                                        >
                                            <option value={''} hidden>Choose</option>
                                            {Array.from({ length: 12 }).map((_, index) =>
                                                <option key={index}>{index + 1} Hours</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>

                                    {/* <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom01">
                                        <Form.Label>Min Salary</Form.Label>
                                        <Form.Control

                                            type="number"
                                            name='salary'
                                            value={data.salary}
                                            onChange={handleChange}
                                            onWheel={e => e.target.blur()}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom01">
                                        <Form.Label>Max Salary</Form.Label>
                                        <Form.Control

                                            type="number"
                                            name='maxSalary'
                                            value={data.maxSalary}
                                            onChange={handleChange}
                                            onWheel={e => e.target.blur()}
                                        />
                                    </Form.Group> */}

                                    <Form.Group as={Col} sm="6" className='mb-3' controlId="validationCustom01">
                                        <Form.Label>Skills</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name='skills'
                                            value={data.skills}
                                            onChange={handleChange}
                                            placeholder='Use comma for multiple skills'
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>
                            <Col md="6">
                                <Form.Group className='mb-3' controlId="validationCustom05">
                                    <Form.Label>Job Description</Form.Label>
                                    <Form.Control
                                        as={"textarea"}
                                        required
                                        rows={4}
                                        name='description'
                                        value={data?.description}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className='mb-3' controlId="validationCustom05">
                                    <Form.Label>Responsibilities</Form.Label>
                                    {resp?.map((item, index) =>
                                        <div key={index} className='d-flex align-items-center mb-2'>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={item}
                                                onChange={e => handleChangeResp(e, index)}
                                                className='me-2'
                                            />
                                            <DeleteIcon onClick={() => handleRemoveResp(index)} />
                                        </div>
                                    )}
                                    <div role='button' className='text-end' onClick={handleAddResp}>+ Add More</div>
                                </Form.Group>

                                <Form.Group className='mb-3' controlId="validationCustom05">
                                    <Form.Label>Requirements</Form.Label>
                                    {requ?.map((item, index) =>
                                        <div key={index} className='d-flex align-items-center mb-2'>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={item}
                                                onChange={e => handleChangeRequ(e, index)}
                                                className='me-2'
                                            />
                                            <DeleteIcon onClick={() => handleRemoveRequ(index)} />
                                        </div>
                                    )}
                                    <div role='button' className='text-end' onClick={handleAddRequ}>+ Add More</div>
                                </Form.Group>
                                <Form.Group className='mb-3' controlId="validationCustom05">
                                    <Form.Label>Status</Form.Label>
                                    <div className='d-flex'>
                                    <Form.Check
                                        required
                                        type="radio"
                                        label={'Active'}
                                        id="Active"
                                        name='status'
                                        value={"Active"}
                                        onChange={handleChange}
                                        checked={data.status === "Active" ? true : false}
                                    />
                                    <Form.Check
                                        required
                                        type="radio"
                                        label={'Disable'}
                                        id="Disable"
                                        name='status'
                                        className='ms-3'
                                        value={"Disable"}
                                        onChange={handleChange}
                                        checked={data.status === "Disable" ? true : false}
                                    />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-center ">

                            { jobId &&
                                <Col className='text-end'>
                                    <button style={ {
                                        border: '1px solid #F93E3E',
                                        backgroundColor: '#F93E3E',
                                        color: '#000',
                                        minWidth: '120px',
                                        padding: '10px 0',
                                        textAlign: 'center',
                                        borderRadius: '5px'
                                    } } type="button" onClick={ () => deleteJob() } >Delete</button>
                                </Col>
                            }

                            <Col className='text-end'>
                                <RedOutButton type="button" title="CANCEL" handleSubmit={() => navigate("/jobs")} />
                            </Col>
                            <Col className='text-start'>
                                <GreenButton disabled={loading} title={jobId ? "UPDATE" : "CREATE"} btnType="submit" />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Job