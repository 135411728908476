import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth';
import styles from '../dispatchmanager/DishpatchManager.module.css'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Pagination from '../../components/Paginantion';
import OrdersTab from '../../components/OrdersTab';

const PurchaseOrders = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState("")
    const { switchDisplay, managerId } = useAuth();
    const location = useLocation().pathname
    const isOrderType = location.split("/")[location.split("/").length - 1]
    const [allData, setAllData] = useState([])
    const [activeTab, setActiveTab] = useState("pruchase")
    const [loading, setLoading] = useState(false)
    const [sortKey, setSortKey] = useState('')
    const [searchparams] = useSearchParams();
    const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;
    const [keyword, setKeyword] = useState('')
    const [orderStatus, setOrderStatus] = useState('')
    const [warehouseName, setWarehouseName] = useState('')
    const [managerName, setManagerName] = useState('')
    const [daysTab, setDaysTab] = useState('today')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [pages, setPages] = useState(1)
    const [warehouses, setWarehouses] = useState([])
    const [managers, setManagers] = useState([])

    useEffect(() => {
        if (isOrderType === "adujstmentorders") {
            setActiveTab("adjustment")
        }
    }, [isOrderType])

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": parseInt(managerId),
                "keyword": keyword,
                "sortKey": sortKey,
                // "orderType": orderType,
                "orderStatus": orderStatus,
                // "cartType": "",
                "warehouse": warehouseName,
                "inventorymanagerId": managerName,
                "day": daysTab,
                "startDate": startDate,
                "endDate": endDate,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + 'inventory/allPurchase?page=' + page + '&limit=8', requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setPages(result.pages)
                        setAllData(result.data)
                        setOrders(result)
                        if (result.warehouses) {
                            setWarehouses(result.warehouses)
                        }
                        if (result.managers) {
                            setManagers(result.managers)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }

    }, [keyword, page, orderStatus, warehouseName, managerName, daysTab, managerId, endDate, startDate, pages, activeTab, sortKey])



    return (
        <React.Fragment>
            <div className={`${!switchDisplay ? styles.bg_dark : styles.bg_white} ${styles.ff}`}>
                <OrdersTab
                    styles={styles}
                    orders={orders}
                    sortKey={sortKey}
                    setSortKey={setSortKey}
                    loading={loading}
                    // orderType={orderType}
                    // setOrderType={setOrderType}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    warehouses={warehouses}
                    warehouseName={warehouseName}
                    setWarehouseName={setWarehouseName}
                    managers={managers}
                    setManagers={setManagers}
                    managerName={managerName}
                    setManagerName={setManagerName}
                    daysTab={daysTab}
                    setDaysTab={setDaysTab}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    setOrderStatus={setOrderStatus}
                    orderStatus={orderStatus}
                />
                {loading ? <div className={'order_loadingMain'}>
                    <img src='/wallicon.gif' alt='walliconGIF' />
                </div> :
                    <>
                        <div className={styles.order_heightFix_div}>

                            <table className={styles.itemTable}>
                                <thead>
                                    <tr className={styles.item}>
                                        <th>Voucher No</th>
                                        <th>Vendor Name</th>
                                        <th>Warehouse</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Items</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        allData && allData.map((val, index) =>
                                            <tr key={index}
                                                className={styles.item}
                                                onClick={() => {
                                                    navigate('/orders/purchase?orderId=' + val.voucherNo)
                                                }}
                                            >
                                                <td>{val.voucherNo} </td>
                                                <td>{val.vendorName}</td>
                                                <td>{val.warehouse} </td>
                                                <td>{val.voucherDate} </td>
                                                <td>{val.totalAmount} </td>
                                                <td className={`${val.status === 'draft' ? styles.table_txt_yellow : styles.table_txt_green}`}>{val.status} </td>
                                                <td>{val.totalItems} </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>

                        </div>
                        {allData.length !== 0 && <Pagination
                            total={pages}
                            current={page}
                        />}
                    </>
                }
            </div>
        </React.Fragment>
    )
}
export default PurchaseOrders;