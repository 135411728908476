import React from 'react'
import { useLocation, useNavigate, useSearchParams, } from 'react-router-dom';
import { GreenOutButton } from './Buttons';
// import { useAuth } from './context/Auth';
import {  ReloadIcon, Search } from './icons/Icon';

const OrdersTab = (props) => {
    const {
        styles,
        orders,
        sortKey,
        setSortKey,
        loading,
        orderType,
        setOrderType,
        keyword,
        setKeyword,
        // warehouses,
        // warehouseName,
        // setWarehouseName,
        // managers,
        // managerName,
        // setManagerName,
        daysTab,
        setDaysTab,
        setStartDate,
        setEndDate,
        endDate,
        startDate,
        orderStatus,
        setOrderStatus, 
        // toWarehouse,
        // setToWarehouse
    } = props

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    // const { designation } = useAuth();
    const location = useLocation().pathname
    const data = !orders || orders === undefined || orders === "undefined" ? "" : orders

    return (
        <React.Fragment>
            <div className={styles.complain_header}>
                <h2 className={styles.heading}>Orders</h2>

                <div className={styles.celender_div}>
                    <div className={styles.reload} onClick={() => window.location.reload()}>
                        <span><ReloadIcon /></span>
                        <span className={styles.reload_txt}>Reload</span>
                    </div>

                    <div className={styles.dashboard_header_select_week}>
                        <div className={`${styles.dashboard_days_select} ${daysTab === 'today' ? styles.days_active : ''}`} onClick={() => setDaysTab('today')}>Today</div>
                        <div className={`${styles.dashboard_days_select} ${daysTab === 'yesterday' ? styles.days_active : ''}`} onClick={() => setDaysTab('yesterday')}>Yesterday</div>
                        <div className={`${styles.dashboard_days_select} ${daysTab === 'month' ? styles.days_active : ''}`} onClick={() => setDaysTab('month')}>This Month</div>
                        <div className={`${styles.dashboard_days_select} ${daysTab === 'previousyear' ? styles.days_active : ''}`} onClick={() => setDaysTab('previousyear')}>Previous Year</div>
                    </div>

                    <div className={styles.startEndDate_div}>
                        <span>Start Date&nbsp;&nbsp;</span>
                        <input
                            className={styles.startEndDate}
                            type="date"
                            value={startDate}
                            onChange={(e) => { setStartDate(e.target.value); setDaysTab('') }}
                        />
                    </div>
                    <div className={styles.startEndDate_div}>
                        <span>End Date&nbsp;&nbsp;</span>
                        <input
                            className={styles.startEndDate}
                            type="date"
                            value={endDate}
                            onChange={(e) => { setEndDate(e.target.value); setDaysTab('') }}
                        />
                    </div>


                    <GreenOutButton
                        btnType="button"
                        title="Create Voucher"
                        css={styles.addAdjestmentBtn}
                        handleSubmit={() => {
                            location.includes('/orders') ? navigate("/orders/estimate") :
                                location.includes('/cartorders') ? navigate("/orders/saleorder") :
                                    location.includes('/partnerorders') ? navigate("/orders/saleorder") :
                                        location.includes('/purchaseorders') ? navigate("/orders/purchase") :
                                            location.includes('/adjustmentorders') ? navigate("/orders/adjustment") :
                                                location.includes('/estimateorders') ? navigate("/orders/estimate") :
                                                    location.includes('/transferorders') ? navigate("/orders/transfer") :
                                                        location.includes('/allorders') ? navigate("/orders/saleorder") :
                                                            <></>
                        }}
                    />

                </div>
            </div>
            <div className={location === '/purchaseorders' || location === '/adjustmentorders' || location === '/estimateorders' || location === '/transferorders' ? styles.space_evenly : styles.statusCard_div}>
                {loading ?
                    <>
                        {
                            Array.from({ length: location === '/adjustmentorders' ? 2 : location === '/transferorders'? 5 : location === '/estimateorders' ? 6 : location === '/purchaseorders' ? 3 : 9 }).map((_, idx) =>
                                <div key={idx} className='skeleton'>
                                    <span className={styles.status_count_loading}>0</span>
                                    <span className={styles.statusTxt}>status</span>
                                </div>
                            )
                        }
                    </>
                    :
                    data.orders && data.orders.map((item, index) =>
                        <div key={index} className={`${styles.statusCard} ${orderStatus === item.status && item.status === 'New Order' ? styles.newOrder_active :
                            orderStatus === item.status && item.status === 'In Progress' ? styles.inProgress_active :
                                orderStatus === item.status && item.status === 'Dispatched Pending' ? styles.inProgress_active :
                                    orderStatus === item.status && item.status === 'Packed' ? styles.orange_active :
                                        orderStatus === item.status && item.status === 'Payment Pending' ? styles.inProgress_active :
                                            orderStatus === item.status && item.status === 'Payment Under Verification' ? styles.Pending_active :
                                                orderStatus === item.status && item.status === 'Deleted' ? styles.Pending_active :
                                                    orderStatus === item.status && item.status === 'Dispatched' ? styles.Dispatches_active :
                                                        orderStatus === item.status && item.status === 'Sale Done' ? styles.Dispatches_active :
                                                            orderStatus === item.status && item.status === 'Completed' ? styles.packed_active :
                                                                orderStatus === item.status && item.status === 'success' ? styles.Dispatches_active :
                                                                    orderStatus === item.status && item.status === 'draft' ? styles.inProgress_active :
                                                                        orderStatus === item.status && item.status === 'Completed' ? styles.packed_active :
                                                                            orderStatus === item.status && item.status === 'Cancelled' ? styles.cancel_status :
                                                                                orderStatus === item.status && item.status === 'COD' ? styles.cancel_status :
                                                                                    orderStatus === item.status && item.status === 'All' ? styles.orange_active :
                                                                                        orderStatus === item.status && item.status === 'Packing In Progress' ? styles.packed_active :
                                                                                            ''}`}
                            onClick={() => {
                                setOrderStatus(item.status);
                                if (searchParams.has("page")) {
                                    const token = searchParams.get("page");
                                    if (token) {
                                        searchParams.delete("page");
                                        setSearchParams(searchParams);
                                    }
                                }
                            }}>
                            <span className={
                                item.status === 'New Order' ? styles.statusNew :
                                    item.status === 'In Progress' ? styles.statusYellow :
                                        item.status === 'Dispatched Pending' ? styles.statusYellow :
                                            item.status === 'Payment Pending' ? styles.statusYellow :
                                                item.status === 'Payment Under Verification' ? styles.statusRed :
                                                    item.status === 'Deleted' ? styles.statusRed :
                                                        item.status === 'Completed' ? styles.statusPacking :
                                                            item.status === 'Packed' ? styles.statusOrange :
                                                                item.status === 'Dispatched' ? styles.statusGreen :
                                                                    item.status === 'Sale Done' ? styles.statusGreen :
                                                                        item.status === 'success' ? styles.statusGreen :
                                                                            item.status === 'draft' ? styles.statusYellow :
                                                                                item.status === 'Cancelled' ? styles.statusWhite :
                                                                                    item.status === 'COD' ? styles.statusWhite :
                                                                                        item.status === 'All' ? styles.statusAll :
                                                                                            item.status === 'Packing In Progress' ? styles.statusPacking :
                                                                                                ''}>
                                {item.count}
                            </span>
                            <span className={styles.statusTxt}>{item.status}</span>
                        </div>
                    )
                }
            </div>

            <div className={styles.complain_header}>
                <div
                    className={styles.complain_search_div}>
                    <input
                        type="search"
                        placeholder='Search by orderId'
                        className={styles.dealer_search}
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                    <Search color="#ffffff" css={styles.dealer_search_icon} />
                </div>

                {location !== "/transferorders" &&
                    <div className={styles.source_header_select_week}>
                        <div className={`${styles.source_days_select} ${orderType === '' ? styles.days_active : ''}`} onClick={() => setOrderType('')}>All</div>
                        <div className={`${styles.source_days_select} ${orderType === 'Online' ? styles.days_active : ''}`}
                            onClick={() => {
                                setOrderType('Online');
                                if (searchParams.has("page")) {
                                    const token = searchParams.get("page");
                                    if (token) {
                                        searchParams.delete("page");
                                        setSearchParams(searchParams);
                                    }
                                }
                            }}>Online</div>
                        <div className={`${styles.source_days_select} ${orderType === 'Offline' ? styles.days_active : ''}`} onClick={() => {
                            setOrderType('Offline');
                            if (searchParams.has("page")) {
                                const token = searchParams.get("page");
                                if (token) {
                                    searchParams.delete("page");
                                    setSearchParams(searchParams);
                                }
                            }
                        }}>Offline</div>
                    </div>}

                {/* <div className={styles.dealer_header_select_days}>

                    <div className={`${styles.dealer_days_select} ${location === "/orders" && styles.days_active}`}
                        onClick={() => { if (designation === "superadmin" || designation === "inventorymanager" || designation === "ordermanager") navigate("/orders") }}>Orders
                    </div>

                    <div className={`${styles.dealer_days_select} 
                        ${location === "/cartorders" && styles.days_active}`}
                        onClick={() => navigate("/cartorders")}>
                        Cart
                    </div>
                    <div className={`${styles.dealer_days_select} ${location === "/partnerorders" && styles.days_active}`}
                        onClick={() => navigate("/partnerorders")}>
            
                        Partner Cart
                    </div>
                    <div className={`${styles.dealer_days_select} ${location === "/purchaseorders" && styles.days_active}`}
                        onClick={() => { if (designation === "superadmin" || designation === "inventorymanager") navigate("/purchaseorders") }}>Purchase
                    </div>
                    <div className={`${styles.dealer_days_select} ${location === "/adjustmentorders" && styles.days_active}`}
                        onClick={() => { if (designation === "superadmin" || designation === "inventorymanager") navigate("/adjustmentorders") }}>Adjustment
                    </div>
                    <div className={`${styles.dealer_days_select} ${location === "/estimateorders" && styles.days_active}`}
                        onClick={() => { if (designation === "superadmin" || designation === "inventorymanager" || designation === "ordermanager") { navigate("/estimateorders") } }}>Estimate
                    </div>
                    <div className={`${styles.dealer_days_select} ${location === "/transferorders" && styles.days_active}`}
                        onClick={() => { if (designation === "superadmin" || designation === "inventorymanager" || designation === "ordermanager") { navigate("/transferorders") } }}>Transfer
                    </div>
                </div> */}

                {/* {designation === "superadmin" &&
                    <div className={styles.complain_Sort_By}>
                        <label className={styles.dashboard_sortBy_txt}>{location === "/transferorders" && 'From '}Warehouse</label>
                        <select
                            className={styles.dashboard_sortBy_select}
                            value={warehouseName}
                            onChange={(e) => setWarehouseName(e.target.value)}
                        >
                            <option value='All'>View All</option>
                            {
                                warehouses && warehouses.map((item, index) =>
                                    <option key={index} value={item.warehousename} >{item.warehousename}</option>
                                )
                            }
                        </select>
                    </div>
                } */}

                 {/* {location === "/transferorders" &&
                    <div className={styles.complain_Sort_By}>
                        <label className={styles.dashboard_sortBy_txt}>To Warehouse</label>
                        <select
                            className={styles.dashboard_sortBy_select}
                            value={toWarehouse}
                            onChange={(e) => setToWarehouse(e.target.value)}
                        >
                            <option value='All'>View All</option>
                            {
                                warehouses && warehouses.map((item, index) =>
                                    <option key={index} value={item.warehousename} >{item.warehousename}</option>
                                )
                            }
                        </select>
                    </div>
                } */}

                {/* {designation === "superadmin" &&
                    <div className={styles.complain_Sort_By}>
                        <label className={styles.dashboard_sortBy_txt}>Manager</label>
                        <select
                            className={styles.dashboard_sortBy_select}
                            value={managerName}
                            onChange={(e) => setManagerName(e.target.value)}
                        >
                            <option value='All'>View All</option>
                            {
                                managers && managers.map((item, index) =>
                                    <option key={index} value={item.managerId}>{item.status === 'active' ? <>&#128994;</> : item.status === 'disable' ? <>&#128308;</> : ''}&nbsp;{item.name}</option>
                                )
                            }
                        </select>
                    </div>
                } */}

                <div className={styles.complain_Sort_By}>
                    <label className={styles.dashboard_sortBy_txt}>Sort By </label>
                    <select
                        className={styles.dashboard_sortBy_select}
                        value={sortKey || orderStatus}
                        onChange={(e) => {
                            if (e.target.value === 'Ascending' || e.target.value === 'Descending' || e.target.value === 'lastAction') {
                                setSortKey(e.target.value)
                            } else {
                                setOrderStatus(e.target.value)
                                setSortKey("")
                            }
                        }}
                    >
                        <option value="All">View All</option>
                        {/* <option value="All">All</option> */}
                        <option value="New Order">New Order</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Payment Pending">Payment Pending</option>
                        <option value="Packing In Progress">Packing In Progress</option>
                        <option value="Dispatched">Dispatched</option>
                        <option value="Dispatched Pending">Dispatched Pending</option>
                        <option value="Ascending">Ascending</option>
                        <option value="Descending">Descending</option>
                        <option value="lastAction">Last Action</option>
                    </select>
                </div>

                {/* <div className={styles.dashboard_Sort_By} >
                    <div role='button' className={styles.dashboard_sortBy_txt}>Download</div>
                    <div role='button'><DownloadIcon /></div>
                </div> */}

            </div>

        </React.Fragment>
    )
}

export default OrdersTab