import React, { useState } from 'react'
import styles from './Buttons.module.css'
import { HelpCircle, Hyphen } from './icons/Icon'
import { useAuth } from './context/Auth'
import { useSearchParams } from 'react-router-dom'

const AllButtons = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default AllButtons

export const GreenOutButton = (props) => {
    return (
        <React.Fragment>
            <button type={props.btnType} className={`${props.css} ${styles.successBtn} ${styles.outBtnCSs}`} disabled={props.disabled} onClick={props.handleSubmit}>{props.title}</button>
        </React.Fragment>
    )
}
export const RedOutButton = (props) => {
    return (
        <React.Fragment>
            <button type={props.btnType} className={`${props.css} ${styles.dangerBtn} ${styles.outBtnCSs}`} onClick={props.handleSubmit}>{props.title}</button>
        </React.Fragment>
    )
}
export const BlackOutButton = (props) => {
    return (
        <React.Fragment>
            <button type={props.btnType} className={`${props.css} ${styles.darkBtn} ${styles.outBtnCSs}`} onClick={props.handleSubmit}>{props.title}</button>
        </React.Fragment>
    )
}
export const GreenButton = (props) => {
    return (
        <React.Fragment>
            <button type={props.btnType} disabled={props.disabled} className={`${props.css} ${styles.greenBtn}`} onClick={props.handleSubmit}>{props.title}</button>
        </React.Fragment>
    )
}
export const WhiteOutButton = (props) => {
    return (
        <React.Fragment>
            <button type={props.btnType} className={`${props.css}  ${styles.darkBtn}`} onClick={props.handleSubmit}>{props.title}</button>
        </React.Fragment>
    )
}
export const HelpButton = ({ helpData, setHelpData }) => {
    const [show, setShow] = useState(false)
    const [searchParams] = useSearchParams()
    const { managerId } = useAuth()
    const orderId = searchParams.get('orderId') ? searchParams.get('orderId') : ""

    const handleSubmit = () => {
        if (!helpData.reqType) {
            alert("Please select request type")
        } 
        else {

            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "reqType": helpData.reqType,
                "reqIssue": helpData.reqIssue
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/orderhelp", requestOptions)
                .then(response => response.json())
                .then(result => {
                    alert(result.msg)
                })
                .catch(error => console.log('error', error));
        }
    }
    return (
        <React.Fragment>
            <button
                className={styles.helpBtn}
                onClick={() => setShow(true)}
                type='button'
            >
                <HelpCircle /> Help
            </button>

            <div style={{ display: show ? "block" : "none" }}
                className={styles.helpModal}
            >

                <div className={styles.helpModalHeader}>
                    <h5>Need Help</h5>
                    <Hyphen onClick={() => setShow(false)} />
                </div>
                <body>
                    <select required={true}
                        value={helpData.reqType}
                        onChange={e => setHelpData(prevState => ({
                            ...prevState,
                            reqType: e.target.value
                        }))}
                    >
                        <option hidden>Select Request</option>
                        <option>Request For Edit</option>
                        <option>Request For Cancel</option>
                    </select>

                    <textarea
                        rows={4}
                        placeholder='Describe the issue'
                        value={helpData.reqIssue}
                        onChange={e => setHelpData(prevState => ({
                            ...prevState,
                            reqIssue: e.target.value
                        }))}
                    />

                    <h6>Or</h6>
                    <h5>Call : <a href={'tel:' + helpData.reqHelpNumber}>{helpData.reqHelpNumber}</a> </h5>


                    <div className='mt-4 text-center'>
                        <button type='button' onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </body>

            </div>
        </React.Fragment>
    )
}