import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth'
import { Search, } from '../../components/icons/Icon'
import styles from "./Admin.module.css"
import Pagination from '../../components/Paginantion'

const SuperAdmin = () => {

  const { managerId } = useAuth()
  const [data, setData] = useState([])
  const [orders, setOrders] = useState([])
  const [keyword, setKeyword] = useState('')
  const [sortKey, setSortKey] = useState('New Order')
  const [pages, setPages] = useState('')

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
        "keyword": keyword,
        "sortKey": sortKey,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "orders/orders?page=1", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === 'success') {
            setData(result.data)
            setOrders(result.orders)
            setPages(result.pages)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [managerId, keyword, sortKey])

  console.log('keyword', orders);

  return (
    <React.Fragment>

      <div className={styles.right_main}>
        <div className='d-flex justify-content-between mt-3'>
          <h1 className={styles.superAdmin_heading}>
            Dashboard
          </h1>
          {/* <div className={styles.dashboard_header_select_days}>
            <div className={`${styles.dashboard_days_select} ${styles.days_active}`}>Order</div>
            <div className={styles.dashboard_days_select}>Dispatch</div>
            <div className={styles.dashboard_days_select}>Packing</div>
            <div className={styles.dashboard_days_select}>Marketing</div>
          </div> */}
        </div>


        <div className={styles.superAdmin_height}>
          <div className={styles.dashboard_main_container}>
            <div className={styles.statusCard_div}>
              {orders?.length === 0 ? Array.from({ length: 6 }).map((_, idx) =>
                <div key={idx} className='skeleton'>
                  <span className={styles.status_count_loading}>0</span>
                  <span className={styles.statusTxt}>status</span>
                </div>
              )
                :
                orders?.map((item, index) =>
                  <div key={index} className={styles.statusCard}
                    onClick={() => setSortKey(item.status)}
                  >
                    <span className={
                      item.status === 'New Order' ? styles.statusNew :
                        item.status === 'Packing In Progress' ? styles.statusYellow :
                          item.status === 'Payment Pending' ? styles.statusRed :
                            item.status === 'COD' ? styles.statusBlue :
                              item.status === 'All Orders' ? styles.statusOrange :
                                item.status === 'Dispatched' ? styles.statusGreen :
                                  ''}>
                      {item.count}
                    </span>
                    <span className={styles.statusTxt}>{item.status}</span>
                  </div>
                )
              }
            </div>

            <div className={styles.shortBy_search}>
              <div className={styles.dashboard_search_div}>
                <input
                  type="search"
                  placeholder='Search'
                  className={styles.dashboard_search}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <Search color="#ffffff" css={styles.dashboard_search_icon} />
              </div>

              <div>
                <label className={styles.dashboard_sortBy_txt}>Sort By </label>
                <select
                  className={styles.dashboard_sortBy_select}
                  value={sortKey}
                  onChange={(e) => setSortKey(e.target.value)}
                >
                  <option value="" hidden>View All</option>
                  {
                    orders && orders?.map((item, index) =>
                      <option key={index} value={item.status} >{item.status}</option>
                    )
                  }
                </select>
              </div>
            </div>

            <div className={styles.adminHeight}>
              <table className={styles.itemTable}>
                <thead>
                  <tr className={styles.item}>
                    <th>Order Id</th>
                    <th>Name</th>
                    <th>Order Date</th>
                    <th>Source</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Last Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.map((val, index) =>
                    <tr key={index} className={styles.item}>
                      <td>{val.orderId} </td>
                      <td> {val.name}</td>
                      <td>{val.orderDate} </td>
                      <td>{val.source} </td>
                      <td>{val.amount} </td>
                      <td className={`${val.status === 'Dispatched' || val.status === 'Packed' ?
                        styles.table_txt_green : (val.status === 'Payment Pending') ?
                          styles.table_txt_red : (val.status === 'In Progress') ?
                            styles.table_txt_yellow : val.status === 'New Order' ?
                              styles.table_txt_blue : ''}`}>
                        {val.status}
                      </td>
                      <td>{val.lastaction} </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <Pagination total={pages} />

          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default SuperAdmin