import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { GreenButton, GreenOutButton, RedOutButton } from "../../components/Buttons";
import { DeleteIcon, Edit, Eye } from "../../components/icons/Icon";

const Delivery = () => {
    const [show, setShow] = useState(false);
    const [deleteModal, setDeletModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [courierName, setCourierName] = useState([])
    const [courierPartner, setCourierPartner] = useState([])

    const [fields, setFields] = useState([{ partnerName: '', partnerStatus: '' }]);
    const [fields1, setFields1] = useState({ courierName: '', disclaimer: '', enableForm: '', status: '' });

    const fieldRef = useRef(null);

    const handleChangeField = (e, index) => {
        const { name, value } = e.target;
        const list = [...fields];
        list[index][name] = value;
        setFields(list);
    };

    function handleAdd() {
        const values = [...fields];
        values.push({ partnerName: '', partnerStatus: '' });
        setFields(values);
        fieldRef.current?.scrollIntoView({ behavior: "" })
    }

    function handleRemove(i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }
    const handleChangeField1 = (e) => {
        const { name, value } = e.target;
        setFields1({
            ...fields1,
            [name]: value,
        });
    };

    const postData1 = () => {
        setRefresh(false)
        fetch(process.env.REACT_APP_URL + "deliverymethod/addDeliveryMethod", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "ch-platform": process.env.REACT_APP_ADMIN_SECRET,
            },

            body: JSON.stringify({
                "courierId": fields1.courierId ? fields1.courierId : "",
                "courierName": fields1.courierName,
                "partners": fields,
                "disclaimer": fields1.disclaimer,
                "enableForm": fields1.enableForm,
                "status": fields1.status
            }),
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setFields([{ partnerName: '', partnerStatus: '' }])
                    setFields1({ courierName: '', disclaimer: '', enableForm: '', status: '' })
                    getCourierName();
                    setShow(false)
                    setRefresh(true)
                }
            })
    };

    useEffect(() => {
        getCourierName()
    }, [refresh])

    const getCourierName = (id) => {
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "courierId": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    if (!result.data.partners) {
                        setCourierName(result.data)
                    }
                    else {
                        setCourierPartner(result.data.partners);
                        setFields(result.data.partners);
                        setFields1({
                            courierId: result.data._id,
                            courierName: result.data.courierName,
                            disclaimer: result.data.disclaimer,
                            enableForm: result.data.enableForm,
                            status: result.data.status
                        })
                    }
                }
            })
            .catch(error => console.log('error', error));
    }


    const deleteDeliveryMethod = () => {
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "courierId": fields1.courierId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "deliverymethod/deleteDeliveryMethod", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setDeletModal(false);
                    getCourierName();
                    setCourierPartner([])
                    alert('Delivery Method Deleted Successfully');
                }
            })
            .catch(error => console.log('error', error));
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            postData1()
        }

        setValidated(true);
    };


    return (
        <React.Fragment>
            <div className="bgSale w-100 text-white bgHeight cp p-3">
                <Row className="m-0">
                    <Col >
                        <h2 className="pf">Delivery</h2>
                    </Col>
                    <Col className="text-end">
                        <GreenOutButton title="Add Delivery Method"
                            handleSubmit={() => { setShow(true); setFields([{ partnerName: '', partnerStatus: '' }]); setFields1({}) }}
                            css="bg-transparent text-white wm_fit px-2"
                        />
                    </Col>
                </Row>

                {courierName && courierName.length === 0 ? <h1 className="shadow py-5 text-center bgColor my-5">No Delivery Methods</h1> :
                    <div className="rounded-2 bgColor shadow p-3 mb-5">
                        <h3 className="text-center">Delivery Methods</h3>
                        <Row className="m-0 w-100">
                            {courierName && courierName.map((item, index) =>
                                <Col xs={2} key={index} className=" mb-2">
                                    <div className="cardcss roundedBlck text-center py-3">
                                        <h5 className=" fw300 mouse" onClick={() => { getCourierName(item._id) }}>{item.courierName}</h5>
                                        <button className="me-3 courier_btn greenClr"
                                            onClick={() => { setShow(true); getCourierName(item._id) }}
                                        >
                                            <Edit color="#1ffc33" /><br />Edit
                                        </button>
                                        <button className="courier_btn redClr"
                                            onClick={() => { setDeletModal(true); getCourierName(item._id) }}
                                        >
                                            <DeleteIcon className="redClr" /><br />Delete
                                        </button>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </div>
                }
                {courierPartner.length === 0 ? null : <>
                    <div className="m-3">
                        <h5 className="text-center mt-5">Delivery Partners</h5>
                        <p className="text-end">View All</p>
                    </div>
                    {courierPartner.map((item, index) => (
                        <Row key={index} className="m-0 bgColor shadow p-3 align-items-center mt-2">
                            <Col>
                                <input type="radio" id="active" defaultChecked />
                                <label htmlFor="active">{item.partnerStatus}</label>
                                <h2>{item.partnerName}</h2>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <div className="bg-dark rounded-circle text-white mx-2 text-center mouse product_pev_btn">
                                    <Edit color="#1ffc33" />
                                    <br />
                                    Configure
                                </div>
                                <div className="bg-dark rounded-circle text-white mx-2 text-center mouse product_pev_btn">
                                    <Eye />
                                    <br />
                                    View
                                </div>
                            </Col>
                        </Row>
                    ))}
                </>}

                <Modal size="lg" show={show} onHide={() => setShow(false)} centered>
                    <Modal.Body className="deliveryModal text-white px-4">
                        <h4 className="text-center">Add Delivery Method</h4>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="bg-transparent textGray"
                                    placeholder="Enter Courier Name"
                                    name="courierName"
                                    value={fields1.courierName}
                                    onChange={handleChangeField1}
                                    required
                                />
                            </Form.Group>

                            <Form.Label> Add Partners</Form.Label>
                            <div className="partnerHeight">
                                {fields && fields.map((item, index) =>
                                    <div key={index} className="d-flex form_group" >
                                        <h6 className="me-2">{index + 1}.</h6>
                                        <Form.Group as={Row} className=" align-items-center">
                                            <Col sm="4" className="mb-2 ps-0">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Partner Name"
                                                    className="shadow p-2 bg-transparent textGray"
                                                    name="partnerName"
                                                    value={item.partnerName}
                                                    onChange={e => handleChangeField(e, index)}
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-2">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Contact Number"
                                                    className="shadow p-2 bg-transparent textGray"
                                                    name="contactNumber"
                                                    value={item.contactNumber}
                                                    onChange={e => handleChangeField(e, index)}
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-2">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Tracking Number"
                                                    className="shadow p-2 bg-transparent textGray"
                                                    name="trackingNumber"
                                                    value={item.trackingNumber}
                                                    onChange={e => handleChangeField(e, index)}
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-2 ps-0">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Tracking URL"
                                                    className="shadow p-2 bg-transparent textGray"
                                                    name="trackingUrl"
                                                    value={item.trackingUrl}
                                                    onChange={e => handleChangeField(e, index)}
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-2">
                                                <Form className="d-flex">
                                                    <Form.Label className="shadow py-2 d-flex align-items-center mb-0 ">
                                                        <Form.Check
                                                            type="radio"
                                                            className="bg-transparent textGray"
                                                            value="active"
                                                            name="partnerStatus"
                                                            onChange={e => handleChangeField(e, index)}
                                                            checked={item.partnerStatus === 'active' ? true : false}
                                                            label="Active"
                                                        />

                                                    </Form.Label>
                                                    <Form.Label className="shadow py-2 d-flex align-items-center mb-0 ms-4">
                                                        <Form.Check
                                                            type="radio"
                                                            value="disable"
                                                            name="partnerStatus"
                                                            onChange={e => handleChangeField(e, index)}
                                                            checked={item.partnerStatus === 'disable' ? true : false}
                                                            label="Disable"
                                                        />

                                                    </Form.Label>
                                                </Form>
                                            </Col>

                                            <Col ref={fieldRef} sm="4" className="mb-2">
                                                <div role="button" className="text-center shadow deletebtncss  shadow  py-1 text-danger" onClick={() => handleRemove(index)} >Delete</div>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                )}
                            </div>

                            <h6 role="button" className="mt-2 mb-3 mouse w-auto" onClick={() => handleAdd()}>+ Add More Partners</h6>


                            <Form.Group className="mb-3">
                                <Form.Label>Disclaimer (optional)</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    className="bg-transparent textGray"
                                    rows={2}
                                    name="disclaimer"
                                    placeholder="Enter Disclaimer"
                                    value={fields1.disclaimer}
                                    onChange={handleChangeField1}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 d-flex">
                                <Form.Check
                                    type="radio"
                                    label="Enable Custom Partner Form"
                                    className="fs_18"
                                    name="enableForm"
                                    value="active"
                                    onChange={handleChangeField1}
                                    checked={fields1.enableForm === 'active' ? true : false}
                                    required
                                />
                                <Form.Check
                                    type="radio"
                                    label="Disable Custom Partner Form"
                                    className="fs_18 ms-5"
                                    name="enableForm"
                                    value="disable"
                                    onChange={handleChangeField1}
                                    checked={fields1.enableForm === 'disable' ? true : false}
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3 ">
                                <Form.Label className="ps-0">Status</Form.Label>
                                <Col sm="3 ps-0" xs="6">
                                    <Form.Check
                                        type="radio"
                                        aria-label="radio 1"
                                        label="Active"
                                        value="active"
                                        name="status"
                                        onChange={handleChangeField1}
                                        checked={fields1.status === 'active' ? true : false}
                                        required
                                    />
                                </Col>
                                <Col sm="3" xs="6">
                                    <Form.Check
                                        type="radio"
                                        aria-label="radio 1"
                                        label="Disable"
                                        value="disable"
                                        name="status"
                                        onChange={handleChangeField1}
                                        checked={fields1.status === 'disable' ? true : false}
                                        required
                                    />
                                </Col>
                            </Form.Group>
                            <div className="text-center mt-4">
                                <RedOutButton btnType="Button" title="CANCEL" css="bg-transparent textGray me-4" handleSubmit={() => { setShow(false); setFields([{ partnerName: '', partnerStatus: '' }]); setFields1({}) }} />
                                <GreenButton btnType="Submit" title={!fields1.courierId ? "CREATE" : "UPDATE"} />
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={deleteModal} onHide={() => setDeletModal(false)} centered>
                    <Modal.Body className="bgSale cardcss text-white text-center">
                        <h2>Delete</h2>
                        <h6 className='my-5'>Are you sure you want to delete this delivery method?</h6>
                        <GreenButton title="CANCEL" css="me-5 " handleSubmit={() => { setDeletModal(false); }} />

                        <RedOutButton title="DELETE" css="ms-5 bg-transparent mb-4" handleSubmit={() => deleteDeliveryMethod(fields1.courierId)} />

                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment >
    );
};

export default Delivery;