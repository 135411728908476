import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth';
import styles from '../dispatchmanager/DishpatchManager.module.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GreenButton, RedOutButton } from '../../components/Buttons';
import Modal from '../../components/Modal';
import Pagination from '../../components/Paginantion';
import OrdersTab from '../../components/OrdersTab';
import OrderModal from './OrderModal';

const Orders = () => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;

  const { switchDisplay, managerId } = useAuth();
  const [msgShow, setMsgShow] = useState({
    status: "",
    msg: "",
  });
  const [orders, setOrders] = useState("")
  const [allData, setAllData] = useState([])
  const [showItemModal, setShowItemModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [orderData, setOrderData] = useState({})
  const [pages, setPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [sortKey, setSortKey] = useState('')
  const [orderStatus, setOrderStatus] = useState('')
  const [orderType, setOrderType] = useState('')
  const [keyword, setKeyword] = useState('')
  const [warehouses, setWarehouses] = useState([])
  const [warehouseName, setWarehouseName] = useState('')
  const [managers, setManagers] = useState([])
  const [managerName, setManagerName] = useState('')
  const [daysTab, setDaysTab] = useState('today')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    if (managerId) {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": parseInt(managerId),
        "keyword": keyword,
        "sortKey": sortKey,
        "orderStatus": orderStatus,
        "orderType": orderType,
        "cartType": "Cart",
        "warehousename": warehouseName,
        "ordermanagerId": managerName,
        "day": daysTab,
        "startDate": startDate,
        "endDate": endDate,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "orders/orders?page=" + page + '&limit=8', requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setLoading(false)
            setPages(result.pages)
            setAllData(result.data)
            setOrders(result)
            if (result.warehouses) {
              setWarehouses(result.warehouses)
            }
            if (result.managers) {
              setManagers(result.managers)
            }
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [managerId, page, sortKey, orderType, keyword, warehouseName, managerName, daysTab, endDate, startDate, orderStatus])


  const handleOrderAccept = (orderId) => {
    setMsgShow({
      status: "",
      msg: "",
    })
    var myHeaders = new Headers();
    myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "managerId": managerId,
      "orderId": orderId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "order/acceptorder", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "success") {
          setShowItemModal(false)
        }
        setMsgShow({
          status: result.status,
          msg: result.msg,
        })

        setShowAcceptModal(true)
      })
      .catch(error => console.log('error', error));
  }

  return (
    <React.Fragment>
      <div className={`${!switchDisplay ? styles.bg_dark : styles.bg_white} ${styles.ff}`}>

        <OrdersTab
          styles={styles}
          orders={orders}
          sortKey={sortKey}
          setSortKey={setSortKey}
          loading={loading}
          orderType={orderType}
          setOrderType={setOrderType}
          keyword={keyword}
          setKeyword={setKeyword}
          warehouses={warehouses}
          warehouseName={warehouseName}
          setWarehouseName={setWarehouseName}
          managers={managers}
          setManagers={setManagers}
          managerName={managerName}
          setManagerName={setManagerName}
          daysTab={daysTab}
          setDaysTab={setDaysTab}
          endDate={endDate}
          setEndDate={setEndDate}
          startDate={startDate}
          setStartDate={setStartDate}
          setOrderStatus={setOrderStatus}
          orderStatus={orderStatus}
        />
        {loading ? <div className={'order_loadingMain'}>
          <img src='/wallicon.gif' alt='walliconGIF' />
        </div> :
          <>
            <div className={styles.order_heightFix_div}>
              <table className={styles.itemTable}>
                <thead>
                  <tr className={styles.item}>
                    <th>  Name & City  </th>
                    <th>Date</th>
                    <th>Order Id</th>
                    <th>Source</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Last Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allData && allData.map((val, index) =>
                    <tr key={index}
                      className={styles.item}
                      onClick={() => {
                        if (val.status !== "New Order") {
                          navigate('/orders/saleorder?orderId=' + val.orderId);
                        } else {
                          setShowItemModal(true);
                          setOrderData(val)
                        }
                      }}
                    >
                      <td> {val.name}</td>
                      <td>{val.orderDate} </td>
                      <td>{val.orderId} </td>
                      <td>{val.source} </td>
                      <td>{val.amount} </td>
                      <td className={`${val.status === 'Dispatched' || val.status === 'Packed' ?
                        styles.table_txt_green : (val.status === 'Not Packed' || val.status === 'Dispatched Pending') ?
                          styles.table_txt_red : (val.status === 'Packing In Progress' || val.status === 'Draft' || val.status === 'In Progress') ?
                            styles.table_txt_yellow : val.status === 'New Order' ?
                              styles.table_txt_blue : ''}`}>
                        {val.status} {val.paymentStatus && <span title={val.paymentStatus} className={styles.infoIcon}>i</span>}
                      </td>
                      {/* <td className={`${val.status === 'In Progress' ? styles.table_txt_yellow : val.status === 'Updated' ? styles.table_txt_green : styles.table_txt_red}`}>{val.status} </td> */}
                      <td>{val.lastaction} </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              total={pages}
              current={page}
            />
          </>
        }

        <OrderModal
          show={showItemModal}
          close={setShowItemModal}
          closeBtn={true}
          heading={orderData.storeName}
          content={
            <div className={styles.text_center}>
              <div className={styles.product_date}>
                <span>Dealer Id: {orderData.dealerId}</span>
                <span>  {orderData.orderDate}</span>
              </div>
              <div className={styles.product_date}>Order Id: {orderData.orderId}</div>

              <div className={styles.itemCard_div}>
                {orderData.items && orderData.items.map((item, index) =>
                  <div key={index} className={switchDisplay ? styles.itemCard_light : styles.itemCard}>
                    <div className={styles.itemCard_left}>
                      <img src={process.env.REACT_APP_S3URL + item.thumbnail} alt="item_Image" className={styles.itemImage} />
                      <div className={styles.itemDetail_div}>
                        <div className={styles.itemDetail}>
                          <span className={styles.itemName_txt}>Product:</span>
                          <span className={styles.itemName}>{item.product_name}</span>
                        </div>
                        <div className={styles.itemDetail}>
                          <span className={styles.itemName_txt}>Item No:</span>
                          <span className={styles.itemName}>{item.itemNo}</span>
                        </div>
                        <div className={styles.itemDetail}>
                          <span className={styles.itemName_txt}>Price:</span>
                          <span className={styles.itemName}>{parseFloat(item.price).toLocaleString("en-IN", { style: "currency", currency: "INR" })}/{item.unit}</span>
                        </div>
                        <div className={styles.itemDetail}>
                          <span className={styles.itemName_txt}>Qty:</span>
                          <span className={styles.itemName}>{item.qty} {item.unit}</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.itemCard_right}>
                      <span className={styles.itemName_txt}>Total:</span>
                      <span className={styles.itemName}>&nbsp;{(parseFloat(item.price) * parseFloat(item.qty)).toLocaleString("en-IN", { style: "currency", currency: "INR" })}/-</span>
                    </div>
                  </div>
                )}
              </div>

              {/* <div>
                                    <select required className={styles.select}>
                                        <option hidden>Select Dispatch Warehouse</option>
                                        {orders.warehouses && orders.warehouses.map((item, index)=>
                                        <option key={index} value={item._id} >{item.warehousename}</option>
                                        )}
                                    </select>
                            </div> */}

              <div className={switchDisplay ? styles.modalFooter_div_light : styles.modalFooter_div}>
                <div className={styles.modalFooter_btn}>
                  <RedOutButton title="CANCEL" handleSubmit={() => setShowItemModal(false)} />
                  <GreenButton title="ACCEPT" handleSubmit={() => handleOrderAccept(orderData.orderId)} />
                </div>
                <div className={styles.modalFooter_GTotal}>
                  <div className={styles.borderBottom_div}>
                    <div className={styles.total_div}>
                      <span className={styles.itemName_txt}>Total</span>
                      <span className={styles.total_rs}>{parseFloat(orderData.subtotal).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                    </div>
                    <div className={styles.total_div}>
                      <span className={styles.itemName_txt}>Shipping Charges</span>
                      <span className={styles.total_rs}>{parseFloat(orderData.shippingInfo && orderData.shippingInfo.shippingCharge).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                    </div>
                  </div>
                  <div className={styles.grandTotal}>
                    <span>Grand Total:</span>
                    <span>{parseFloat(orderData.totalAmount).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <Modal
          show={showAcceptModal}
          close={setShowAcceptModal}
          closeBtn={true}
          heading="Order Status"
          content={
            <div className={styles.text_center}>
              <p className={styles.message}>{msgShow.msg}</p>
              <div className={styles.massageModal_btn}>
                <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                <GreenButton title="OK" handleSubmit={() => {
                  if (msgShow.status === "success") {
                    navigate('/orders/saleorder?orderId=' + orderData.orderId);
                    setShowAcceptModal(false);
                  } else {
                    setShowAcceptModal(false);
                  }
                }} />
              </div>
            </div>
          }
        />

      </div>
    </React.Fragment>
  )
}
export default Orders;