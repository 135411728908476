import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { GreenOutButton, RedOutButton } from '../../components/Buttons';
import { useAuth } from '../../components/context/Auth';
import { BlackPrint, DeleteFile, DownArrowCircle, NoImageIcon, UpArrowCircle } from '../../components/icons/Icon';
import styles from './DispatchBoy.module.css'
import DispatchModal from '../components/DispatchModal';
import { PackingSlip } from '../../pages/dispatchmanager/PackingSlip';
import { PrintLabel } from '../../pages/dispatchmanager/PrintLabel';
import { FileHandlerMob } from '../components/DragAndDropMob';
import { Modal } from 'react-bootstrap';

const ViewDispatchDetailsMob = () => {
    const navigate = useNavigate()
    let componentRef = React.useRef();
    const { managerId } = useAuth();
    const params = useParams()
    const orderId = params.orderId
    const [loading, setLoading] = useState(true);
    const [showLabelModal, setShowLabelModal] = useState(false);
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [msgShow, setMsgShow] = useState("");
    const [packer, setPacker] = useState(managerId);
    const [couriers, setCouriers] = useState([])
    const [partners, setPartners] = useState("")
    const [data, setData] = useState({})
    const [isReloaded, setIsReloaded] = useState(false)

    const [isImage, setisImage] = React.useState("");

    const [delivery, setDelivery] = useState({
        courierName: "",
        partnerName: "",
    })
    const [printLabel, setPrintLabel] = useState({
        qtyPacks: "",
        delPerName: "",
        delPerMobile: "",
        delTrackingNo: "",
        delTrackingUrl: "",
    })
    const [courierSlip, setCourierSlip] = useState("")
    const [detailShow, setDetailShow] = useState(false);
    const [showPackedModal, setShowPackedModal] = useState(false);
    const [check, setCheck] = useState(false);
    const [images, setImages] = useState([]);
    const [packs, setPacks] = useState()
    const [point, setPoint] = useState()

    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "courierName": delivery.courierName
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(result.data)
                        setPacker(result.data.order.pmId)
                        setCouriers(result.data.couriers)
                        setPartners(result.data.courierPartners)
                        setPrintLabel(prevState => ({
                            ...prevState,
                            qtyPacks: result.data.order.qtyPacks,
                            delPerName: result.data.order.delPerName,
                            delPerMobile: result.data.order.delPerMobile,
                            delTrackingNo: result.data.order.delTrackingNo,
                            delTrackingUrl: result.data.order.delTrackingUrl,
                        }))
                        if (!delivery.courierName) {
                            setDelivery(prevState => ({
                                ...prevState,
                                courierName: result.data.order.courierName,
                                partnerName: result.data.order.partnerName,
                                shippingType: result.data.order.shippingType,
                            }))
                        }
                        setCourierSlip(result.data.order.courierSlip)
                    }
                    setLoading(false)
                })
                .catch(error => console.log('error', error));
        }
    }, [orderId, managerId, delivery.courierName, isReloaded])

    // -----------------Courier---------//

    const handleAcceptOrder = (e) => {
        e.preventDefault()

        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId,
            "pmId": packer ? packer : managerId,
            "courierName": delivery.courierName,
            "partnerName": delivery.partnerName,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
            .then(response => response.json())
            .then(result => {
                setMsgShow(result.msg)
                setShowAcceptModal(true)
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        if (packs) {
            setPoint(Array.from(Array(packs === "0" ? 1 : packs === "1" ? 1 : packs === "2" ? 2 : parseInt(packs - 1)).keys()))
        }
    }, [packs])



    const addFormFields = () => {
        point.forEach(element => {
            setImages(prevState => ([...prevState, {}]))
        });
    }

    useEffect(() => {
        if (check && (images.length < parseInt(packs))) {
            point.forEach(element => {
                setImages(prevState => [
                    ...prevState.slice(0, images.length),
                    {},
                    // ...images.slice(index + 1)
                ])
            });
        }
    }, [check, images.length, packs, point])


    const handlePrintLabel = () => {
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId,
            "qtyPacks": printLabel.qtyPacks,
            "delPerName": printLabel.delPerName,
            "delPerMobile": printLabel.delPerMobile,
            "delTrackingNo": printLabel.delTrackingNo,
            "delTrackingUrl": printLabel.delTrackingUrl,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    handlePrint()
                    setShowLabelModal(false)
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handlePacked = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("packingQty", packs);
        formdata.append("images", images);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "packing/orderPacked/" + managerId + "/" + orderId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    window.location.reload()
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    const labelRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => labelRef.current
    });

    const handleDispatched = () => {
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("shippingType", delivery.shippingType);
        formdata.append("courierName", delivery.courierName);
        formdata.append("partnerName", delivery.partnerName);

        formdata.append("delPerName", printLabel.delPerName);
        formdata.append("delPerMobile", printLabel.delPerMobile);
        formdata.append("delTrackingNo", printLabel.delTrackingNo);
        formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
        formdata.append("image", courierSlip);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(process.env.REACT_APP_URL + "dispatch/deliveryDetails/" + managerId + "/" + orderId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setShowDeliveryModal(false)
                    setIsReloaded(Math.random())
                }
                alert(result.msg)
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if (isImage) {
            setImages()
        }
    }, [isImage])

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleDispatched()
        }
    };

    return (
        loading ? <div className={'Mob_dispatch_loading'}>
            <img src='/wallicon.gif' alt='walliconGIF' />
        </div> :
            <React.Fragment>
                <DispatchModal
                    show={showAcceptModal}
                    close={setShowAcceptModal}
                    closeBtn={true}
                    heading="DISPATCH ORDER STATUS"
                    content={
                        <div className={styles.modalMaindiv}>
                            <p className={styles.message}>{msgShow}</p>
                            <div className={styles.massageModal_btn}>
                                <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                                <GreenOutButton title="OK" handleSubmit={() => { setShowAcceptModal(false); window.location.reload() }} />
                            </div>
                        </div>
                    }
                />
                <DispatchModal
                    show={showPackedModal}
                    close={setShowPackedModal}
                    heading="PACKING STATUS"
                    content={
                        <form onSubmit={handlePacked} className={styles.text_center}>
                            <div className={styles.main_div}>
                                <div className={styles.flexdiv}>
                                    <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                                    <input type="number"
                                        name="packs"
                                        value={packs}
                                        // setPacks
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                        onChange={(e) => {
                                            setPacks(e.target.value)

                                            if (check && e.target.value && (images.length > parseInt(e.target.value))) {
                                                const values = [...images];
                                                values.splice(point, (images.length - (parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value))));
                                                setImages(values);
                                            }
                                        }}
                                        className={styles.input}
                                        required
                                        autoFocus={true}
                                    />
                                </div>

                                <div className={styles.checkBox_addImage}>
                                    <input
                                        type="checkbox"
                                        name="vehicle1"
                                        value="check"
                                        className={styles.checkbox}
                                        checked={check ? true : false}
                                        onChange={(e) => {
                                            if (e.target.checked && packs) {
                                                addFormFields()
                                                setCheck(true)
                                            } else {
                                                const values = [...images];
                                                values.splice(point, point.length);
                                                setImages(values);
                                                setCheck(false)
                                            }
                                        }}
                                    />
                                    <label htmlFor="vehicle1">&nbsp;&nbsp;Add image of every pack</label>
                                </div>

                                <div className={styles.dragAndDrop_div}>
                                    <FileHandlerMob
                                        setisImage={setisImage}
                                        title="Upload Image"
                                    />
                                </div>
                                <div>
                                    {images.map((item, index) =>
                                        <div key={index} className={styles.flexdiv}>
                                            <span>Pack {index + 1}</span>

                                            {item.name ? <img src={URL.createObjectURL(item)} alt="" className={styles.fileimg}
                                                onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")}
                                            /> :
                                                <img
                                                    src='/assets/packingImage.png'
                                                    alt='packing_order_image'
                                                    className={styles.packingImage}
                                                />
                                            }
                                            <div className={styles.view_packingImage} onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")} >View</div>
                                            <div className={styles.deletediv}
                                                onClick={e => {
                                                    let data = [...images];
                                                    data[index] = {};
                                                    setImages(data);
                                                }}
                                            >
                                                <DeleteFile color={"red"} />
                                                <p className={styles.deleteicon_css}>Delete</p>
                                            </div>
                                            {images.length !== 1 &&
                                                <div className={styles.deletediv}
                                                    onClick={e => {
                                                        setPacks(parseInt(packs) - 1)
                                                        const list = [...images];
                                                        list.splice(index, 1);
                                                        setImages(list);
                                                    }}
                                                >
                                                    <span className={styles.crossIcon_css}>&#10006;</span>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.flexdiv}>
                                <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => setShowPackedModal(false)} />
                                <GreenOutButton btnType="submit" title="OK" />
                            </div>
                        </form>
                    }
                />

                <Modal show={showDeliveryModal} className={'dispatchModal'} onHide={() => setShowDeliveryModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delivery Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pb-0'>
                        <form onSubmit={handleSubmit}>
                            <div className='position-relative mb-2'>
                                <label className={styles.inputLabel}>Delivery Type</label>
                                <select
                                    // className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    className={styles.inputdiv}
                                    value={delivery.shippingType}
                                    onChange={(e) => setDelivery(prevState => ({
                                        ...prevState,
                                        shippingType: e.target.value
                                    }))}
                                    required
                                >
                                    <option value="" hidden></option>
                                    <option>Standard</option>
                                    <option>Express</option>
                                </select>
                            </div>
                            <div className='position-relative mb-2'>
                                <label className={styles.inputLabel}>Delivery Method</label>
                                <select
                                    // className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    className={styles.inputdiv}
                                    value={delivery.courierName}
                                    onChange={(e) => setDelivery(prevState => ({
                                        ...prevState,
                                        courierName: e.target.value
                                    }))}
                                    required
                                >
                                    <option value="" hidden></option>
                                    {couriers && couriers.map((obj, index) =>
                                        <option key={index} value={obj.courierName}>{obj.courierName}</option>
                                    )}
                                </select>
                            </div>
                            <div className='position-relative mb-2'>
                                <label className={styles.inputLabel}>Delivery Partner</label>
                                <select
                                    // className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    className={styles.inputdiv}
                                    value={delivery.partnerName}
                                    onChange={(e) => {
                                        setDelivery(prevState => ({
                                            ...prevState,
                                            partnerName: e.target.value
                                        }));
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delPerName: partners && partners.partners.find(x => x.partnerName === e.target.value)?.partnerName,
                                            delPerMobile: partners && partners.partners.find(x => x.partnerName === e.target.value)?.contactNumber,
                                            delTrackingNo: partners && partners.partners.find(x => x.partnerName === e.target.value)?.trackingNumber,
                                            delTrackingUrl: partners && partners.partners.find(x => x.partnerName === e.target.value)?.trackingUrl,
                                        }));
                                    }}
                                    required
                                >
                                    <option value="" hidden></option>
                                    {partners.partners && partners.partners.map((obj, index) =>
                                        <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
                                    )}
                                </select>
                            </div>

                            <div className='position-relative mb-2'>
                                <label className={styles.inputLabel}>Delivery Person</label>
                                <input
                                    className={styles.inputdiv}
                                    name="name"
                                    type="text"
                                    // placeholder='Name'
                                    value={printLabel.delPerName}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delPerName: e.target.value
                                        }))
                                    }}
                                />
                            </div>
                            <div className='position-relative mb-2'>
                                <label className={styles.inputLabel}>Contact Number</label>
                                <input
                                    className={styles.inputdiv}
                                    name="mobile"
                                    type="number"
                                    // placeholder='Mobile Number'
                                    value={printLabel.delPerMobile}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delPerMobile: e.target.value
                                        }))
                                    }}
                                    onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                />
                            </div>
                            <div className='position-relative mb-2'>
                                <label className={styles.inputLabel}>Tracking Number</label>
                                <input
                                    className={styles.inputdiv}
                                    name="name"
                                    type="text"
                                    // placeholder='Tracking Number'
                                    value={printLabel.delTrackingNo}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delTrackingNo: e.target.value
                                        }))
                                    }}
                                />
                            </div>
                            <div className='position-relative'>
                                <label className={styles.inputLabel}>Tracking URL</label>
                                <input
                                    className={styles.inputdiv}
                                    name="name"
                                    type="text"
                                    // placeholder='Tracking URL'
                                    value={printLabel.delTrackingUrl}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delTrackingUrl: e.target.value
                                        }))
                                    }}
                                />
                            </div>
                            <h2 className={styles.modal_heading}>Or</h2>
                            <div className='mb-3'>
                                {courierSlip ?
                                    <div className={styles.courierSlip}>
                                        <label onClick={() => { setCourierSlip("") }}>X</label>
                                        <img src={courierSlip.name ? URL.createObjectURL(courierSlip)
                                            : process.env.REACT_APP_S3URL + courierSlip}
                                            alt='Courier Slip'
                                            height={86}
                                            className={`w-100 ${styles.courierSlip_image}`}
                                            onClick={() => {
                                                window.open(courierSlip.name ? URL.createObjectURL(courierSlip)
                                                    : process.env.REACT_APP_S3URL + courierSlip)
                                            }}
                                        />
                                    </div>
                                    :
                                    <FileHandlerMob
                                        setisImage={(item) => setCourierSlip(item[0])}
                                        styles={styles}
                                        title="Upload Courier Slip"
                                    />
                                }
                            </div>
                            <div className={styles.dispatchModalBtm}>
                                <RedOutButton btnType="button" title={"CANCEL"} handleSubmit={() => { setShowDeliveryModal(false) }} />
                                <GreenOutButton title={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DISPATCHED" : "DISPATCHED"} />
                            </div>
                        </form>


                    </Modal.Body>
                </Modal>

                <DispatchModal
                    show={showLabelModal}
                    close={setShowLabelModal}
                    content={
                        <div className={styles.modalMaindiv1}>
                            <h2 className={styles.modal_heading}>Print Label</h2>
                            <p className={styles.modaltext}>Are you sure you want to Print Label
                                & Marked as Packed?</p>
                            <div className={styles.flexdiv}>
                                <p className={styles.sliptext}>Enter Number Of Packs</p>
                                <input
                                    name="name"
                                    type="number"
                                    className={styles.input}
                                    value={printLabel.qtyPacks}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            qtyPacks: e.target.value
                                        }))
                                    }}
                                />
                            </div>
                            {detailShow &&
                                <form>
                                    <label>Delivery Partner</label>
                                    <input
                                        className={styles.inputdiv}
                                        name="name"
                                        type="text"
                                        placeholder='Name'
                                        value={printLabel.delPerName}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerName: e.target.value
                                            }))
                                        }}
                                    />
                                    <label>Contact Number</label>
                                    <input
                                        className={styles.inputdiv}
                                        name="mobile"
                                        type="number"
                                        placeholder='Mobile Number'
                                        value={printLabel.delPerMobile}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerMobile: e.target.value
                                            }))
                                        }}
                                    />
                                    <label>Tracking Number</label>
                                    <input
                                        className={styles.inputdiv}
                                        name="name"
                                        type="text"
                                        placeholder='Tracking Number'
                                        value={printLabel.delTrackingNo}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delTrackingNo: e.target.value
                                            }))
                                        }}
                                    />
                                    <label>Tracking URL</label>
                                    <input
                                        className={styles.inputdiv}
                                        name="name"
                                        type="text"
                                        placeholder='Tracking URL'
                                        value={printLabel.delTrackingUrl}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delTrackingUrl: e.target.value
                                            }))
                                        }}
                                    />
                                </form>
                            }
                            <div className={styles.flexdiv}>
                                <RedOutButton title={"CANCEL"} handleSubmit={() => { setShowLabelModal(false) }} />
                                <div className={styles.printicon}>
                                    <GreenOutButton css={!printLabel.qtyPacks ? styles.disableBtn : ''} title={"PRINT"} disabled={printLabel.qtyPacks ? false : true} handleSubmit={() => { handlePrintLabel() }} />
                                </div>
                                <div style={{ display: "none" }}>
                                    <PrintLabel
                                        data={data.order}
                                        qtyPacks={printLabel.qtyPacks}
                                        ref={labelRef}
                                        handlePrint={handlePrint}
                                    />
                                </div>
                            </div>
                            <div className={styles.flexdiv}>
                                <p> Add Delivery Details</p>
                                <div onClick={() => setDetailShow(!detailShow)}>{detailShow ? <UpArrowCircle /> : <DownArrowCircle />}</div>
                            </div>
                        </div>
                    }
                />

                <div className={styles.bg_white}>
                    <div className={styles.packingOrder}>
                        <form
                            onSubmit={handleAcceptOrder}
                            className={styles.marketing}>

                            <div className={styles.flexdiv1}>
                                <div className={styles.order_text}>
                                    <div>Dated: <b className={styles.textright}>{data.order && data.order.orderDate}</b></div>
                                    <div>Order ID: <b className={styles.textright}>{orderId}</b></div>
                                </div>


                                <div className='d-flex flex-column '>

                                    <div className='d-flex'>
                                        <span className={styles.dispatch_status}>Dispatch Status</span>
                                        <div className={`${styles.status_height} ${(data.order.dispatchStatus === "New Order") ? styles.status_blue :
                                            (data.order.dispatchStatus === "Packed" || data.order.dispatchStatus === "Dispatched") ? styles.statuscolor3 :
                                                (data.order.dispatchStatus === "Not Packed") ? styles.statuscolor1 :
                                                    (data.order.dispatchStatus === 'In Progress' || data.order.dispatchStatus === 'Pending') ?
                                                        styles.status : ''
                                            }`}>
                                            &#9679; {data.order.dispatchStatus}
                                        </div>
                                    </div>


                                    <div className='d-flex'>
                                        <span className={styles.dispatch_status}>Packing Status</span>
                                        <div className={`${styles.status_height} ${(data.order.packingStatus === "New Order") ? styles.status_blue :
                                            (data.order.packingStatus === "Packed" || data.order.packingStatus === "Dispatched") ? styles.statuscolor3 :
                                                (data.order.packingStatus === "Not Packed") ? styles.statuscolor1 :
                                                    (data.order.packingStatus === 'In Progress' || data.order.packingStatus === 'Pending') ?
                                                        styles.status : ''
                                            }`}>
                                            &#9679; {data.order.packingStatus}
                                        </div>
                                    </div>
                                </div>

                                <>
                                    {data.order.dispatchStatus === "In Progress" &&
                                        <ReactToPrint
                                            trigger={() =>

                                                <div className={styles.printicon}>
                                                    <BlackPrint color={"#000000"} />
                                                    <p className={styles.print}>Print Slip</p>
                                                </div>
                                            }
                                            content={() => componentRef}
                                        />
                                    }
                                    <div style={{ display: "none" }}>
                                        <PackingSlip
                                            orderId={orderId}
                                            managerId={managerId}
                                            delivery={delivery}
                                            ref={(el) => (componentRef = el)}
                                        />
                                    </div>
                                </>
                                {((data.order.dispatchStatus === "Packed") || (data.order.dispatchStatus === "Dispatched") || (data.order.dispatchStatus === "Pending")) &&
                                    <div className={styles.printicon}
                                        onClick={() => { setShowLabelModal(true); }}
                                    >
                                        <BlackPrint color={"#000000"} />
                                        <p className={styles.print}>Label</p>
                                    </div>
                                }
                            </div>
                            <div>
                                <h2 className={styles.packingDetailsHeading}>
                                    {data.order && data.order.storeName}
                                </h2>
                                <div className={styles.packingDetails_div}>
                                    <span>Ship To: </span>
                                    <span>{data.order.shippingInfo.name && data.order.shippingInfo.name}</span>
                                </div>
                                <div className={styles.packingDetails_div}><span>Address: </span>
                                    {data.order && <span>{data.order.shippingInfo &&
                                        (data.order.shippingInfo.street + ", " + data.order.shippingInfo.city + ", " +
                                            data.order.shippingInfo.state + ", " + data.order.shippingInfo.pincode
                                        )}</span>}
                                </div>
                                <div className={styles.packingDetails_div}>
                                    <span>GSTUIN: </span>
                                    <span>{data.order && data.order.gstuin}</span>
                                </div>
                                {data.order &&
                                    <>
                                        {/* <div className={styles.packingDetails_div}><span>State: </span>
                                            <span>{data.order.shippingInfo && data.order.shippingInfo.state}</span>
                                        </div> */}
                                        <div className={styles.packingDetails_div}><span>Contact: </span>
                                            <span>{data.order.shippingInfo && data.order.shippingInfo.mobile}</span>
                                        </div>
                                        <div className={styles.packingDetails_div}><span>Email: </span>
                                            <span>{data.order && data.order.email}</span>
                                        </div>
                                    </>
                                }
                            </div>
                            <h2 className={styles.packingDetailsHeading}><u>Delivery Details</u></h2>
                            <div className={styles.packingDetails_div}>

                                <div className={styles.packingDetails_txt}>
                                    <span className={styles.slipDetailsTxt}>Delivery Partners</span>
                                    <span className={styles.slipDetailsTxt}>{delivery.partnerName}</span>
                                </div>

                                <div className={styles.packingDetails_txt}>
                                    <div className={styles.ordermanagerName}>Contact Number</div>
                                    {data.order.pmcontact &&
                                        <a className='text-dark' href={`tel:+91${data.order.pmcontact}`}>
                                            <b className='ps-1'>+91-{data.order.pmcontact}</b>
                                        </a>
                                    }
                                </div>

                                <div className={styles.packingDetails_txt}>
                                    <div className={styles.ordermanagerName}>Tracking Number</div>
                                    {data.order.delTrackingNo &&
                                        <u><b>{(data.order.delTrackingNo === 'undefined') ? '' : data.order.delTrackingNo}</b></u>
                                    }
                                </div>

                                <div className={styles.packingDetails_txt}>
                                    <div className={styles.ordermanagerName}>Tracking URL</div>
                                    {data.order.delTrackingUrl &&
                                        <u><b>{data.order.delTrackingUrl === 'undefined' ? '' : data.order.delTrackingUrl}</b></u>
                                    }
                                </div>

                            </div>

                            <div>
                                {data.order.courierSlip ?
                                
                                    <img
                                        src={process.env.REACT_APP_S3URL + data.order.courierSlip} className={styles.main_pack_image} alt='delivery__slip'
                                        onClick={() => window.open(process.env.REACT_APP_S3URL + data.order.courierSlip, "mozillaWindow", "popup")}
                                    /> :
                                    // <img src='/assets/img.png' alt='slip_image' className={styles.Delivery_details_slip} />
                                    ''
                                }
                                {
                                    data.order.packingImgs && data.order.packingImgs.map((item, index) =>
                                        <img key={index} src={process.env.REACT_APP_S3URL + item} className={styles.main_pack_image} alt='pack_image'
                                            onClick={() => window.open(process.env.REACT_APP_S3URL + item, "mozillaWindow", "popup")} />
                                    )}
                            </div>

                            <div className={styles.itemsScroll}>
                                {data.order && data.order.items.map((item, index) =>
                                    <div key={index} className={styles.dispatch_item_detail}>
                                        <div className={styles.flex_dispatch_details}>
                                            <div>
                                                {item.thumbnail ?
                                                    <img
                                                        src={process.env.REACT_APP_S3URL + item.thumbnail} height='50px' width='40px' alt={item.itemNo}
                                                        onClick={() => window.open(process.env.REACT_APP_S3URL + item.thumbnail, "mozillaWindow", "popup")}
                                                    /> :
                                                    <div className={styles.NoImageIcon}><NoImageIcon /></div>
                                                }
                                            </div>
                                            <div className={styles.flexMA}>
                                                <div className={styles.packingDetails_div}><span>Product Name:</span><span>{item.product_name}</span></div>
                                                <div className={styles.packingDetails_div}><b><span>Item No:</span><span>{item.itemNo}</span></b></div>
                                                <div className={styles.packingDetails_div}>{item.warehouse}</div>
                                                <div className={styles.packingDetails_div}><b><span>Qty:</span> <span>{item.qty}</span></b></div>
                                            </div>
                                        </div>


                                        {item.packingImg &&
                                            <img src={
                                                item.packingImg ? process.env.REACT_APP_S3URL + item.packingImg
                                                    : "/assets/img.png"}
                                                alt="img" className={styles.dispatchItemBox_image}
                                            />
                                        }
                                        {/* <img src='/assets/main_pack.png' alt="item_image" className={styles.dispatchItemBox_image} /> */}
                                    </div>
                                )}
                            </div>

                            <div className='d-flex justify-content-between mt-2'>
                                <button type='button' className={styles.dispatch_back_btn} onClick={() => navigate(-1)}>BACK</button>
                                <button type='button' className={styles.dispatch_update_btn} onClick={() => {
                                    setPrintLabel(prevState => ({
                                        ...prevState,
                                        delPerName: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.partnerName,
                                        delPerMobile: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.contactNumber,
                                        delTrackingNo: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.trackingNumber,
                                        delTrackingUrl: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.trackingUrl,
                                    }));
                                    setShowDeliveryModal(true)
                                }}
                                >
                                    {data.order.dispatchStatus === "Dispatched" ? "UPDATE" : "DETAILS"}</button>
                                <button type='button' className={styles.dispatch_update_btn}>SHARE</button>
                            </div>

                        </form>
                    </div>
                </div>
            </React.Fragment >
    )
}
export default ViewDispatchDetailsMob;
