import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useAuth } from '../../components/context/Auth';
import { DownArrowCircle, Printbtn, UpArrowCircle } from '../../components/icons/Icon';
import styles from './DishpatchManager.module.css'
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
import { useNavigate, useParams, } from 'react-router-dom';
import { PackingSlip } from './PackingSlip';
import { PrintLabel } from './PrintLabel';
import Modal from '../../components/Modal';
// import { FileHandler } from '../../components/DragAndDrop';
// import modalcss from '../../components/Modal.module.css'

const PackingVoucher = () => {

    let componentRef = React.useRef();
    const navigate = useNavigate();
    const { switchDisplay, managerId } = useAuth();
    const params = useParams()
    const orderId = params.orderId
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [showLabelModal, setShowLabelModal] = useState(false);
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);

    const [data, setData] = useState({})
    const [isReloaded, setIsReloaded] = useState(false)
    // const [lableBtnLoading, setLableBtnLoading] = useState(false)


    // const [isImage, setisImage] = React.useState("");
    // const [delivery, setDelivery] = useState({
    //     courierName: "",
    //     partnerName: "",
    // })
    const [printLabel, setPrintLabel] = useState({
        qtyPacks: "",
        delPerName: "",
        delPerMobile: "",
        delTrackingNo: "",
        delTrackingUrl: "",
        courierSlip: "",
    })
    // const [courierSlip, setCourierSlip] = useState()

    const [showPackedModal, setShowPackedModal] = useState(false);
    // const [check, setCheck] = useState(false);
    // const [images, setImages] = useState([]);
    // const [packs, setPacks] = useState()
    // const [point, setPoint] = useState()

    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "orders/order", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(result.data)
                    }
                })
                .catch(error => console.log('error', error))
                .finally(() => setLoading(false))
        }
    }, [orderId, managerId, isReloaded])


    // useEffect(() => {
    //     if (packs) {
    //         setPoint(Array.from(Array(packs === "0" ? 1 : packs === "1" ? 1 : packs === "2" ? 2 : parseInt(packs - 1)).keys()))
    //     }
    // }, [packs])



    // const addFormFields = () => {
    //     point.forEach(element => {
    //         setImages(prevState => ([...prevState, {}]))
    //     });
    // }

    // useEffect(() => {
    //     if (check && (images.length < parseInt(packs))) {
    //         point.forEach(element => {

    //             setImages(prevState => [
    //                 ...prevState.slice(0, images.length),
    //                 {},
    //                 // ...images.slice(index + 1)
    //             ])
    //         });
    //     }
    // }, [check, images.length, packs, point])


    const handlePrintLabel = () => {
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId,
            "qtyPacks": printLabel.qtyPacks,
            "delPerName": printLabel.delPerName,
            "delPerMobile": printLabel.delPerMobile,
            "delTrackingNo": printLabel.delTrackingNo,
            "delTrackingUrl": printLabel.delTrackingUrl,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    handlePrint()
                    setShowLabelModal(false)
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    // console.log('images', images);

    const handlePacked = (e) => {
        // e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        // var formdata = new FormData();
        // formdata.append("managerId", managerId);
        // formdata.append("orderId", orderId);
        // // images && images.map((image) =>
        // //     formdata.append("images", image)
        // // )

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(process.env.REACT_APP_URL + "orders/orderpacked/", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    window.location.reload()
                }
                alert(result.msg)
            })
            .catch(error => console.log('error', error));
    }

    const labelRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => labelRef.current
    });

    const handleDispatched = () => {
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        // var formdata = new FormData();
        // formdata.append("shippingType", delivery.shippingType);
        // formdata.append("courierName", delivery.courierName);
        // formdata.append("partnerName", delivery.partnerName);

        // formdata.append("delPerName", printLabel.delPerName);
        // formdata.append("delPerMobile", printLabel.delPerMobile);
        // formdata.append("delTrackingNo", printLabel.delTrackingNo);
        // formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
        // formdata.append("image", courierSlip[0]);

        // var requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: formdata,
        //     redirect: 'follow'
        // };


        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "orders/dispatched", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setShowDeliveryModal(false)
                    setIsReloaded(Math.random())
                }
                alert(result.msg)
            })
            .catch(error => console.log('error', error));
    }
    // useEffect(() => {
    //     if (isImage) {
    //         setImages(prevState => ([...prevState, ...isImage]))
    //     }
    // }, [isImage])

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //     } else {
    //         handleDispatched()
    //     }
    // };


    return (
        <React.Fragment>
            {/* {data.order &&
                <PrintLabel
                    data={data.order}
                    qtyPacks={1}
                // ref={labelRef}
                // handlePrint={handlePrint}
                />} */}

            {/* ----------------------------------Print Label Modal-------------------------- */}
            <Modal
                show={showLabelModal}
                close={setShowLabelModal}
                closeBtn={true}
                heading="PRINT LABEL"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.sliptext}>Are you sure you want to Print Label & Marked as Packed?</p>
                        <div className={styles.modal_div}>
                            <p className={styles.sliptext}>Enter Number Of Packs</p>
                            <input
                                name="name"
                                type="number"
                                className={`${switchDisplay ? styles.inputcolorpack : styles.inputpack}`}
                                value={printLabel.qtyPacks}
                                onChange={(e) => {
                                    setPrintLabel(prevState => ({
                                        ...prevState,
                                        qtyPacks: e.target.value
                                    }))
                                }}
                            />
                        </div>
                        {show ?
                            <div>
                                <div className={styles.modal_div}>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Name'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delPerName}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerName: e.target.value
                                            }))
                                        }}
                                    />
                                    <input
                                        name="mobile"
                                        type="number"
                                        placeholder='Mobile Number'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delPerMobile}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerMobile: e.target.value
                                            }))
                                        }}
                                    />
                                </div>
                                <div className={styles.modal_div}>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Tracking Number'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delTrackingNo}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delTrackingNo: e.target.value
                                            }))
                                        }}
                                    />
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Tracking URL'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delTrackingUrl}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delTrackingUrl: e.target.value
                                            }))
                                        }}
                                    />
                                </div>
                            </div> : ""
                        }
                        <div className={styles.modalBtn_div}>
                            <RedOutButton title={"CANCEL"} handleSubmit={() => { setShowLabelModal(false) }} />
                            <div className={styles.printicon}>
                                <GreenOutButton css={!printLabel.qtyPacks ? styles.disableBtn : ''} title={"PRINT"} disabled={printLabel.qtyPacks ? false : true} handleSubmit={() => { handlePrintLabel() }} />
                            </div>
                            <div style={{ display: "none" }}>
                                <PrintLabel
                                    data={data.order}
                                    SubDistName={data.SubDistName}
                                    districts={data.districts}
                                    qtyPacks={printLabel.qtyPacks}
                                    ref={labelRef}
                                    handlePrint={handlePrint}
                                />
                            </div>
                        </div>
                        <div className={styles.modal_div} onClick={() => setShow(!show)}>
                            {show ? <p className={styles.sliptext}>Add Delivery Details</p> : <p className={styles.sliptext}>Hide Delivery Details</p>}

                            <div>
                                {show ? <DownArrowCircle color={switchDisplay ? "#000000" : "#FFFFFF"} /> : <UpArrowCircle color={switchDisplay ? "#000000" : "#FFFFFF"} />}
                            </div>
                        </div>
                    </div>
                }
            />

            {/* ----------------------------------Dispatch Modal-------------------------- */}
            <Modal
                show={showDeliveryModal}
                close={setShowDeliveryModal}
                closeBtn={true}
                heading={"Dispatched"}
                content={
                    <div>
                        <h2 className='my-5'> Are you sure want to dispatch order </h2>
                        <div className={styles.packingModal_btn}>
                            <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => setShowDeliveryModal(false)} />
                            <GreenButton btnType="button" title="YES" handleSubmit={() => handleDispatched()} />
                        </div>
                    </div>
                    // <div className={styles.text_center}>
                    //     <form onSubmit={handleSubmit}>
                    //         <div className={styles.modal_div}>
                    //             <select
                    //                 className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    //                 value={delivery.shippingType}
                    //                 onChange={(e) => setDelivery(prevState => ({
                    //                     ...prevState,
                    //                     shippingType: e.target.value
                    //                 }))}
                    //                 required
                    //             >
                    //                 <option value="" hidden>Delivery Type</option>
                    //                 <option>Standard</option>
                    //                 <option>Express</option>
                    //             </select>
                    //             <select
                    //                 className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    //                 value={delivery.courierName}
                    //                 onChange={(e) => setDelivery(prevState => ({
                    //                     ...prevState,
                    //                     courierName: e.target.value
                    //                 }))}
                    //                 required
                    //             >
                    //                 <option value="" hidden>Delivery Method</option>
                    //                 {/* {couriers && couriers.map((obj, index) =>
                    //                     <option key={index} value={obj.courierName}>{obj.courierName}</option>
                    //                 )} */}
                    //             </select>
                    //             <select
                    //                 className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    //                 value={delivery.partnerName}
                    //                 onChange={(e) => {
                    //                     setDelivery(prevState => ({
                    //                         ...prevState,
                    //                         partnerName: e.target.value
                    //                     }));
                    //                     // setPrintLabel(prevState => ({
                    //                     //     ...prevState,
                    //                     //     delPerName: partners && partners.partners.find(x => x.partnerName === e.target.value)?.partnerName,
                    //                     //     delPerMobile: partners && partners.partners.find(x => x.partnerName === e.target.value)?.contactNumber,
                    //                     //     delTrackingNo: partners && partners.partners.find(x => x.partnerName === e.target.value)?.trackingNumber,
                    //                     //     delTrackingUrl: partners && partners.partners.find(x => x.partnerName === e.target.value)?.trackingUrl,
                    //                     // }));
                    //                 }}
                    //                 required
                    //             >
                    //                 <option value="" hidden>Delivery Partner</option>
                    //                 {/* {partners.partners && partners.partners.map((obj, index) =>
                    //                     <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
                    //                 )} */}
                    //             </select>
                    //         </div>
                    //         <div className={styles.modal_div}>
                    //             <input
                    //                 name="name"
                    //                 type="text"
                    //                 placeholder='Name'
                    //                 className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    //                 value={printLabel.delPerName}
                    //                 onChange={(e) => {
                    //                     setPrintLabel(prevState => ({
                    //                         ...prevState,
                    //                         delPerName: e.target.value
                    //                     }))
                    //                 }}
                    //             />
                    //             <input
                    //                 name="mobile"
                    //                 type="number"
                    //                 placeholder='Mobile Number'
                    //                 className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    //                 value={printLabel.delPerMobile}
                    //                 onChange={(e) => {
                    //                     setPrintLabel(prevState => ({
                    //                         ...prevState,
                    //                         delPerMobile: e.target.value
                    //                     }))
                    //                 }}
                    //             />
                    //         </div>
                    //         <div className={styles.modal_div}>
                    //             <input
                    //                 name="name"
                    //                 type="text"
                    //                 placeholder='Tracking Number'
                    //                 className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    //                 value={printLabel.delTrackingNo}
                    //                 onChange={(e) => {
                    //                     setPrintLabel(prevState => ({
                    //                         ...prevState,
                    //                         delTrackingNo: e.target.value
                    //                     }))
                    //                 }}
                    //             />
                    //             <input
                    //                 name="name"
                    //                 type="text"
                    //                 placeholder='Tracking URL'
                    //                 className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    //                 value={printLabel.delTrackingUrl}
                    //                 onChange={(e) => {
                    //                     setPrintLabel(prevState => ({
                    //                         ...prevState,
                    //                         delTrackingUrl: e.target.value
                    //                     }))
                    //                 }}
                    //             />
                    //         </div>

                    //         {/* <div className={styles.dragImage_div}>
                    //             <FileHandler
                    //                 setisImage={setCourierSlip}
                    //                 styles={styles}
                    //             />
                    //         </div> */}

                    //         <div className={styles.uploadImage_div}>

                    //             <div className={styles.demoImage_div}>

                    //                 {(courierSlip || printLabel.courierSlip) ?
                    //                     <img
                    //                         src={courierSlip ? URL.createObjectURL(courierSlip[0]) : process.env.REACT_APP_S3URL + printLabel.courierSlip}
                    //                         className={styles.modalItem_Image}
                    //                         alt="courierSlip"
                    //                     />
                    //                     :
                    //                     <img
                    //                         src='/assets/packingImage.png'
                    //                         className={styles.modalItem_Image}
                    //                         alt="courier"
                    //                     />
                    //                 }


                    //             </div>
                    //             <label className={styles.uploadImage_btn}>View</label>
                    //             <div className={styles.delete_div} onClick={() => { setCourierSlip(); setPrintLabel(prevState => ({ ...prevState, courierSlip: '' })) }}>
                    //                 <span><DeleteIcon /></span>
                    //                 <span>Delete</span>
                    //             </div>
                    //         </div>

                    //         <div className={styles.modalBtn_div}>
                    //             <RedOutButton btnType="button" title={"CANCEL"} handleSubmit={() => { setShowDeliveryModal(false) }} />
                    //             <GreenOutButton title={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DISPATCHED" : "DISPATCHED"} />
                    //         </div>
                    //     </form>
                    // </div>
                }
            />

            {/* ----------------------------------Packing Modal-------------------------- */}
            <Modal
                show={showPackedModal}
                close={setShowPackedModal}
                closeBtn={true}
                heading="PACKING STATUS"
                content={
                    <div>
                        <h2 className='my-5'> Are you sure want to packed order </h2>
                        <div className={styles.packingModal_btn}>
                            <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => setShowPackedModal(false)} />
                            <GreenButton btnType="button" title="YES" handleSubmit={() => handlePacked()} />
                        </div>
                    </div>
                    // <form onSubmit={handlePacked} className={styles.text_center}>
                    //     <div className={styles.main_div}>
                    //         <div>
                    //             <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                    //             <input type="number"
                    //                 name="packs"
                    //                 value={packs}
                    //                 // setPacks
                    //                 onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                    //                 onChange={(e) => {
                    //                     setPacks(e.target.value)

                    //                     if (check && e.target.value && (images.length > parseInt(e.target.value))) {
                    //                         const values = [...images];
                    //                         values.splice(point, (images.length - (parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value))));
                    //                         setImages(values);
                    //                     }

                    //                 }}
                    //                 className={styles.enterPackets}
                    //                 required
                    //                 autoFocus={true}
                    //             />
                    //         </div>

                    //         <div className={styles.checkBox_addImage}>
                    //             <input
                    //                 type="checkbox"
                    //                 name="vehicle1"
                    //                 value="check"
                    //                 className={styles.checkbox}
                    //                 checked={check ? true : false}
                    //                 onChange={(e) => {
                    //                     if (e.target.checked && packs) {
                    //                         addFormFields()
                    //                         setCheck(true)
                    //                     } else {
                    //                         const values = [...images];
                    //                         values.splice(point, point.length);
                    //                         setImages(values);
                    //                         setCheck(false)
                    //                     }
                    //                 }}
                    //             />
                    //             <label htmlFor="vehicle1">&nbsp;&nbsp;Add image of every pack</label>
                    //         </div>

                    //         <div className={styles.dragImage_div}>
                    //             <FileHandler
                    //                 setisImage={setisImage}
                    //                 styles={styles}
                    //             />
                    //         </div>

                    //         {/* <div className={styles.dragImage_div}>
                    //             <span> <img src='/assets/packing_image.png' alt="item_image" className={styles.packing_image} /></span>
                    //             <p className={styles.drag_txt}>Drag photos and documents</p>
                    //             <hr className={styles.border_OR_div} />
                    //             <span className={styles.OR_css}>OR</span>
                    //             <button type='button' className={styles.browseFiles_btn}>Browse Photos</button>
                    //         </div> */}

                    //         <div>
                    //             {images.map((item, index) =>
                    //                 <div key={index} className={styles.flexdiv}>
                    //                     <span>Pack {index + 1}</span>
                    //                     {/* <label className={styles.label}>
                    //                         <input
                    //                             name=""
                    //                             type="file"
                    //                             hidden
                    //                             onChange={(e) => {
                    //                                 let data = [...images];
                    //                                 data[index] = e.target.files[0];
                    //                                 setImages(data);
                    //                             }}
                    //                         />
                    //                         <UpLoadFile />&nbsp;Upload Your Photos Here
                    //                     </label> */}

                    //                     {item.name ? <img src={URL.createObjectURL(item)} alt="" className={styles.fileimg}

                    //                         onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")}
                    //                     /> :
                    //                         <img
                    //                             src='/assets/packingImage.png'
                    //                             alt='packing_order_image'
                    //                             className={styles.packingImage}
                    //                         />
                    //                     }

                    //                     <div className={styles.view_packingImage} onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")} >View</div>

                    //                     <div className={styles.deletediv}
                    //                         onClick={e => {
                    //                             let data = [...images];
                    //                             data[index] = {};
                    //                             setImages(data);
                    //                         }}
                    //                     >
                    //                         <DeleteFile color={"red"} />
                    //                         <p className={styles.deleteicon}>Delete</p>
                    //                     </div>
                    //                     {images.length !== 1 &&
                    //                         <div className={styles.deletediv}
                    //                             onClick={e => {
                    //                                 setPacks(parseInt(packs) - 1)
                    //                                 const list = [...images];
                    //                                 list.splice(index, 1);
                    //                                 setImages(list);
                    //                             }}
                    //                         >
                    //                             <span className={styles.crossIcon}> &#10006;</span>
                    //                         </div>
                    //                     }
                    //                 </div>
                    //             )}
                    //         </div>
                    //     </div>
                    //     <div className={styles.packingModal_btn}>
                    //         <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => setShowPackedModal(false)} />
                    //         <GreenButton btnType="submit" title="OK" />
                    //     </div>
                    // </form>
                }
            />

            {loading ? "Loading" :
                <form className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.ff}`}>
                    <div className={`${switchDisplay ? styles.voucher_maindiv : styles.voucher_maindiv}`}>

                        <div className={styles.packingVoucherDiv}>
                            <div className={styles.packingHeading}>
                                <div className={styles.backArrow} onClick={() => navigate(-1)}>&lt;&nbsp;Go Back</div>
                                <h2>Packing Voucher</h2>
                                <div className={`${(data.status === "New Order") ? styles.status_blue :
                                    (data.status === "Packed" || data.status === "Dispatched") ? styles.statuscolor3 :
                                        (data.status === "Not Packed") ? styles.statuscolor1 :
                                            (data.status === 'In Progress' || data.status === 'Pending') ?
                                                styles.status : ''
                                    }`}>
                                    &#9679; {data.status}
                                </div>
                            </div>

                            <div className={styles.voucher_flex}>
                                <div className={styles.voucherFlexDirection}>
                                    <h2 className={styles.slipheading_div}>{data.name}</h2>
                                    <span className={styles.sliptext}>Ship To:&nbsp;<b>{data.name}</b></span>
                                    <span className={styles.sliptext}>Address:&nbsp;<b>{
                                        (data.street + ", " + data.district + ", " +
                                            data.state + ", " + data.pincode
                                        )}</b></span>
                                    {/* <span className={styles.sliptext}>State:&nbsp;{data.order && data.order.state}</span> */}
                                    <span className={styles.sliptext}>Contact:&nbsp;{data.mobile}</span>
                                </div>
                                <div className={styles.packingVoucher_left}>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>OrderId</span>
                                        <span className={styles.slipDetailsData}><b>{data.orderId}</b></span>
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Order Date</span>
                                        <span className={styles.slipDetailsData}><b>{data.orderDate}</b></span>
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Delivery Type</span>
                                        <span className={styles.slipDetailsData}><b>{data?.shippingType}</b></span>
                                    </div>
                                    <div className={styles.voucher_flex}>
                                        <span className={styles.slipDetailsTxt}>Delivery Method</span>
                                        <span className={styles.slipDetailsData}><b>{data?.shippingType}</b></span>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.fixTableHead_packing}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Item Description</th>
                                            <th>Qty</th>
                                            <th>Size</th>
                                        </tr>
                                    </thead>
                                    {data?.itemsdata?.map((val, key) => {
                                        return (
                                            <tbody key={key}>
                                                <tr className={styles.h10}>
                                                    <td>{key + 1}</td>
                                                    <td>
                                                        <div className={styles.itemDescription_div}>
                                                            <img src={process.env.REACT_APP_S3URL + val.masterimg} alt={val.itemNo} height={50} />
                                                            <span>{val.itemNo}</span>
                                                            <span>{val.product_name}</span>
                                                        </div>
                                                    </td>
                                                    <td>{val.qty}</td>
                                                    <td>{val.size}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            </div>
                            {/* <div className={styles.packingHeading}>
                                <div>
                                    <div className={styles.selectheading}>Select Packer</div>

                                    <select
                                        className={`${switchDisplay ? styles.bg_white :
                                            styles.bg_dark} ${styles.select}`}
                                        onChange={(e) => setPacker(e.target.value)}
                                        value={packer}
                                        required
                                    >
                                        <option value={managerId} >Self</option>
                                        {data.pakingManagers && data.pakingManagers.map((item, index) =>
                                            <option
                                                key={index}
                                                className={styles.selecthover}
                                                value={item.managerId}
                                            >
                                                {item.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                                <div>Total Items: {data.order.items.length}</div>
                            </div> */}

                            <div className={styles.btn_div}>
                                <RedOutButton btnType="button" title={"CANCEL"} handleSubmit={() => { window.history.back() }} />
                                {
                                    data?.status !== "Packed" && data?.status !== "Dispatched" ?
                                        <><ReactToPrint
                                            trigger={() => <div>
                                                <GreenOutButton
                                                    btnType="button"
                                                    title={<div className={styles.btn}><Printbtn />&nbsp;&nbsp;PACKING SLIP</div>} />
                                            </div>}
                                            content={() => componentRef}
                                        />
                                            <div style={{ display: "none" }}>
                                                <PackingSlip
                                                    orderId={orderId}
                                                    managerId={managerId}
                                                    // delivery={delivery}
                                                    ref={(el) => (componentRef = el)}
                                                />
                                            </div>
                                            <GreenButton
                                                btnType="button"
                                                title={"PACKED"}
                                                handleSubmit={() => { setShowPackedModal(true) }}
                                            />
                                        </>
                                        :
                                        <>
                                            {data?.status === "Dispatched" ?
                                                // <Link to={'/viewdispatchdetails/' + orderId} className={styles.viewDetail_btn}>View Dispatch Details</Link>
                                                <div>
                                                    <GreenOutButton btnType="button" title="PRINT LABEL" handleSubmit={() =>handlePrint()}/> 
                                                    <div style={{ display: "none" }}>
                                                        <PrintLabel
                                                            data={data}
                                                            qtyPacks={1}
                                                            ref={labelRef}
                                                            handlePrint={handlePrint}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <GreenOutButton btnType="button" title="DELIVERY DETAILS"
                                                    disabled={data.status === "Cancelled" ? true : false}
                                                    handleSubmit={() => {
                                                        // setPrintLabel(prevState => ({
                                                        //     ...prevState,
                                                        //     delPerName: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.partnerName,
                                                        //     delPerMobile: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.contactNumber,
                                                        //     delTrackingNo: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.trackingNumber,
                                                        //     delTrackingUrl: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.trackingUrl,
                                                        // }));
                                                        setShowDeliveryModal(true)
                                                    }}
                                                />
                                            }
                                            {/* <GreenOutButton
                                                disabled={((data.order.dispatchStatus === "Cancelled") || lableBtnLoading) ? true : false}
                                                btnType="button"
                                                title={<>{lableBtnLoading ? <Spinner size="15" /> : <div className={styles.btn}><Printbtn />&nbsp;&nbsp;LABEL</div>}</>}
                                                handleSubmit={() => { setShowLabelModal(true) }}
                                            /> */}
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            }
            {/* <PackingSlip
                orderId={orderId}
                managerId={managerId}
                delivery={delivery}
                ref={(el) => (componentRef = el)}
            /> */}

        </React.Fragment>
    )
}
export default PackingVoucher;



// export const PackingImage = ({ show, close, content, heading, closeBtn }) => {
//     useEffect(() => {
//         if (show) {
//             document.body.style.overflow = 'hidden'
//         } else {
//             document.body.style.overflow = 'auto'
//         }
//     }, [show,])
//     return show && (
//         <div className={modalcss.modal_backdrop} onClick={() => { close(); }}>
//             <div className={modalcss.packingImage_content} onClick={e => { e.stopPropagation(); }}  >
//                 {content}
//             </div>
//         </div >
//     )
// }