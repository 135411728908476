import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth';
import styles from '../dispatchmanager/DishpatchManager.module.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '../../components/Paginantion';
import OrdersTab from '../../components/OrdersTab';

const EstimateOrders = () => {
    const navigate = useNavigate();
    const [searchparams] = useSearchParams();
    const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;
    const { switchDisplay, managerId } = useAuth();
    // const [msgShow, setMsgShow] = useState({
    //     status: "",
    //     msg: "",
    // });
    const [orders, setOrders] = useState("")
    const [allData, setAllData] = useState([])
    // const [showItemModal, setShowItemModal] = useState(false);
    // const [showAcceptModal, setShowAcceptModal] = useState(false);
    // const [orderData, setOrderData] = useState({})
    const [pages, setPages] = useState(1)
    const [loading, setLoading] = useState(false)
    const [sortKey, setSortKey] = useState('')
    const [orderStatus, setOrderStatus] = useState('')
    const [orderType, setOrderType] = useState('')
    const [keyword, setKeyword] = useState('')  
    const [warehouses, setWarehouses] = useState([])
    const [warehouseName, setWarehouseName] = useState('')
    const [managers, setManagers] = useState([])
    const [managerName, setManagerName] = useState('')
    const [daysTab, setDaysTab] = useState('today')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')


    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": parseInt(managerId),
                "keyword": keyword,
                "sortKey": sortKey,
                "orderType": orderType,
                "orderStatus": orderStatus,
                "cartType": "",
                "warehousename": warehouseName,
                "ordermanagerId": managerName,
                "day": daysTab,
                "startDate": startDate,
                "endDate": endDate,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            // fetch('http://192.168.0.117:5055/order/estimates?page=' + page + '&limit=8', requestOptions)
            fetch(process.env.REACT_APP_URL + 'order/estimates?page=' + page + '&limit=8', requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setPages(result.pages)
                        setAllData(result.data)
                        setOrders(result)
                        if (result.warehouses) {
                            setWarehouses(result.warehouses)
                        }
                        if (result.managers) {
                            setManagers(result.managers)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, page, sortKey, orderType, keyword, warehouseName, managerName, daysTab, endDate, startDate, orderStatus])



return (
    <React.Fragment>
        <div className={`${!switchDisplay ? styles.bg_dark : styles.bg_white} ${styles.ff}`}>

            <OrdersTab
                styles={styles}
                orders={orders}
                sortKey={sortKey}
                setSortKey={setSortKey}
                loading={loading}
                orderType={orderType}
                setOrderType={setOrderType}
                keyword={keyword}
                setKeyword={setKeyword}
                warehouses={warehouses}
                warehouseName={warehouseName}
                setWarehouseName={setWarehouseName}
                managers={managers}
                setManagers={setManagers}
                managerName={managerName}
                setManagerName={setManagerName}
                daysTab={daysTab}
                setDaysTab={setDaysTab}
                endDate={endDate}
                setEndDate={setEndDate}
                startDate={startDate}
                setStartDate={setStartDate}
                setOrderStatus={setOrderStatus}
                orderStatus={orderStatus}
            />
            {loading ? <div className={'order_loadingMain'}>
                <img src='/wallicon.gif' alt='walliconGIF' />
            </div> :
                <>
                    <div className={styles.order_heightFix_div}>
                        <table className={styles.itemTable}>
                            <thead>
                                <tr className={styles.item}>
                                    <th>Name & City</th>
                                    <th>Cart Type</th>
                                    <th>Date</th>
                                    <th>Estimate Id</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Last Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allData && allData.map((val, index) =>
                                    <tr key={index}
                                        className={styles.item}
                                        onClick={() => {
                                            navigate('/orders/estimate?orderId=' + val.estimateId);
                                        }}
                                    >
                                        <td>{`${val.storeName}, ${val.city}`}</td>
                                        <td>{val.cartType}</td>
                                        <td>{val.orderDate}</td>
                                        <td>{val.estimateId}</td>
                                        <td>{val.totalAmount}</td>
                                        <td className={`${val.status === 'Dispatched' ?
                                                    styles.table_txt_green : (val.status === 'Payment Pending') ?
                                                        styles.table_txt_red : (val.status === 'Sale Done') ?
                                                            styles.table_txt_yellow : val.status === 'New Order' ?
                                                                styles.table_txt_blue : ''}`}>{val.status} </td>
                                        <td>{val.lastAction}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        total={pages}
                        current={page}
                    />
                </>
            }
        </div>
    </React.Fragment>
)
}
export default EstimateOrders;