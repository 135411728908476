import React, { useEffect, useRef, useState } from 'react'
import styles from './SaleOrderMob.module.css'
import ModalMob from '../components/ModalMob'
import { useLocation, useNavigate } from 'react-router-dom';
import { CircleAddIcon, Close, Spinner } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
import { useSearchParams } from 'react-router-dom';
import LiveSearchMob from '../components/LiveSearchMob';
import PaymentModalMob from '../components/PaymentModalMob';
import AddDealerModalMob from '../components/AddDealerModalMob';
import Modal from '../../components/Modal';
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
import AddItemModalMob from '../components/AddItemModalMob';

const MobEstimate = () => {

  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  // const { managerId, designation } = useAuth()
  // const [searchParams] = useSearchParams()
  // const orderId = searchParams.get('orderId') ? searchParams.get('orderId') : ""
  // const [showTab, setShowTab] = useState("sale")
  // const [showAcceptModal, setShowAcceptModal] = useState(false)
  // const [step, setStep] = useState(0)
  // const [searchDealer, setSearchDealer] = useState("")
  // const [dealers, setDealers] = useState([])
  // const [generalInfo, setGeneralInfo] = useState("")
  // const [shippingInfo, setShippingInfo] = useState("")
  // const [warehouses, setWarehouses] = useState([])
  // // const [dispatchManagers, setDispatchManagers] = useState([])
  // const [couriers, setCouriers] = useState([])
  // const [partners, setPartners] = useState("")
  // const [results, setResults] = useState()
  // const [searchItem, setSearchItem] = useState("")
  // const [wareHouseWise, setWareHouseWise] = useState("")
  // const [showAddItem, setShowAddItem] = useState(false)
  // const [loading, setLoading] = useState(false)
  // // const [reciptsInfo, setReciptsInfo] = useState([])
  // const [reload, setReload] = useState(false)
  // const [showPaymentModal, setShowPaymentModal] = useState(false)
  // const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
  // const [loadingReceipt, setLoadingReceipt] = useState(false)
  // const [accounts, setAccounts] = useState([])
  // const [addDealer, setAddDealer] = useState(false)
  // const [loadingMoveToSale, setLoadingMoveToSale] = useState(false)
  // const [moveToSaleModal, setMoveToSaleModal] = useState(false)

  // const [reciptData, setReciptData] = useState({
  //   paymentMethod: '',
  //   paymentType: '',
  //   paymentAmount: '',
  //   transactionId: '',
  //   accountNumber: '',
  // })

  const { managerId, designation } = useAuth()
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const [step, setStep] = useState(0)
  const orderId = searchParams.get('orderId') ? searchParams.get('orderId') : ""

  // const [activeTab, setActiveTab] = useState("sale")
  const [wareHouseWise, setWareHouseWise] = useState("")
  const [results, setResults] = useState()
  const [searchItem, setSearchItem] = useState("")
  const [addDealer, setAddDealer] = useState(false)
  const [searchDealer, setSearchDealer] = useState("")
  // const [showCODModal, setShowCODModal] = useState(false)
  const [shippingInfo, setShippingInfo] = useState("")
  const [generalInfo, setGeneralInfo] = useState("")
  const [showAddItem, setShowAddItem] = useState(false)
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMoveToSale, setLoadingMoveToSale] = useState(false)
  const [moveToSaleModal, setMoveToSaleModal] = useState(false)
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  // const [reciptsInfo, setReciptsInfo] = useState([])
  const [dealers, setDealers] = useState([])
  const [reload, setReload] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [loadingReceipt, setLoadingReceipt] = useState(false)
  const [reciptData, setReciptData] = useState({
    paymentMethod: '',
    paymentType: '',
    paymentAmount: '',
    transactionId: '',
    accountNumber: '',
  })

  useEffect(() => {
    if (searchDealer) {
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": 1,
        "keyword": searchDealer
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/finddealer", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            if (result.data.length > 0) {
              setDealers(result.data)
              setShowAcceptModal(true)
            } else {
              setShowAcceptModal(false)
              setDealers([])
            }
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [searchDealer])


  const [isImage, setisImage] = useState();
  // const [msgShow, setMsgShow] = useState("");

  const qtyFocus = useRef()

  const [itemsdetails, setItemsdetails] = useState([
    {
      accessories: [],
      itemNo: "",
      product_name: "",
      collection_name: "",
      warehouse: "",
      cartType: "",
      qty: "",
      dp: "",
      unit: "",
      amount: "",
      totalQty: "",
      desc: "",
      descKey: ""
    }
  ]);

  const [error, setErrors] = useState({
    dp: "",
    index: ""
  })


  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...itemsdetails];
    list[index][name] = value;
    setItemsdetails(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...itemsdetails];
    list.splice(index, 1);
    setItemsdetails(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    if (error.dp) {
      alert(error.dp)
    } else {
      setSearchItem("");
      setItemsdetails([...itemsdetails, { itemNo: "", product_name: "", collection_name: "", qty: "", price: "", unit: "", amount: "" }]);
    }
  };

  useEffect(() => {
    if (!searchItem) {
      setResults([])
    }

    if (searchItem) {
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
        "keyword": searchItem,
        // "cartType": generalInfo.cartType,
        "warehousename": wareHouseWise,
        "orderType": generalInfo.orderType,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/searchitems?page=", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setResults(result.data)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [wareHouseWise, searchItem, managerId, generalInfo.orderType])

  function enforce_maxlength(e) {
    var t = e.target;
    if (t.hasAttribute('maxlength')) {
      t.value = t.value.slice(0, t.getAttribute('maxlength'));
    }
  }

  const unitRef = useRef()
  const priceRef = useRef()
  const descRef = useRef()

  // const searchItems = results && results.filter(item => !itemsdetails.some(obj => item.itemNo === obj.itemNo && item.warehouse === obj.warehouse))

  // const billingAddress = generalInfo.street && generalInfo.street + " " + generalInfo.city && generalInfo.street + " " + generalInfo.city + " " +
  //     generalInfo.state && generalInfo.street + " " + generalInfo.city + " " + generalInfo.state + " " + generalInfo.pincode &&
  //     generalInfo.street + " " + generalInfo.city + " " + generalInfo.state + " " + generalInfo.pincode

  const [warehouses, setWarehouses] = useState([])
  // const [dispatchManagers, setDispatchManagers] = useState([])
  useEffect(() => {
    if (!orderId) {
      setGeneralInfo("")
    }
  }, [orderId])

  useEffect(() => {
    if (managerId) {
      const fetchOrder = async () => {
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "managerId": managerId,
          "estimateId": orderId,
          // "warehousename": ""
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        await fetch(process.env.REACT_APP_URL + "order/estimate", requestOptions)
          // fetch("http://192.168.0.117:5055/order/estimate", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.status === "success") {
              if (result.data.order) {
                setGeneralInfo(prevState => ({
                  ...prevState,
                  dealerId: result.data.order.dealerId,
                  storeName: result.data.order.storeName,
                  dealerName: result.data.order.name,
                  billingAddress: result.data.order.billingAddress,

                  shippingType: result.data.order.shippingInfo.shippingType,
                  shippingCharge: result.data.order.shippingInfo.shippingCharge,
                  courierName: result.data.order.shippingInfo.courierName,
                  partnerName: result.data.order.shippingInfo.partnerName,

                  warehousename: result.data.order.warehousename ? result.data.order.warehousename : "",
                  dmId: result.data.order.dmId,
                  cartType: result.data.order.cartType === "Cart" ? 'ocart' : 'pcart',
                  orderDate: result.data.order.orderDate,
                  estimateId: result.data.order.estimateId,

                  transactionScreenshot: result.data.order.transactionScreenshot,
                  paymentMethod: result.data.order.paymentMethod,
                  paymentType: result.data.order.paymentType,
                  paymentStatus: result.data.order.paymentStatus,
                  paymentAmount: result.data.order.paymentAmount,
                  transferTransId: result.data.order.transferTransId,

                  status: result.data.order.status,
                  manageName: result.data.order.manageName,
                  manageValue: result.data.order.manageValue,
                  orderType: result.data.order.orderType,
                }))
                setShippingInfo(result.data.order.shippingInfo)
                setItemsdetails(result.data.order.items)
                // setReciptsInfo(result.data.receipts)
              }
            }
          })
          .catch(error => console.log('error', error));
      }
      fetchOrder()
    }

  }, [orderId, managerId, reload])

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
        "estimateId": orderId,
        // "warehousename": generalInfo.warehousename
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/estimate", requestOptions)
        // fetch("http://192.168.0.117:5055/order/estimate", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            if (result.data.warehouses) {
              setWarehouses(result.data.warehouses)
            }
            if (result.data.dispatchmanagers && result.data.dispatchmanagers.length > 0) {
              // setDispatchManagers(result.data.dispatchmanagers)
              setAccounts(result.data.accounts)
              setGeneralInfo(prevState => ({
                ...prevState,
                dmId: result.data.dispatchmanagers[0].managerId
              }))
            }
          }
        })
        .catch(error => console.log('error', error));
    }

  }, [orderId, managerId, generalInfo.warehousename,])



  useEffect(() => {
    if (shippingInfo.pincode && shippingInfo.pincode.length === 6) {
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "keyword": shippingInfo.pincode,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setShippingInfo(prevState => ({
              ...prevState,
              city: result.districts[0] ? result.districts[0] : "",
              state: result.states[0] ? result.states[0] : "",
            }))
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [shippingInfo.pincode])

  const [couriers, setCouriers] = useState([])
  const [partners, setPartners] = useState("")

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "courierName": generalInfo.courierName
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            if (!generalInfo.courierName) {
              setCouriers(result.data)
              // if( result.data.courierName)
              setGeneralInfo(prevState => ({
                ...prevState,
                shippingType: "Standard",
                courierName: result.data[0].courierName
              }))
            } else {
              setPartners(result.data)
              setGeneralInfo(prevState => ({
                ...prevState,
                partnerName: result.data.partners[0].partnerName
              }))
            }
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [managerId, generalInfo.courierName])

  const cartTypeRef = useRef(null)
  const itemseacrhRef = useRef(null)
  const paymentRef = useRef(null)

  const handleSubmitStep0 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setStep(1)
    }
  }

  const handleSubmitStep1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setStep(2)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleSaleOrder("success")
    }
  }

  // const qty = itemsdetails.map(obj => parseFloat(obj.qty)).reduce((a, b) => a + b)
  // const totalItems = itemsdetails.length

  let cartPriceSum = itemsdetails && itemsdetails.reduce((sum, currentItem) => {
    return sum + (parseFloat(currentItem.price ? currentItem.price : 0) * parseFloat(currentItem.qty ? currentItem.qty : 0));
  }, 0)

  var cartTotal = itemsdetails && itemsdetails.map(x => !x.accessories ? 0 : x.accessories.map(y => (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)
  let subtotal = (cartPriceSum ? cartPriceSum : 0) + (cartTotal ? cartTotal : 0)
  let gettotalAmount = subtotal + parseFloat(generalInfo.shippingCharge ? generalInfo.shippingCharge : 0) + parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0)

  let totalAmount = Math.round(gettotalAmount)
  let roundedAmt = totalAmount ? (totalAmount - gettotalAmount).toFixed(2) : 0

  const handleSaleOrder = (status) => {
    if (error.dp) {
      alert(error.dp)
    }
    // else if (status === "success" && (parseFloat(totalAmount).toFixed(2) !== parseFloat(generalInfo.paymentAmount).toFixed(2))) {
    //     alert("Payment amount is not matching with total amount")
    // } 
    else {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
        "estimateId": orderId,//estimateId
        "warehousename": generalInfo.warehousename,
        // "dmId": generalInfo.dmId,
        "cartType": generalInfo.cartType,
        "dealerId": generalInfo.dealerId,
        "name": shippingInfo.name,
        "street": shippingInfo.street,
        "mobile": shippingInfo.mobile,
        "pincode": shippingInfo.pincode,
        "city": shippingInfo.city,
        "state": shippingInfo.state,
        "shippingType": generalInfo.shippingType,
        "shippingCharge": generalInfo.shippingCharge,
        "courierName": generalInfo.courierName,
        "partnerName": generalInfo.partnerName,
        "manageName": generalInfo.manageName,
        "manageValue": generalInfo.manageValue,
        "estimateItems": itemsdetails,//estimateItems
        "status": status,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/addupdateEstimate", requestOptions)
        // fetch("http://192.168.0.117:5055/order/estimateOrder", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            alert(result.msg)
            if (status === "success") {
              navigate("/orders/estimateinvoice?orderId=" + result.data.estimateId)
              setShowPaymentModal(true)
              // handleOfllinePayment({ orderId: result.data.orderId, status })
            }
            if (status === "draft") {
              navigate("/orders")
            }
          } else {
            alert(result.msg)
          }
        })
        .catch(error => console.log('error', error))
        .finally(() => setLoading(false))
    }
  }

  // const handleOfllinePayment = ({ orderId, status }) => {
  //     if (parseFloat(totalAmount).toFixed(2) !== parseFloat(generalInfo.paymentAmount).toFixed(2)) {
  //         alert("Payment amount is not matching with total amount")
  //     } else {
  //         var myHeaders = new Headers();
  //         myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);

  //         var formdata = new FormData();
  //         formdata.append("transactionScreenshot", generalInfo.transactionScreenshot);
  //         formdata.append("paymentMethod", generalInfo.paymentMethod);
  //         formdata.append("paymentType", generalInfo.paymentType);
  //         formdata.append("paymentStatus", generalInfo.paymentStatus);
  //         formdata.append("paymentAmount", generalInfo.paymentAmount);
  //         formdata.append("transferTransId", generalInfo.transferTransId);

  //         var requestOptions = {
  //             method: 'POST',
  //             headers: myHeaders,
  //             body: formdata,
  //             redirect: 'follow'
  //         };

  //         fetch(process.env.REACT_APP_URL + "order/offlinePayment/" + orderId, requestOptions)
  //             .then(response => response.json())
  //             .then(result => {
  //                 if (result.status === "success") {
  //                     if (status === "success") {
  //                         navigate("/orders/saleinvoice?orderId=" + orderId)
  //                     } else {
  //                         navigate("/orders")
  //                     }
  //                 }
  //             })
  //             .catch(error => console.log('error', error));
  //     }
  // }
  let typeStatus = !generalInfo.status ? true : generalInfo.status === "In Progress" ? true : generalInfo.status === "Draft" ? true : false

  const resultArray = [];

  itemsdetails.length > 0 && itemsdetails.forEach(item => {
    //for each item in arrayOfObjects check if the object exists in the resulting array
    if (resultArray.find(object => {
      if (object.warehouse === item.warehouse) {
        //if the object exists iterate times
        object.times++;
        return true;
        //if it does not return false
      } else {
        return false;
      }
    })) {
    } else {
      //if the object does not exists push it to the resulting array and set the times count to 1
      item.times = 1;
      resultArray.push(item);
    }
  })

  // useEffect(()=>{
  //     if(resultArray.length > 0){
  //         resultArray.forEach(obj=>{
  //             if(obj.times > obj.times){
  //                 console.log(obj.times)
  //             }
  //         })
  //     }
  // }, [resultArray])
  const closest = resultArray.length > 0 ? resultArray.reduce(
    (acc, loc) =>
      acc.times > loc.times
        ? acc
        : loc
  ) : []

  useEffect(() => {
    setGeneralInfo(prevState => ({
      ...prevState,
      warehousename: closest.warehouse
    }))
  }, [closest.warehouse])

  // console.log(closest);

  const handleCancelOrder = () => {
    // setMsgShow("")
    var myHeaders = new Headers();
    myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "managerId": managerId,
      "orderId": orderId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "order/cancelOrder", requestOptions)
      .then(response => response.json())
      .then(result => {
        alert(result.msg)
        if (result.status === 'success') {
          setShowCancelOrderModal(false)
          navigate("/orders")
        }
      })
      .catch(error => console.log('error', error));
  }

  const MoveToSale = () => {
    setLoadingMoveToSale(true)
    var myHeaders = new Headers();
    myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "managerId": managerId,
      "estimateId": orderId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "order/moveToSaleEstimate", requestOptions)
      // fetch("http://192.168.0.117:5055/order/moveToSaleEstimate", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          setMoveToSaleModal(false)
          navigate('/estimateorders')
        } else {
          alert(result.msg)
        }
      })
      .catch(error => console.log('error', error))
      .finally(() => setLoadingMoveToSale(false))
  }

  const handleRecipt = () => {
    setLoadingReceipt(true)
    setReload(false)
    var myHeaders = new Headers();
    myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("transactionScreenshot", isImage ? [...isImage][0] : '');
    formdata.append("paymentMethod", reciptData.paymentMethod ? reciptData.paymentMethod : '');
    formdata.append("paymentType", reciptData.paymentType ? reciptData.paymentType : '');
    formdata.append("paymentAmount", reciptData.paymentAmount ? reciptData.paymentAmount : '');
    formdata.append("transactionId", reciptData.transactionId ? reciptData.transactionId : '');
    formdata.append("accountNumber", reciptData.accountNumber ? reciptData.accountNumber : '');
    formdata.append("totalAmount", totalAmount ? totalAmount : '');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "receipt/offlinePay/" + managerId + "/" + orderId + "?receiptId=", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          setShowPaymentModal(false)
          setReload(true)
        }
        alert(result.msg)
      })
      .catch(error => console.log('error', error))
      .finally(() => setLoadingReceipt(false))
  }

  // let payStatus = !generalInfo.status ? false : generalInfo.status === "In Progress" ? false : generalInfo.status === "Cancelled" ? false : true

  // console.log(resultArray.map(obj=> obj.warehouse))



  return (
    <div>
      {addDealer && <AddDealerModalMob setGeneralInfo={setGeneralInfo} close={setAddDealer} show={addDealer} />}
      <AddItemModalMob
        showAddItem={showAddItem}
        setShowAddItem={setShowAddItem}
        setItemsdetails={setItemsdetails}
        index={itemsdetails.length - 1}
      />
      <div className={styles.dashboard_select_overall}>
        {(designation === 'ordermanager' || designation === 'superadmin' || designation === 'inventorymanager') &&
          <>
            <div
              className={`${styles.dashboard_overall_select} ${(pathname === "/orders/saleorder") && styles.days_active}`}
              onClick={() => { navigate("/orders/saleorder") }}>Sale Order</div>
            <div className={`${styles.dashboard_overall_select} ${(pathname === "/orders/estimate") && styles.days_active}`}
              onClick={() => { navigate("/orders/estimate") }}>Estimate</div>
          </>
        }
        {(designation === 'inventorymanager' || designation === 'superadmin') &&
          <>
            <div
              className={`${styles.dashboard_overall_select} ${pathname === "/orders/purchase" && styles.days_active}`}
              onClick={() => { navigate("/orders/purchase") }}>Purchase
            </div>
            <div
              className={`${styles.dashboard_overall_select} ${pathname === "Adjustment" && styles.days_active}`}
            >Adjustment
            </div>
            <div
              className={`${styles.dashboard_overall_select} ${pathname === "transfer" && styles.days_active}`}
            >Transfer
            </div>
          </>
        }
      </div>

      {/* <form onSubmit={handleSubmit}> */}
      <div>
        {step === 0 ?
          <form onSubmit={handleSubmitStep0} className={designation === 'superadmin' ? styles.order_container : styles.order_margin}>
            <h1 className={styles.order_heading}>Estimate</h1>
            <label className='pb-1'>Company/Dealer Name</label>
            {/* <div type="text" className={styles.order_div} onClick={() => setShowAcceptModal(true)} >
                                {selected}
                            </div> */}
            <input
              type="text"
              className={styles.order_input}
              placeholder='Company/Dealer Name/DealerId...'
              value={generalInfo.storeName && generalInfo.dealerName && generalInfo.storeName + " / " + generalInfo.dealerName}
              onChange={e => setSearchDealer(e.target.value)}
              onClick={() => setShowAcceptModal(true)}
            />
            <textarea
              className={styles.order_input_textarea}
              placeholder='Enter Billing Address'
              value={generalInfo.billingAddress}
              readOnly
            />

            <h4 className='border py-2 text-center'>Delivery Address</h4>

            <input
              type="text"
              className={styles.order_input}
              placeholder='Name'
              value={shippingInfo.name}
              onChange={(e) => setShippingInfo(prevState => ({
                ...prevState,
                name: e.target.value
              }))}
              required={generalInfo.dealerName === "Cash" ? false : generalInfo.dealerId ? false : true}
            />

            <input
              type="number"
              className={styles.order_input}
              placeholder='Enter Mobile'
              value={shippingInfo.mobile}
              onChange={(e) => setShippingInfo(prevState => ({
                ...prevState,
                mobile: e.target.value
              }))}
              onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
              required={generalInfo.dealerName === "Cash" ? false : generalInfo.dealerId ? false : true}
            />

            <input
              type="text"
              className={styles.order_input}
              placeholder='Street'
              value={shippingInfo.street}
              onChange={(e) => setShippingInfo(prevState => ({
                ...prevState,
                street: e.target.value
              }))}
              required={generalInfo.dealerName === "Cash" ? false : generalInfo.dealerId ? false : true}
            />

            <div className='position-relative'>
              {shippingInfo.pincode && <div className={styles.pincode_address}>, {shippingInfo.city}, {shippingInfo.state}</div>}
              <input
                type="text"
                className={styles.order_input}
                placeholder='Pincode'
                value={shippingInfo.pincode}
                onChange={(e) => setShippingInfo(prevState => ({
                  ...prevState,
                  pincode: e.target.value
                }))}
                onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                required={generalInfo.dealerName === "Cash" ? false : generalInfo.dealerId ? false : true}
              />
            </div>

            <div className={styles.saleOrders_btns}>
              <button type='button' className={styles.order_btn}>CANCEL</button>
              <button type='submit' className={styles.order_btn1}
              // onClick={() => setStep(1)}
              >NEXT</button>
            </div>
          </form>
          :
          step === 1 ?
            <form onSubmit={handleSubmitStep1} className={designation === 'superadmin' ? styles.order_container_step_1 : styles.step_1_main}>
              <div className={styles.orders_voucherNo}>
                <div className={styles.voucherNo}>
                  <div>Voucher No.</div>
                  <b className={styles.date_source}>{generalInfo.orderId}</b>
                </div>
                <div className={styles.voucherNo}>
                  <div>Voucher Date</div>&nbsp;
                  <b className={styles.date_source}>{generalInfo.orderDate}</b>
                </div>
                <div className={styles.voucherNo}>
                  <div>Source</div>&nbsp;
                  <b className={styles.date_source}>{generalInfo.orderType}</b>
                </div>
              </div>
              <label className={styles.order_lebel}>Dispached Warehouse</label>
              <select
                className={styles.select_packer}
                value={generalInfo.warehousename}
                onChange={(e) => setGeneralInfo(prevState => ({
                  ...prevState,
                  warehousename: e.target.value
                }))}
                required
              >
                <option value="">All Warehouse</option>
                {warehouses && warehouses.map((item, index) => (
                  <option key={index} value={item.warehousename}>{item.warehousename}</option>
                ))}
              </select>
              {/* <label className={styles.order_lebel}>Dispatch Manager</label>
              <select
                className={styles.select_packer}
                value={generalInfo.dmId}
                onChange={(e) => setGeneralInfo(prevState => ({
                  ...prevState,
                  dmId: e.target.value
                }))}
                required={generalInfo.dealerId ? false : true}
              >
                <option value="" >Select Dispatch Manager</option>
                {dispatchManagers && dispatchManagers.map((item, index) => (
                  <option key={index} value={item.managerId}>{item.name}</option>
                ))}
              </select> */}
              <label className={styles.order_lebel}>Delivery Type</label>
              <select
                className={styles.select_packer}
                value={generalInfo.shippingType}
                onChange={(e) => setGeneralInfo(prevState => ({
                  ...prevState,
                  shippingType: e.target.value
                }))}
                required
              >
                <option value="" hidden>Choose Delivery Type</option>
                <option>Standard</option>
                <option>Express</option>
              </select>
              <label className={styles.order_lebel}>Delivery Method</label>
              <select
                className={styles.select_packer}
                value={generalInfo.courierName}
                onChange={(e) => setGeneralInfo(prevState => ({
                  ...prevState,
                  courierName: e.target.value
                }))}
                required
              >
                <option value="" hidden>Choose Delivery Method</option>
                {couriers && couriers.map((obj, index) =>
                  <option key={index} value={obj.courierName}>{obj.courierName}</option>
                )}
              </select>
              <label className={styles.order_lebel}>Delivery Partners</label>
              <select
                className={styles.select_packer}
                value={generalInfo.partnerName}
                onChange={(e) => setGeneralInfo(prevState => ({
                  ...prevState,
                  partnerName: e.target.value
                }))}
                required
              >
                <option value="" hidden>Choose Delivery Partner</option>
                <option value="Auto">Auto Select</option>
                {partners.partners && partners.partners.map((obj, index) =>
                  <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
                )}
              </select>
              <label className={styles.order_lebel}>Product Type</label>
              <select className={styles.select_packer}
                value={generalInfo.cartType}
                onChange={(e) => {
                  setGeneralInfo(prevState => ({
                    ...prevState,
                    cartType: e.target.value
                  }));
                  // itemseacrhRef.current.focus()
                }}
                required
              // ref={cartTypeRef}
              // disabled={itemsdetails[0].itemNo && itemsdetails.length >= 0 && generalInfo.cartType ? true : false}
              >
                <option value="" hidden>Choose Product Type</option>
                <option value="ocart">Cart</option>
                <option value="pcart">Partner Cart</option>
              </select>
              <div className={styles.saleStep1_btn}>
                <button type='button' className={styles.order_btn} onClick={() => { setStep(0) }}>BACK</button>
                <button type='submit' className={styles.order_btn1}
                //  onClick={() => setStep(2)}
                >NEXT</button>
              </div>
            </form>
            :
            step === 2 &&
            <form onSubmit={handleSubmit} className={designation === 'superadmin' ? styles.order_container_step_2 : styles.step_2_main}>
              <div>
                <div className={styles.item_scroll}>
                  <div>
                    <select
                      className={styles.whereHouse_select_box}
                      value={wareHouseWise}
                      onChange={(e) => setWareHouseWise(e.target.value)}
                    >
                      <option value="">All Warehouse</option>
                      {warehouses && warehouses.map((item, index) => (
                        <option key={index} value={item.warehousename}>{item.warehousename}</option>
                      ))}
                    </select>
                    {itemsdetails && itemsdetails.map((x, index) =>
                      <div key={index}>
                        <div
                          className={styles.item_card_main}
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.keyCode === 88) {
                              handleRemoveClick(index + 1);
                              paymentRef.current.focus()
                            }
                          }}>
                          <div
                            className={styles.card_Close}
                            onClick={() => handleRemoveClick(index)}>
                            <Close />
                          </div>
                          <div className={styles.table_th_Sno}>
                            <span className={styles.item_description}>{index + 1}. Item Description</span>
                          </div>
                          <div className={styles.table_th + " " + styles.w_50}>
                            <div className={styles.live_search_input}>
                              <LiveSearchMob
                                results={results}
                                itemsdetails={itemsdetails}
                                cartType={generalInfo.cartType}
                                value={x.itemNo ? x.itemNo : searchItem}
                                renderItem={item => <>{item.name}</>}
                                onChange={(e) => setSearchItem(e.target.value)}
                                setSearchItem={setSearchItem}
                                itemseacrhRef={itemseacrhRef}
                                cartTypeRef={cartTypeRef}
                                handleRemoveClick={() => handleRemoveClick(index + 1)}
                                removeIndex={index + 1}
                                setShowAddItem={setShowAddItem}
                                onSelect={item => {
                                  if (!generalInfo.cartType) {
                                    setGeneralInfo(prevState => ({
                                      ...prevState,
                                      cartType: item.cartType
                                    }))
                                    setItemsdetails([
                                      ...itemsdetails.slice(0, index),
                                      item,
                                      ...itemsdetails.slice(index + 1)
                                    ])
                                    qtyFocus.current.focus()
                                  }
                                  else {
                                    setItemsdetails([
                                      ...itemsdetails.slice(0, index),
                                      item,
                                      ...itemsdetails.slice(index + 1)
                                    ])
                                    qtyFocus.current.focus()
                                  }
                                }}
                              />
                              {/* <span>{x.product_name}</span>
                                                            <span className={styles.ms_5}>{x.warehouse}</span> */}
                            </div>

                            {x.accessories && x.accessories.length > 0 && x.accessories.map((item, idx) =>
                              <div key={idx} className={styles.L_U_clip}>
                                <div className={styles.L_clip_price}>
                                  <b>{item.accessoriesName}</b>
                                  <span>{item.accessoriesPrice} Rupees</span> / Pcs
                                </div>
                                <input
                                  type="number"
                                  placeholder='Enter Qty'
                                  className={styles.L_clip_qty}
                                  value={item.accessoriesQty}
                                  onChange={e => {
                                    setItemsdetails([...itemsdetails.slice(0, index),
                                    {
                                      ...itemsdetails[index],
                                      accessories: [...itemsdetails[index].accessories.slice(0, idx),
                                      {
                                        ...itemsdetails[index].accessories[idx],
                                        accessoriesQty: e.target.value,
                                      },
                                      ...itemsdetails[index].accessories.slice(idx + 1)]
                                    },
                                    ...itemsdetails.slice(index + 1)]);
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault()
                                      unitRef.current.focus()
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          {((x.accessories && x.accessories.length > 0) || x.price) &&
                            <div className={styles.card_qty_rate_per}>
                              <div className='d-flex flex-column me-1'>
                                <label className={styles.card_lebel}>Qty</label>
                                <input
                                  type="number"
                                  ref={qtyFocus}
                                  placeholder="Qty"
                                  name='qty'
                                  value={x.qty}
                                  onChange={e => {
                                    if (!x.cartType) {
                                      enforce_maxlength(e, index);
                                      handleInputChange(e, index);
                                      const list = [...itemsdetails];
                                      list[index]["amount"] = x.qty * x.price;
                                      setItemsdetails(list);
                                    }
                                    else if (!e.target.value || (parseFloat(e.target.value) <= parseFloat(x.totalQty))) {
                                      enforce_maxlength(e, index);
                                      handleInputChange(e, index);
                                      const list = [...itemsdetails];
                                      list[index]["amount"] = x.qty * x.price;
                                      setItemsdetails(list);
                                    }
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault()
                                      unitRef.current.focus()
                                    }
                                  }}
                                  required
                                  className={styles.card_input_qty}
                                />
                              </div>

                              <div className='d-flex flex-column me-1'>
                                <label className={styles.card_lebel}>Per</label>
                                <select
                                  name='unit'
                                  value={x.unit}
                                  onChange={e => { handleInputChange(e, index); }}
                                  ref={unitRef}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault()
                                      priceRef.current.focus()
                                    }
                                  }}
                                  required
                                  className={styles.card_input_per}
                                >
                                  <option value="" hidden>Select</option>
                                  <option value="Roll">Roll</option>
                                  <option value="Pcs">Pcs</option>
                                  <option value="Box">Box</option>
                                  <option value="Sq Meter">Sq Meter</option>
                                  <option value="Meter">Meter</option>
                                  <option value="Sq Feet">Sq Feet</option>
                                  <option value="Feet">Feet</option>
                                  <option value="Kg">Kg</option>
                                </select>
                              </div>

                              <div className='d-flex flex-column me-1'>
                                <label className={styles.card_lebel}>Rate</label>
                                <input
                                  type="number"
                                  value={x.price}
                                  name="price"
                                  onChange={e => {
                                    if (x.dp > parseFloat(e.target.value ? e.target.value : 0)) {
                                      setErrors(prevState => ({ ...prevState, dp: `Enter  Above` + x.dp, index: index }))
                                    } else {
                                      setErrors(prevState => ({ ...prevState, dp: "", index: "" }))
                                    }

                                    handleInputChange(e, index);
                                  }}
                                  ref={priceRef}
                                  onKeyPress={(e) => {

                                    if (e.key === 'Enter') {
                                      e.preventDefault()
                                      const list = [...itemsdetails];
                                      list[index]["descKey"] = index;
                                      setItemsdetails(list);
                                      descRef.current.focus()
                                    }
                                  }}
                                  className={styles.card_input_Rate}
                                  required
                                />

                              </div>

                              <div className='d-flex flex-column'>
                                <label className={styles.card_lebel}>Amount</label>
                                <input
                                  type="number"
                                  value={(x.qty * x.price).toFixed(2)}
                                  disabled
                                  readOnly
                                  className={styles.card_input}
                                />
                              </div>

                            </div>
                          }
                          {error.index === index && <div className='fs_10 text-danger text-center'>{error.dp}</div>}


                          {(index === x.descKey || x.desc) && <tr>
                            <td colSpan={6} className={styles.table_th_details}>
                              <input
                                type={"text"}
                                className={styles.inputFocus}
                                style={{ height: "30px", textAlign: "left" }}
                                ref={descRef}
                                name="desc"
                                autoFocus
                                value={x.desc}
                                onChange={(e) => handleInputChange(e, index)}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    if (!x.desc) {
                                      const list = [...itemsdetails];
                                      list[index]["descKey"] = "";
                                      setItemsdetails(list);
                                    }
                                    e.preventDefault()
                                    handleAddClick()
                                  }
                                }}
                              />
                            </td>
                          </tr>
                          }

                        </div>
                      </div>
                    )}
                  </div>

                </div>
                <div className={styles.item_btn_main}>
                  <button type='button' className={styles.item_btn} onClick={() => handleAddClick()}>Add More</button>
                </div>
              </div>

              <div className={styles.totalAmount_card}>
                <div className='d-flex justify-content-between'>
                  <span>Sub Total</span>
                  <span className={styles.charges_txt}>{subtotal.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <span className={styles.shipingCharges}>Shipping Charges</span>
                  <div className='d-flex w-50 justify-content-around align-items-center'>

                    <input
                      type="number"
                      className={styles.charges_input}
                      placeholder='0.00'
                      value={generalInfo.shippingCharge}
                      onChange={(e) => setGeneralInfo(prevState => ({
                        ...prevState,
                        shippingCharge: e.target.value
                      }))}
                      maxLength="10"
                      onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                    />

                    <span className='d-flex align-items-center'>
                      <span className={styles.toPay}>To Pay</span>

                      <input
                        type='checkbox'
                        className={styles.toPay}
                        value={generalInfo.toPay}
                        onChange={(e) => setGeneralInfo(prevState => ({
                          ...prevState,
                          toPay: e.target.checked
                        }))}
                        checked={generalInfo.toPay}
                      />

                    </span>


                  </div>
                  <span className={styles.charges_txt}>{generalInfo.shippingCharge ? generalInfo.shippingCharge : 0}</span>
                </div>
                <div className='d-flex justify-content-between align-items-center my-1'>
                  <span className='d-flex align-items-center'>
                    <CircleAddIcon />

                    <input
                      type="text"
                      className={styles.adjustment}
                      value={generalInfo.manageName}
                      placeholder="Enter Manage"
                      onChange={(e) => setGeneralInfo(prevState => ({
                        ...prevState,
                        manageName: e.target.value,
                      }))}
                      required={generalInfo.manageValue ? true : false}
                    />

                  </span>
                  <div className='d-flex w-50 justify-content-around align-items-center'>
                    <input
                      type="number"
                      className={styles.charges_input}
                      value={generalInfo.manageValue}
                      name='manageValue'
                      onChange={(e) => {
                        setGeneralInfo(prevState => ({
                          ...prevState,
                          manageValue: e.target.value
                        }))
                      }}
                      maxLength="10"
                      onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                      required={generalInfo.manageName ? true : false}
                    />

                    <span className={styles.toPay_hide}></span>
                  </div>
                  <span className={styles.charges_txt + ' text-danger'}>{generalInfo.manageValue ? generalInfo.manageValue : 0}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span>Round Off</span>
                  <span className={styles.charges_txt}>{roundedAmt}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span><b>Total (INR)</b></span>
                  <span className={styles.charges_txt}><b>{totalAmount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</b></span>
                </div>
              </div>

              {/* {payStatus && <div className={styles.status_card}>
                <div className='d-flex justify-content-end'>
                  {payStatus &&
                    <button
                      type='button'
                      className={styles.addReceipt_btn}
                      onClick={() => {
                        if (!orderId) {
                          alert("Please Create Voucher Before")
                        } else {
                          setShowPaymentModal(true)
                          setReciptData(prevState => ({
                            ...prevState,
                            paymentMethod: '',
                            paymentType: '',
                            paymentAmount: '',
                            transactionId: '',
                            accountNumber: '',
                            transactionScreenshot: ''
                          }))
                        }
                      }}
                    >
                      Add Receipts
                    </button>
                  }
                </div>
                <div className={styles.receiptList_scroll}>
                  {reciptsInfo && reciptsInfo.map((item, index) =>
                    <div key={index} onClick={() => { setReciptData(item) }} className='d-flex flex-wrap'>
                      <span>Receipt Id : <u><b>{item.receiptId}</b></u></span>
                      <span>Method : {item.paymentMethod}</span>
                      <span>Amount : <b>{item.paymentAmount}</b></span>
                      <span>Status : <u className='text-danger'>
                        <b role='button'
                          className={item.status.includes('Pending') ? styles.recipt_status_pending :
                            item.status === 'Cancelled' ? styles.recipt_status_red :
                              styles.recipt_status_green}
                          onClick={() => { setShowPaymentModal(true) }}>
                          <u>{item.status}</u>
                        </b>
                      </u></span>
                    </div>
                  )}
                </div>
              </div>} */}

              <div className={styles.estimateStep2_btn}>

                <button type='button' className={styles.estimateback_btn} onClick={() => setStep(1)}>BACK</button>
                {!orderId && <button type='button' className={styles.estimateback_btn} onClick={() => navigate(-1)}>CANCEL</button>}

                {orderId && generalInfo.status !== "Cancelled" && generalInfo.status !== 'Sale Done' &&
                  <button
                    type='button'
                    className={styles.estimateback_btn}
                    onClick={() => { setShowCancelOrderModal(true) }}>
                    CANCEL ORDER
                  </button>
                }

                {typeStatus && <>
                  {orderId &&
                    <button
                      type='button'
                      className={styles.saveAsDraft}
                      onClick={() => handleSaleOrder("draft")}
                    >SAVE AS DRAFT
                    </button>
                  }
                </>}

                {generalInfo.status &&
                  <button
                    type='button'
                    className={styles.estimate_save_send}
                    onClick={() => navigate("/orders/estimateinvoice?orderId=" + orderId)}>
                    VIEW INOVICE
                  </button>
                }
                {(!orderId || generalInfo.status === 'Payment Pending') &&
                  <>
                    <button
                      type='submit'
                      className={styles.estimate_save_send}
                      disabled={loading ? true : false}
                    >
                      {loading ? <Spinner size="15" /> : generalInfo.status ? 'UPDATE' : 'SAVE & SEND'}
                    </button>
                    {orderId &&
                      <button
                        type='button'
                        className={styles.estimate_save_send}
                        onClick={() => { setMoveToSaleModal(true) }}
                      >
                        MOVE TO SALE
                      </button>
                    }
                  </>
                }
              </div>
            </form>
        }
      </div>
      {/* </form> */}

      <ModalMob
        show={showAcceptModal}
        close={setShowAcceptModal}
        css={designation === 'superadmin' ? styles.dealerMargin_top : styles.date_margin_top}
        content={
          <div className={styles.dealer_select}>
            <p onClick={() => { setAddDealer(true); setShowAcceptModal(false) }} className={styles.add_dealer_btn}>
              Add Dealer
            </p>
            {dealers?.map((item, index) =>
              <p key={index}
                // setAddDealer={setAddDealer}
                onClick={() => {
                  if (item.name === "Cash") {
                    setGeneralInfo(prevState => ({
                      ...prevState,
                      dealerId: item.dealerId,
                      dealerName: item.name,
                      storeName: item.storeName,
                      shippingType: "Standard",
                      courierName: "Pick Up",
                      partnerName: "Self",
                    }))
                  }
                  else {
                    setGeneralInfo(prevState => ({
                      ...prevState,
                      dealerId: item.dealerId,
                      dealerName: item.name,
                      storeName: item.storeName,
                      billingAddress: item.street && item.street + " " + item.city && item.street + " " + item.city + " " +
                        item.state && item.street + " " + item.city + " " + item.state + " " + item.pincode &&
                        item.street + " " + item.city + " " + item.state + " " + item.pincode,
                    }))
                    setShippingInfo(prevState => ({
                      ...prevState, mobile: item.mobile,
                      name: item.storeName,
                      pincode: item.pincode,
                      street: item.street,
                      city: item.city,
                      state: item.state,
                    }))
                  }
                  setShowAcceptModal(false)
                  setSearchDealer(item.storeName && item.name && item.storeName + " / " + item.name)
                }}>
                {item.storeName + " / " + item.name}
              </p>
            )}

          </div>
        }
      />

      <PaymentModalMob
        show={showPaymentModal}
        close={setShowPaymentModal}
        handleRecipt={handleRecipt}
        setReciptData={setReciptData}
        reciptData={reciptData}
        isImage={isImage}
        setisImage={setisImage}
        totalAmount={totalAmount}
        accounts={accounts}
        setLoadingReceipt={setLoadingReceipt}
        loadingReceipt={loadingReceipt}
      />

      <Modal
        show={showCancelOrderModal}
        close={setShowCancelOrderModal}
        closeBtn={true}
        heading="CANCEL ORDER"
        content={
          <div className={styles.text_center}>
            <p className={styles.message}>Are You Sure Want To Cancel This Order</p>
            <div className={styles.massageModal_btn}>
              <RedOutButton css={styles.moveToSale_btn_no} title="NO" handleSubmit={() => setShowCancelOrderModal(false)} />
              <GreenButton css={styles.moveToSale_btn} title="YES" handleSubmit={() => { handleCancelOrder(); }} />
            </div>
          </div>
        }
      />

      <Modal
        show={moveToSaleModal}
        close={setMoveToSaleModal}
        closeBtn={true}
        heading="Move To Sale"
        content={
          <div className={styles.text_center}>
            <p className={styles.message}>Are You Sure Move To Sale</p>
            <div className={styles.massageModal_btn}>
              <GreenButton css={styles.moveToSale_btn} disabled={loadingMoveToSale ? true : false} title={loadingMoveToSale ? <Spinner size="15" /> : "YES"} handleSubmit={() => { MoveToSale() }} />
              <GreenOutButton css={styles.moveToSale_btn_no} title="NO" handleSubmit={() => { setMoveToSaleModal(false) }} />
            </div>
          </div>
        }
      />

    </div>
  )
}

export default MobEstimate;