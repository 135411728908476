import React, { useEffect, useRef, useState } from 'react'
import ModalMob from '../components/ModalMob'
import styles from './DispatchDasboardMob.module.css'
import { useNavigate, } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
import { ArrowIcon, CarIcon, CartIcon, DateIcon, DownloadIcongrey, EyeTwo, IndustryIcon, Loadmore, OrdersIcon, Search, SettingIcon } from '../../components/icons/Icon'
// import UseInfiniteScroll from '../../components/UseInfiniteScroll'

const DispatchDasboardMob = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const { managerId, designation } = useAuth();
    const [data, setData] = useState([]);
    const [daysTab, setDaysTab] = useState('today')
    const [shipingTab, setShipingTab] = useState('all')
    const [cartTab, setCartTab] = useState('all')
    const [orders, setOrders] = useState([])
    const [keyword, setKeyword] = useState('')
    const [sortKey, setSortKey] = useState('All')
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(true)
    const [orderStatus, setOrderStatus] = useState('')
    const [warehouses, setWarehouses] = useState([])
    const [warehouseName, setWarehouseName] = useState('')
    const [managers, setManagers] = useState([])
    const [managerName, setManagerName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showAcceptModal1, setShowAcceptModal1] = useState(false);
    const [showAcceptModal2, setShowAcceptModal2] = useState(false);
    const [showAcceptModal3, setShowAcceptModal3] = useState(false);
    const [showAcceptModal4, setShowAcceptModal4] = useState(false);
    const [showAcceptModal5, setShowAcceptModal5] = useState(false);
    const data1 = [
        {
            key: "1",
            sort: "All"
        },
        {
            key: "2",
            sort: "New Order"
        },
        {
            key: "3",
            sort: "Cancelled"
        },
        {
            key: "4",
            sort: "In progress"
        },
        {
            key: "5",
            sort: "Packed"
        },
        {
            key: "6",
            sort: "Pending"
        },
        {
            key: "7",
            sort: "Dispatched"
        },
        {
            key: "8",
            sort: "Descending"
        },
        {
            key: "9",
            sort: "Ascending"
        },
        {
            key: "10",
            sort: "Last Action"
        },
    ]

    const getCardData = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": parseInt(managerId),
            "keyword": keyword,
            "orderStatus": orderStatus,
            "sortKey": sortKey,
            "shippingType": shipingTab,
            "cartType": cartTab,
            "warehousename": warehouseName,
            "disPatchManagerId": managerName,
            "day": daysTab,
            "startDate": startDate,
            "endDate": endDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_URL}dispatch/allOrders?page=${page}&limit=8&skip=${true}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setLoading(false)
                    setData(result.data)
                    // setData((prev) => [...prev, ...result.data])
                    setOrders(result.orders)
                    if (result.warehouses) {
                        setWarehouses(result.warehouses)
                    }
                    if (result.managers) {
                        setManagers(result.managers)
                    }
                }
                if (result.pages === 1) {
                    setPage(1)
                }
                setPages(result.pages)
                setLoading1(false);
            })
            .catch(error => console.log('error', error));
    };

    useEffect(() => {
        /*eslint-disable*/
        getCardData();
    }, [page, managerId, sortKey, keyword, page, orderStatus, shipingTab, cartTab, warehouseName, managerName, daysTab, startDate, endDate]);

    const handelInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight

            ) {
                setLoading1(true);
                setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (pages > page) {
            window.addEventListener("scroll", handelInfiniteScroll);
            return () => window.removeEventListener("scroll", handelInfiniteScroll);
        }
    }, [pages, page]);


    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowAcceptModal3(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    return (
        <React.Fragment>
            <div className={styles.main_container}>
                <div className={styles.dispatch_div}>
                    <div onClick={() => setShowAcceptModal(true)} className={styles.tab_dropdown}>{daysTab ? daysTab : 'SelectDay'}{showAcceptModal ? <span>&#9650;</span> : <span>&#9660;</span>}</div>
                    <DateIcon />
                    <label className={styles.dispatch_div1}>
                        <div className={styles.date_text}>Start date:</div>
                        <input
                            type="date"
                            className={styles.date_select}
                            value={startDate}
                            onChange={(e) => { setStartDate(e.target.value); setDaysTab('') }}
                        />
                    </label>
                    <label className={styles.dispatch_div1}>
                        <div className={styles.date_text}>End date:</div>
                        <input
                            type="date"
                            className={styles.date_select}
                            value={endDate}
                            onChange={(e) => { setEndDate(e.target.value); setDaysTab('') }}
                        />
                    </label>
                </div>
                <div className={styles.statusCard_div}>
                {loading ?
                    <>
                        {
                            Array.from({ length: 7 }).map((_, idx) =>
                                <div key={idx} className='Mob_skeleton'>
                                    <span className={styles.orders_status_filter}>0</span>
                                    <span className={styles.statusTxt}>status</span>
                                </div>
                            )
                        }
                    </>
                    :orders && orders.map((item, index) =>
                        <div key={index} className={styles.statuscard1}>
                            <div className={`${styles.statusCard} ${orderStatus === item.status && item.status === 'New Order' ? styles.newOrder_active :
                                orderStatus === item.status && item.status === 'In Progress' ? styles.inProgress_active :
                                    orderStatus === item.status && item.status === 'Packed' ? styles.packed_active :
                                        orderStatus === item.status && item.status === 'Pending' ? styles.Pending_active :
                                            orderStatus === item.status && item.status === 'Dispatched' ? styles.Dispatches_active :
                                                orderStatus === item.status && item.status === 'All' ? styles.orange_active :
                                                    orderStatus === item.status && item.status === 'Cancelled' ? styles.cancel_status :
                                                        ''}`}
                                onClick={() => {
                                    setOrderStatus(item.status);
                                }}
                            >
                                <div className={`${styles.orders_status_filter} ` + (
                                    item.status === 'New Order' ? styles.statusNew :
                                        item.status === 'In Progress' ? styles.statusYellow :
                                            item.status === 'Packed' ? styles.statusBlue :
                                                item.status === 'All' ? styles.statusAll :
                                                    item.status === 'Pending' ? styles.statusRed :
                                                        item.status === 'Dispatched' ? styles.statusGreen :
                                                            item.status === 'Cancelled' ? styles.statusWhite :
                                                                '')}>
                                    {item.count}
                                    <div className={styles.statusTxt}>{item.status}</div>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <div className={styles.flex}>
                        <span className={styles.dashboard_search_div}>
                            <input type="text"
                                placeholder='Search'
                                className={styles.dashboard_search}
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                            <Search color="#000" css={styles.dashboard_search_icon} />
                        </span>
                        <span className={styles.icons_css} onClick={() => setShowAcceptModal2(true)}>
                            <CarIcon />
                        </span>
                        <span className={styles.icons_css} onClick={() => setShowAcceptModal1(true)}>
                            <CartIcon />
                        </span>
                        <span className={styles.icons_css} onClick={() => setShowAcceptModal4(true)}>
                            <SettingIcon />
                        </span>
                        <span className={styles.icons_css} onClick={() => setShowAcceptModal5(true)}>
                            <IndustryIcon />
                        </span>
                        <span className={styles.icons_css} onClick={() => setShowAcceptModal3(!showAcceptModal3)}>
                            <ArrowIcon />

                        </span>
                        <span className={styles.icons_css} >
                            <DownloadIcongrey color="#D3D3D3" />
                        </span>
                        {showAcceptModal3 &&
                            <div className={styles.short_div} ref={wrapperRef} onClick={(e) => e.stopPropagation()}>
                                <h3>Short By</h3>
                                {data1.map((item, index) =>
                                    <h6 key={index} className={`${sortKey === item.sort ? styles.days_active : ''}`}
                                        onClick={(e) => {
                                            setShowAcceptModal3(false);
                                            if (item.sort === 'Ascending' || item.sort === 'Descending' || item.sort === 'lastAction') {
                                                setSortKey(item.sort)
                                            } else {
                                                setOrderStatus(item.sort)
                                                setSortKey("")
                                            }
                                        }}>{item.sort}</h6>
                                )}
                            </div>
                        }
                    </div>
                    <h2 className={styles.orders_text}>Order's</h2>
                    {
                        loading ? <div className={'Mob_loading'}>
                            <img src='/wallicon.gif' alt='walliconGIF' />
                        </div> :
                            <>
                                {data.length !== 0 ?
                                    <div className={styles.orders + " pb-5"} >
                                       
                                        {!loading1 ?
                                            <>
                                                {data && data.map((item, index) =>
                                                    <div role='button' key={index} className={styles.orders_div}
                                                        onClick={() => navigate('/dispatchorders/' + item.orderId)}>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <OrdersIcon />
                                                            <div className={styles.order_detail}>
                                                                <h2>{item.storeName}</h2>
                                                                <div className={styles.orderDate}><span>OrderId : {item.orderId}</span><span>Date : {item.orderDate}</span></div>
                                                                <span>Order Manager : {item.ordermanager}</span>
                                                                {designation === 'superadmin' ?
                                                                    <span>Dispatch Manager : {item.disPatchManager}</span> :
                                                                    designation === 'dispatchmanager' ?
                                                                        <span>Packing Manager : {item.packinghManager}</span> :
                                                                        ''
                                                                }

                                                                <span
                                                                    className={`${styles.orders_status} ` + (
                                                                        item.status === 'New Order' ? styles.statusNew :
                                                                            item.status === 'In Progress' ? styles.statusYellow :
                                                                                item.status === 'Packed' ? styles.statusBlue :
                                                                                    item.status === 'All' ? styles.statusAll :
                                                                                        item.status === 'Pending' ? styles.statusRed :
                                                                                            item.status === 'Dispatched' ? styles.statusGreen :
                                                                                                item.status === 'Cancelled' ? styles.statusWhite :
                                                                                                    '')}
                                                                >{item.status}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            < EyeTwo />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <div className={styles.loadmore}>
                                                <Loadmore className={styles.loadmoreIocon} />
                                            </div>
                                        }
                                    </div>
                                    : <div className={styles.noData}>
                                        No Data Found !
                                    </div>
                                }
                            </>
                    }
                    {/* </>
                            :
                            <div className={styles.loadmore}>
                            <Loadmore className={styles.loadmoreIocon} />
                            </div>
                        } */}
                </div>
            </div>

            <ModalMob
                show={showAcceptModal}
                close={setShowAcceptModal}
                // closeBtn={true}
                // heading="Order Status"
                content={
                    <div className={styles.dispatch_select}>
                        <h2 className={`${styles.dashboard_days_select} ${daysTab === 'today' ? styles.days_active : ''}`} onClick={() => { setDaysTab('today'); setShowAcceptModal(false) }}>Today</h2>
                        <h2 className={`${styles.dashboard_days_select} ${daysTab === 'yesterday' ? styles.days_active : ''}`} onClick={() => { setDaysTab('yesterday'); setShowAcceptModal(false) }}>Yesterday</h2>
                        <h2 className={`${styles.dashboard_days_select} ${daysTab === 'month' ? styles.days_active : ''}`} onClick={() => { setDaysTab('month'); setShowAcceptModal(false) }}>This Month</h2>
                    </div>
                }
            />
            <ModalMob
                show={showAcceptModal1}
                close={setShowAcceptModal1}
                // closeBtn={true}
                // heading="Order Status"
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Select Cart Type</h2>
                        <div className={styles.flex} onClick={() => { setCartTab('all'); setShowAcceptModal1(false) }}>
                            <h2 className={styles.dashboard_days_select1}>All</h2>
                            <input checked={cartTab === 'all' ? true : false} type='checkbox' className={styles.largerCheckbox} />
                        </div>
                        <div className={styles.flex} onClick={() => { setCartTab('cart'); setShowAcceptModal1(false) }}>
                            <h2 className={styles.dashboard_days_select1} >Cart</h2>
                            <input checked={cartTab === 'cart' ? true : false} type='checkbox' className={styles.largerCheckbox} />
                        </div>
                        <div className={styles.flex} onClick={() => { setCartTab('pcart'); setShowAcceptModal1(false) }}>
                            <h2 className={styles.dashboard_days_select1} >Partner cart</h2>
                            <input checked={cartTab === 'pcart' ? true : false} type='checkbox' className={styles.largerCheckbox} />
                        </div>
                    </div>
                }

            />
            <ModalMob
                show={showAcceptModal2}
                close={setShowAcceptModal2}
                // closeBtn={true}
                // heading="Order Status"
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Choose Delivery Type</h2>
                        <div className={styles.flex} onClick={() => { setShipingTab('all'); setShowAcceptModal2(false) }}>
                            <h2 className={styles.dashboard_days_select1} >All</h2>
                            <input checked={shipingTab === 'all' ? true : false} type='checkbox' className={styles.largerCheckbox}
                            />
                        </div>
                        <div className={styles.flex} onClick={() => { setShipingTab('standred'); setShowAcceptModal2(false) }}>
                            <h2 className={styles.dashboard_days_select1} >Standard</h2>
                            <input checked={shipingTab === 'standred' ? true : false} type='checkbox' className={styles.largerCheckbox} />
                        </div>
                        <div className={styles.flex} onClick={() => { setShipingTab('express'); setShowAcceptModal2(false) }}>
                            <h2 className={styles.dashboard_days_select1} >Express</h2>
                            <input checked={shipingTab === 'express' ? true : false} type='checkbox' className={styles.largerCheckbox} />
                        </div>
                    </div>
                }
            />


            <ModalMob
                show={showAcceptModal4}
                close={setShowAcceptModal4}
                // closeBtn={true}
                // heading="Order Status"
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Select Managers</h2>
                        {managers && managers.map((item, index) =>
                            <div key={index} className={styles.flex} onClick={() => { setManagerName(item.name); setShowAcceptModal4(false) }}>
                                <h2 className={styles.dashboard_days_select1}>{item.name}</h2>
                                <input checked={managerName === item.name ? true : false} type='checkbox' className={styles.largerCheckbox}
                                />
                            </div>
                        )}
                    </div>
                }
            />
            <ModalMob
                show={showAcceptModal5}
                close={setShowAcceptModal5}
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Select Warehouse</h2>
                        {warehouses && warehouses.map((item, index) =>
                            <div className={styles.flex} key={index} onClick={() => { setWarehouseName(item.warehousename); setShowAcceptModal5(false); }}>
                                <h2 className={styles.dashboard_days_select1}>{item.warehousename}</h2>
                                <input checked={warehouseName === item.warehousename ? true : false} type='checkbox' className={styles.largerCheckbox}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        </React.Fragment>

    )
}
export default DispatchDasboardMob;