import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GreenOutButton } from '../../../components/Buttons'
import { AddDealerIcon } from '../../../components/icons/Icon'
import styles from "../../kyc/Dealer.module.css"
import { useAuth } from '../../../components/context/Auth'
import Pagination from '../../../components/Paginantion'

const Applicants = () => {
    const { managerId } = useAuth()
    const navigate = useNavigate();
    const [searchparams] = useSearchParams();
    const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;

    const [data, setData] = useState([]);
    const [pages, setPages] = useState("");
    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "careers/applicants?page=" + page, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(result.data)
                        setPages(result.pages)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, page])

    // console.log(data[data.length - 1] - 1);
    return (
        <React.Fragment>
            <div className={styles.right_main}>
                <div className={styles.addDealers}>
                    <h1 className={styles.dealers}>
                        Applicants
                    </h1>
                    <GreenOutButton
                        btnType="button"
                        title={<><AddDealerIcon />&nbsp;&nbsp;POST JOB</>}
                        css={styles.addDealerButton} handleSubmit={() => { navigate('/job') }}
                    />
                </div>
                <div className={styles.Dealer_main_container + " p-3"}>
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>Sr</th>
                                <th>Name</th>
                                <th>Applied For</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>City</th>
                                <th>Resume</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, index) =>
                                <tr key={index}>
                                    <td>{data.length - index}</td>
                                    <td>{item.fullname}</td>
                                    <td>{item.jobTitle}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.email}</td>
                                    <td>{item.city}</td>
                                    <td><a href={process.env.REACT_APP_S3URL + item.resume} target='_blank' download="newfilename">Download</a></td>
                                    {/* <td>{item.status}</td> */}
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Pagination
                        total={pages}
                        current={page}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Applicants