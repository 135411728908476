import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { GreenButton, RedOutButton } from "../../components/Buttons";
import { DeleteFile, UpLoadFile } from "../../components/icons/Icon";
import stylescss from "./Product.module.css";

const AddItem = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getId = searchParams.get("product_id");
  const product_id = !getId || getId === null ? "" : getId;
  const itemId = searchParams.get("itemId");
  const item_id = !itemId || itemId === null ? "" : itemId;
  let origin = window.location.origin;

  const product_name =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const [addData, setAddData] = useState({
    productimg: "",
    closeupimg: "",
    thumbnail: "",
    type_name: "",
    itemNo: "",
    rating: "",

    description: "",
    title: "",
    url: "",
    status: "",
    itemSize: "",
    itemColor: "",
    itemAddMoreColor: [],
    metaTitle: "",
    metaKeywords: "",
    focusKeyword: "",
    metaDescription: "",
    qty: "",
    price: "",
    mrp: "",
  });

  // ---------------------------Add-More-----------------------------------
  const [addMoreItem, setAddMoreItem] = useState([
    {
      image: "",
      masterimg: false,
    },
  ]);
  const addFields = () => {
    let newfield = { image: "", masterimg: false };
    setAddMoreItem([...addMoreItem, newfield]);
  };

  // const handleFormChange = (index, event) => {
  //     let data = [...addMoreItem];
  //     data[index][event.target.name] = event.target.value;
  //     setAddMoreItem(data);
  // }

  const handleFileChange = (index, event) => {
    let data = [...addMoreItem];
    data[index]["image"] = event.target.files[0];
    setAddMoreItem(data);
  };
  const removeFormFields = (i) => {
    const values = [...addMoreItem];
    values.splice(i, 1);
    setAddMoreItem(values);
  };

  // ---------------------------Add-More-Size-----------------------------------
  const [addMoreSize, setAddMoreSize] = useState([
    {
      size: "",
      qty: "",
    },
  ]);
  const handleSizeChange = (index, event) => {
    let data = [...addMoreSize];
    data[index][event.target.name] = event.target.value;
    setAddMoreSize(data);
  };

  const addMoreFields = () => {
    let newfield = { size: "", qty: "" };
    setAddMoreSize([...addMoreSize, newfield]);
  };

  const removeSize = (i) => {
    const values = [...addMoreSize];
    values.splice(i, 1);
    setAddMoreSize(values);
  };

  // ---------------------------Remove-Update-Img-Key-----------------------------------
  const [demoUpdate, setDemoUpdate] = useState([]);

  // ---------------------------Add-More-Color-----------------------------------
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : "#1E2022",
        color: "#fff",
      };
    },
  };

  const [selectedValue, setSelectedValue] = useState([]);

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const [items, setItems] = useState([]);
  // ---------------------------Meta Keywords----------------------
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  // Ravi Changes
  const [tages2, setTages2] = useState([]);
  useEffect(() => {
    const trimmedInput = input.trim();
    if (input.includes(",")) {
      setTages2(trimmedInput.split(","));
    }
  }, [input]);

  useEffect(() => {
    if (tages2) {
      setTags(tages2);
      setInput("");
    }
  }, [tages2]);
  // Ravi Changes

  // ---------------------------Get Item-----------------------------------
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      prodId: product_id,
      _id: item_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // fetch("http://192.168.0.108:5052/content/item", requestOptions)
    fetch(process.env.REACT_APP_URL + "content/item", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          if (result.data) {
            setAddData((prevState) => ({
              ...prevState,
              itemNo: result.data.itemNo,
              status: result.data.status,
              // itemSize: result.data.itemSize,
              itemColor: result.data.itemColor,

              title: result.data.title,
              url: result.data.url ? result.data.url : "",

              rating: result.data.rating,
              description: result.data.description,

              metaTitle: result.data.metaTitle,
              metaKeywords: result.data.metaKeywords,
              focusKeyword: result.data.focusKeyword,
              metaDescription: result.data.metaDescription,
              qty: result.data.qty,
              price: result.data.price,
              mrp: result.data.mrp,
            }));
            if (result.data.metaKeywords.length === 1) {
              setTags(result.data.metaKeywords[0].split(","));
            } else {
              setTags(result.data.metaKeywords);
            }
            // setAddMoreItem(result.data.itemImgDetails.stylesImgs);
            setAddMoreItem(result.data.images);
            setSelectedValue(result.data.itemAddMoreColor);
            setAddMoreSize(result.data.itemSize);
          }

          setItems(result.items);
        }
      })
      .catch((error) => console.log("error", error));
  }, [item_id, product_id]);


    // ---------------------------Add-Update-Item-----------------------------------
    const handleSubmit = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("product_name", product_name.replace(/-/g, " "));
        formdata.append("product_id", product_id);
        formdata.append("itemSize", JSON.stringify(addMoreSize));
        formdata.append("itemColor", addData.itemColor);
        formdata.append("itemAddMoreColor", selectedValue);
        formdata.append("url", addData.url);
        formdata.append("metaTitle", addData.metaTitle);
        formdata.append("title", addData.title);
        formdata.append("description", addData.description);
        formdata.append("rating", addData.rating);
        formdata.append("metaKeywords", JSON.stringify(tags));
        formdata.append("focusKeyword", addData.focusKeyword);
        formdata.append("metaDescription", addData.metaDescription);
        formdata.append("status", addData.status);
        formdata.append("qty", addData.qty);
        formdata.append("price", addData.price);
        formdata.append("mrp", addData.mrp);
        demoUpdate && formdata.append("demoUpdate", demoUpdate);
        item_id && formdata.append("_id", item_id);

        addMoreItem.map(item =>
            <>
                {(formdata.append("image", item.image),
                    formdata.append("masterimg", item.masterimg)
                )}
            </>
        )

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        // fetch("http://192.168.0.108:5052/content/addUpdateItem/" + addData.itemNo + '?item_id=' + item_id, requestOptions)
        fetch(process.env.REACT_APP_URL + "content/addUpdateItem/" + addData.itemNo + '?item_id=' + item_id, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    alert("Item Updated Successfully");
                    navigate(-1)
                } else {
                    alert(result.msg);
                }
            })
            .catch(error => console.log('error', error));

    }

  // console.log(addMoreSize);
  return (
    <React.Fragment>
      <div className={stylescss.product_right}>
        <div className={stylescss.product_header}>
          <div>
            <u onClick={() => navigate(-1)}>&lt; Go Back</u>
          </div>
          <div>
            <h1>{product_name}</h1>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className={stylescss.add_product_main_div}
        >
          <div className={stylescss.metaRow}>
            <div className={stylescss.meta}>Meta URL</div>
            <div className={stylescss.url}>
              {origin}
              <input
                type={"text"}
                name="url"
                value={addData.url}
                onChange={(e) =>
                  setAddData({ ...addData, url: e.target.value })
                }
                placeholder="Enter Url"
                className={stylescss.add_url_input}
              />
              {origin.length + addData.url.length}
              {/* {addData.url.length} */}
            </div>
          </div>
          <div className={stylescss.addProductRow}>
            <div className={stylescss.addProductCol}>
              <div className={stylescss.addProductColP}>
                {/* --------------------------------Upload Images----------------------- */}
                <div className={stylescss.addItemRow}>
                  <div className={stylescss.addProductCol3}>Image</div>
                  <div className={stylescss.addBannerCol9}>
                    <div />
                    <div />
                    <div className={stylescss.text_center}>
                      {" "}
                      Select <br />
                      Master Image{" "}
                    </div>
                    <div />
                  </div>
                </div>
                {addMoreItem &&
                  addMoreItem.map((item, index) => (
                    <div className={stylescss.itemImages} key={index}>
                      <img
                        src={
                          item.image && item.image.name
                            ? URL.createObjectURL(item.image)
                            : item.image
                            ? process.env.REACT_APP_S3URL + item.image
                            : "/assets/preview.png"
                        }
                        alt="img"
                        height={65}
                        width={50}
                      />

                      <label className={stylescss.bannerAddBtn}>
                        <UpLoadFile />
                        Upload Image
                        <input
                          name="image"
                          type="file"
                          className={stylescss.bannerImgInput}
                          onChange={(e) => {
                            handleFileChange(index, e);
                            setDemoUpdate([...demoUpdate, item._id]);
                          }}
                          accept="image/*"
                        />
                      </label>

                      <input
                        // className={stylescss.bannerUrlInput}
                        type="radio"
                        name="masterimg"
                        checked={addMoreItem[index].masterimg === true}
                        onChange={(e) => {
                          let isChecked = e.target.checked;
                          addMoreItem[index].masterimg = isChecked;
                          setAddMoreItem([...addMoreItem]);

                          if (isChecked) {
                            let data = [...addMoreItem];
                            data.map(
                              (item, i) =>
                                i !== index && (item.masterimg = false)
                            );
                            setAddMoreItem(data);
                          }
                        }}
                        required
                      />

                      <div
                        className={stylescss.bannerDeleteBtn}
                        onClick={() => {
                          removeFormFields(index);
                          setDemoUpdate([...demoUpdate, item._id]);
                        }}
                      >
                        <DeleteFile color="red" />
                        <br />
                        Delete
                      </div>
                    </div>
                  ))}

                <div className={stylescss.addMoreBtn}>
                  <button
                    type="button"
                    onClick={() => {
                      addFields();
                    }}
                  >
                    + Add More
                  </button>
                </div>
                <br />
                <br />
                <div className={stylescss.addBannerRow}>
                  <div className={stylescss.addProductCol3}>Qty</div>
                  <div className={stylescss.addProductCol9}>
                    <input
                      required
                      type="number"
                      name="qty"
                      value={addData.qty}
                      onChange={(e) =>
                        setAddData({ ...addData, qty: e.target.value })
                      }
                      className={stylescss.add_product_input}
                    />
                  </div>
                </div>
                <div className={stylescss.addBannerRow}>
                  <div className={stylescss.addProductCol3}>MRP</div>
                  <div className={stylescss.addProductCol9}>
                    <input
                      required
                      type="number"
                      name="mrp"
                      value={addData.mrp}
                      onChange={(e) =>
                        setAddData({ ...addData, mrp: e.target.value })
                      }
                      className={stylescss.add_product_input}
                    />
                  </div>
                </div>
                <div className={stylescss.addBannerRow}>
                  <div className={stylescss.addProductCol3}>Price</div>
                  <div className={stylescss.addProductCol9}>
                    <input
                      required
                      type="number"
                      name="price"
                      value={addData.price}
                      onChange={(e) =>
                        setAddData({ ...addData, price: e.target.value })
                      }
                      className={stylescss.add_product_input}
                    />
                  </div>
                </div>
                <div className={stylescss.addBannerRow}>
                  <div className={stylescss.addProductCol3}>Status</div>
                  <div className={stylescss.addProductCol9}>
                    <label>
                      <input
                        required
                        type="radio"
                        name="status"
                        value="Active"
                        onChange={(e) =>
                          setAddData({ ...addData, status: e.target.value })
                        }
                        checked={addData.status === "Active" ? true : false}
                      />
                      Active
                    </label>
                    <label>
                      <input
                        required
                        type="radio"
                        name="status"
                        value="Disable"
                        onChange={(e) =>
                          setAddData({ ...addData, status: e.target.value })
                        }
                        checked={addData.status === "Disable" ? true : false}
                      />
                      Disable
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className={stylescss.addProductCol}>
              <div className={stylescss.addProductColP}>
                <div className={stylescss.addItemRow}>
                  <div className={stylescss.addProductCol3}>Item Number</div>
                  <div className={stylescss.addProductCol9}>
                    <input
                      type="text"
                      name="itemNo"
                      value={addData.itemNo}
                      onChange={(e) =>
                        setAddData({ ...addData, itemNo: e.target.value })
                      }
                      placeholder="Enter Item Number"
                      className={stylescss.add_product_input}
                      required
                    />
                  </div>
                </div>
                <div className={stylescss.addItemRow}>
                  <div className={stylescss.addProductCol3}>Product Title</div>
                  <div className={stylescss.addProductCol9}>
                    <textarea
                      rows={3}
                      name="proctTitle"
                      value={addData.title}
                      onChange={(e) =>
                        setAddData({ ...addData, title: e.target.value })
                      }
                      placeholder="Enter Title"
                      className={stylescss.add_product_input}
                      required
                    />
                  </div>
                </div>
                <div className={stylescss.addItemRow}>
                  <div className={stylescss.addProductCol3}>
                    Product Description
                  </div>
                  <div className={stylescss.addProductCol9}>
                    <textarea
                      name="description"
                      value={addData.description}
                      onChange={(e) =>
                        setAddData({ ...addData, description: e.target.value })
                      }
                      placeholder="Enter Description"
                      className={ stylescss.add_product_input }
                      rows={5}
                      required
                    />
                  </div>
                </div>
                {addMoreSize?.map((item, index) => (
                  <div key={index} className={stylescss.addItemRow}>
                    <div className={stylescss.addProductCol3}>Size</div>

                    <div className={`d-flex ${stylescss.addProductCol9}`}>
                      <input
                        type="text"
                        name="size"
                        value={item.size}
                        onChange={(e) => handleSizeChange(index, e)}
                        placeholder="Enter Size"
                        className={`${stylescss.add_product_input} me-2`}
                        required
                      />
                      <input
                        type="text"
                        name="qty"
                        value={item.qty}
                        onChange={(e) => handleSizeChange(index, e)}
                        placeholder="Enter Qty"
                        className={stylescss.add_product_input}
                        required
                      />
                      <div
                        className={stylescss.bannerDeleteBtn}
                        onClick={() => {
                          removeSize(index);
                        }}
                      >
                        <DeleteFile color="red" />
                        <br />
                        Delete
                      </div>
                    </div>
                  </div>
                ))}
                <div className={stylescss.addMoreBtn}>
                  <button
                    type="button"
                    onClick={() => {
                      addMoreFields();
                    }}
                  >
                    + Add More
                  </button>
                </div>
                {/* <div className={stylescss.textMandatory}>*Size Is Mandatory</div> */}
                <div className={stylescss.addItemRow}>
                  <div className={stylescss.addProductCol3}>Color</div>
                  <div className={stylescss.addProductCol9}>
                    <input
                      type="text"
                      name="itemColor"
                      value={addData.itemColor}
                      onChange={(e) =>
                        setAddData({ ...addData, itemColor: e.target.value })
                      }
                      placeholder="Enter Color"
                      className={stylescss.add_product_input}
                      required
                    />
                  </div>
                </div>
                <div className={stylescss.addItemRow}>
                  <div className={stylescss.addProductCol3}>Add More Color</div>
                  <div className={stylescss.addProductCol9}>
                    <Select
                      options={items.map((x) => ({
                        value: x.itemNo,
                        label: x.itemNo,
                      }))}
                      value={items
                        .map((x) => ({ value: x.itemNo, label: x.itemNo }))
                        .filter((obj) => selectedValue.includes(obj.value))}
                      onChange={handleChange}
                      className={stylescss.selectReact}
                      styles={colourStyles}
                      isMulti
                    />
                  </div>
                </div>

                <div className={stylescss.addItemRow}>
                  <div className={stylescss.addProductCol3}>Title</div>
                  <div className={stylescss.addProductCol9}>
                    <textarea
                      rows={3}
                      name="metaTitle"
                      value={addData.metaTitle}
                      onChange={(e) => {
                        setAddData({
                          ...addData,
                          metaTitle: e.target.value,
                          url: e.target.value
                            .toLocaleLowerCase()
                            .replace(/[\s,-=;]+/g, "-"),
                        });
                      }}
                      placeholder="Enter Title"
                      className={stylescss.add_product_input}
                      required
                    />
                    <div>
                      *use 60 letters only
                      <span className={stylescss.counter}>
                        {" "}
                        {addData.metaTitle.length}
                      </span>
                    </div>
                  </div>
                </div>

                <div className={stylescss.addItemRow}>
                  <div className={stylescss.addProductCol3}>Description</div>
                  <div className={stylescss.addProductCol9}>
                    <textarea
                      name="metaDescription"
                      value={addData.metaDescription}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          metaDescription: e.target.value,
                        })
                      }
                      placeholder="Enter Description"
                      className={ stylescss.add_product_input }
                      rows={5}
                      required
                    />
                    {/* <div className={stylescss.textMandatory}>*use 60-1200 letters only</div> */}
                    <div>
                      *use 60 letters only
                      <span className={stylescss.counter}>
                        {addData.metaDescription.length}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={stylescss.addBannerRow}>
                  <div className={stylescss.addProductCol3}>
                    Keywords (optional)
                  </div>
                  <div className={stylescss.addtag}>
                    <div className={stylescss.tag_main_div}>
                      {tags.map((tag, index) => (
                        <div key={index} className={stylescss.tag}>
                          {tag}
                          <button
                            type="button"
                            className={stylescss.tag_button}
                            onClick={() => deleteTag(index)}
                          >
                            x
                          </button>
                        </div>
                      ))}
                      <textarea
                        rows={1}
                        value={input}
                        placeholder="Enter a tag"
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        onChange={onChange}
                        className={stylescss.add_inputtag}
                      />
                    </div>
                  </div>
                </div>
                <div className={stylescss.addBannerRow}>
                  <div className={stylescss.addProductCol3}>
                    Focus Keywords (optional)
                  </div>
                  <div className={stylescss.addProductCol9}>
                    <input
                      type="text"
                      name="focusKeyword"
                      value={addData.focusKeyword}
                      onChange={(e) =>
                        setAddData({ ...addData, focusKeyword: e.target.value })
                      }
                      placeholder="Enter Focus Keyword"
                      className={stylescss.add_product_input}
                    />
                  </div>
                </div>
                {addData.rating && (
                  <div className={stylescss.addItemRow}>
                    <div className={stylescss.addProductCol3}>Rating</div>
                    <div className={stylescss.addProductCol9}>
                      <input
                        type="text"
                        placeholder="Enter Rating"
                        name="rating"
                        value={addData.rating}
                        onChange={(e) =>
                          setAddData({ ...addData, rating: e.target.value })
                        }
                        className={stylescss.add_product_input}
                        required
                        disabled
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={stylescss.addItemBtmBtn}>
            <RedOutButton
              btnType="button"
              title="CANCEL"
              handleSubmit={() => navigate(-1)}
            />
            <GreenButton
              btnType="submit"
              title={item_id ? "UPDATE" : "CREATE"}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddItem;
