import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth';
import { EyeIcon, PackingOrderIcon } from '../../components/icons/Icon';
import styles from './Dishpatchboy.module.css'
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const PackingOrders = () => {
    const navigate = useNavigate();
    const { switchDisplay, managerId } = useAuth();
    const [showTab, setShowTab] = useState("New Order")
    const [show, setShow] = useState(false)
    const [data, setData] = useState([])
    const [statusData, setStatusData] = useState([])
    const [loading, setLoading] = useState(false)
    const [acceptOrderData, setAcceptOrderData] = useState([])

    useEffect(() => {
        if (managerId) {
            setData('')
            setStatusData('')
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "status": showTab
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "packing/allOrders?page=1", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setData(result.data)
                    }
                    if (result.orders) {
                        setStatusData(result.orders)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, showTab])

    const handleAcceptOrder = (orderId) => {
        var myHeaders = new Headers();
        myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "packing/acceptOrder", requestOptions)
            .then(response => response.json())
            .then(result => {
                alert(result.msg)
                if (result.status === "success") {
                    navigate("/packingorder/" + result.data.orderId)
                    setShow(false)
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <React.Fragment>
            <div className={`${switchDisplay ? styles.bg_dark : styles.bg_white} ${styles.mark}`}>
                {
                    loading ?
                        <div className={'loadingMain'}>
                            <img src='/wallicon.gif' alt='walliconGIF' />
                        </div>
                        :
                        <div className={styles.marketingMain}>
                            <h1 className={styles.main_heading}>Today's Progress</h1>

                            <div className={styles.progressStatus}>
                                {
                                    statusData && statusData.map((item, index) =>
                                        <div key={index} className={styles.progressStatus_tab} onClick={() => { setShowTab(item.status) }}>
                                            <span className={`${styles.status_num} ${(item.status === 'New Order') ? styles.progressStatus_yellow :
                                                item.status === 'Not Packed' ? styles.progressStatus_red :
                                                    styles.progressStatus_green}`}>{item.count}</span>
                                            <span className={styles.status_txt}>{item.status === 'Not Packed' ? 'In Progress' : item.status}</span>
                                        </div>
                                    )
                                }
                            </div>
                            <h1 className={styles.progressStatus_heading}>{showTab === 'New Order' ? 'New Orders' : showTab === 'Not Packed' ? 'In Progress' : showTab}</h1>
                            {/* <div className={styles.tabs_div}>
                                    <div className={showTab === "New Order" ? styles.tab + " " + styles.tab_active : styles.tab} onClick={() => { setShowTab("New Order") }}>
                                        New Order
                                    </div>
                                    <div className={showTab === "Not Packed" ? styles.tab + " " + styles.tab_active : styles.tab} onClick={() => { setShowTab("Not Packed") }}>
                                        Not Packed
                                    </div>
                                    <div className={showTab === "Packed" ? styles.tab + " " + styles.tab_active : styles.tab} onClick={() => { setShowTab("Packed") }}>
                                        Packed
                                    </div>
                                </div> */
                            }
                            {
                                data && data.map((item, index) =>
                                    <div key={index} className={styles.card_div} onClick={() => {
                                        if (!item.status || item.status === "New Order") {
                                            setAcceptOrderData(item);
                                            setShow(true)
                                        } else {
                                            navigate("/packingorder/" + item.orderId)
                                        }
                                    }}>
                                        <div className={styles.flexdiv}>
                                            <span className={styles.packingIcon}><PackingOrderIcon /></span>
                                            <div className={styles.packingDetailDiv}>
                                                <h4 className={styles.card_heading}>{item.storeName}</h4>
                                                <div className={styles.subtext}>Order: {item.orderId}</div>
                                                <div className={styles.subtext}>Order Date: {item.orderDate}</div>
                                                <div className={styles.subtext}>Total Items: {item.totalItems}</div>
                                            </div>
                                        </div>
                                        <div><EyeIcon color='#7A7A7A' /></div>
                                    </div>
                                )
                            }
                            {/* --------------modal-------------------------------- */}
                            <Modal
                                show={show}
                                onHide={() => setShow(false)}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                className={styles.selectModal}
                            >
                                <div className={styles.lost_content}>
                                    <div className={styles.flexdiv}>
                                        <p className={styles.card_heading}>{acceptOrderData.storeName}</p>
                                        <p className={styles.card_heading}>OrderId:{acceptOrderData.orderId}</p>
                                    </div>
                                    <div className={styles.ModalItemsScroll}>
                                        {acceptOrderData.items && acceptOrderData.items.map((item, index) =>
                                            <div key={index} className={styles.card_div}>
                                                <div className={styles.flexdiv}>
                                                    <div>
                                                        <img src={process.env.REACT_APP_S3URL + item.thumbnail} alt={item.itemNo} />
                                                    </div>
                                                    <div>
                                                        <p className={styles.subtext}>{item.product_name}</p>
                                                        <p className={styles.subtext}>{item.itemNo}</p>
                                                        <p className={styles.subtext}>{item.warehouse}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className={styles.subtext}>Qty: <b>{item.qty}</b></p>
                                                    <p className={styles.subtext}>Unit: {item.unit}</p>
                                                    <p className={styles.subtext}>Batch: {item.batch}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.selectDispatchManager}>
                                        <select className={styles.selectManager}>
                                            <option>1</option>
                                            <option>1</option>
                                            <option>1</option>
                                        </select>
                                    </div>
                                    <div className={styles.flexdiv}>
                                        <button className={styles.btn1} onClick={() => setShow(false)}>Close</button>
                                        <button className={styles.btn2} onClick={() => { handleAcceptOrder(acceptOrderData.orderId) }}>Accept</button>
                                    </div>
                                </div>
                            </Modal>

                            {/* <div className={styles.leadlost} style={Style} onClick={leadlost}>
                        <div className={styles.lost_content}>
                            <div className={styles.flexdiv}>
                                <p className={styles.card_heading}>{acceptOrderData.storeName}</p>
                                <p className={styles.card_heading}>OrderId:{acceptOrderData.orderId}</p>
                            </div>
                            <div className={styles.ModalItemsScroll}>
                                {acceptOrderData.items && acceptOrderData.items.map((item, index) =>
                                    <div key={index} className={styles.card_div}>
                                        <div className={styles.flexdiv}>
                                            <div>
                                                <img src={process.env.REACT_APP_S3URL + item.thumbnail} alt={item.itemNo} />
                                            </div>
                                            <div>
                                                <p className={styles.subtext}>{item.product_name}</p>
                                                <p className={styles.subtext}>{item.itemNo}</p>
                                                <p className={styles.subtext}>{item.warehouse}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className={styles.subtext}>Qty: <b>{item.qty}</b></p>
                                            <p className={styles.subtext}>Unit: {item.unit}</p>
                                            <p className={styles.subtext}>Batch: {item.batch}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.selectDispatchManager}>
                               
                            </div>
                            <div className={styles.flexdiv}>
                                <button className={styles.btn1}>Close</button>
                                <button className={styles.btn2} onClick={() => { handleAcceptOrder(acceptOrderData.orderId) }}>Accept</button>
                            </div>
                        </div>
                    </div> */}
                            {/* --------------modal-------------------------------- */}

                        </div>
                }
            </div>
        </React.Fragment>
    )
}
export default PackingOrders;