import React, { useEffect, useState } from 'react'
import { GreenButton } from '../../components/Buttons';
import { useAuth } from '../../components/context/Auth';
import { Envelope, MessageIcon, WhatsappIcon } from '../../components/icons/Icon';
import OrderCard from '../../components/OrderCard';
import ProgressCircle from '../../components/ProgressCircle';
import styles from '../ordermanager/OrderManager.module.css'

const OrderDashboard = () => {

  const { switchDisplay, managerId } = useAuth();
  const [newOrderData, setNewOrderData] = useState([]);
  const [inProgressData, setInProgressData] = useState([]);
  const [paymentPendingData, setPaymentPendingData] = useState([]);
  const [notDispatchedData, setNotDispatchedData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  // const [orderData, setOrderData] = useState([])



  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": parseInt(managerId)
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/dashboard", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setNewOrderData(result.data.newOrders)
            setInProgressData(result.data.inprogress)
            setPaymentPendingData(result.data.paymentPending)
            setNotDispatchedData(result.data.notDispatched)
            setCompletedData(result.data.completed)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [managerId])

  return (
    <React.Fragment>
      <div className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.marketingMain}`}>
        <h1 className={styles.main_heading}>Order Dashboard</h1>
        <div className={styles.orderCards_mainDiv}>
          <div className={styles.row}>
            <OrderCard title="NEW ORDER" data={newOrderData} />
            <OrderCard title="IN PROGRESS" data={inProgressData} />
            <OrderCard title="PAYMENT PENDING" data={paymentPendingData} />
            <OrderCard title="NOT DISPATCHED" data={notDispatchedData} />
            <OrderCard title="COMPLETED" data={completedData} />
          </div>
        </div>

        <div className={styles.actionPending_div}>

          <div className={styles.actionPending}>
            <h3 className={styles.actionPending_heading}>ACTIONS PENDING</h3>
            <div className={styles.dashboard_select_overall}>
              <div className={`${styles.dashboard_overall_select} ${styles.days_active}`}>Today</div>
              <div className={styles.dashboard_overall_select}>Overall</div>
            </div>

            <div className={styles.progressBar_center}>
              <ProgressCircle percent={55} />
            </div>

            <div className={styles.takeAction}><GreenButton title="TAKE ACTION" /></div>
          </div>

          <div className={styles.InactiveDealers}>
            <h3 className={styles.actionPending_heading}>INACTIVE DEALERS</h3>
            <div className={styles.InactiveDealers_div}>

              <div className={styles.InactiveDealers_left}>
                <div className={styles.dashboard_select_overall}>
                  <div className={`${styles.dashboard_overall_select} ${styles.days_active}`}>15 Days</div>
                  <div className={styles.dashboard_overall_select}>Over 15 Days</div>
                </div>

                <div className={styles.selectAll}>
                  <input type='checkbox' />&nbsp;Select All
                </div>

                <div>
                  <div className={styles.inactive_profile_details}>
                    <input type='checkbox' />
                    <img src='/assets/dealerprofile.png' alt='dealer_profile' width={60} height={60} className={styles.inactive_dealer_img} />
                    <div className={styles.profile_details}>
                      <span><b>Demo Interiors</b></span>
                      <span>DEMO0906/08/02</span>
                      <span>Last Order: <b>12/07/2021</b></span>
                    </div>
                  </div>
                  <div className={styles.inactive_profile_details}>
                    <input type='checkbox' />
                    <img src='/assets/dealerprofile2.png' alt='dealer_profile' width={60} height={60} className={styles.inactive_dealer_img} />
                    <div className={styles.profile_details}>
                      <span><b>Demo Interiors</b></span>
                      <span>DEMO0906/08/02</span>
                      <span>Last Order: <b>12/07/2021</b></span>
                    </div>
                  </div>
                  <div className={styles.inactive_profile_details}>
                    <input type='checkbox' />
                    <img src='/assets/dealerprofile3.png' alt='dealer_profile' width={60} height={60} className={styles.inactive_dealer_img} />
                    <div className={styles.profile_details}>
                      <span><b>Demo Interiors</b></span>

                      <span>DEMO0906/08/02</span>
                      <span>Last Order: <b>12/07/2021</b></span>
                    </div>
                  </div>
                  <div className={styles.inactive_profile_details}>
                    <input type='checkbox' />
                    <img src='/assets/dealerprofile4.png' alt='dealer_profile' width={60} height={60} className={styles.inactive_dealer_img} />
                    <div className={styles.profile_details}>
                      <span><b>Demo Interiors</b></span>
                      <span>DEMO0906/08/02</span>
                      <span>Last Order: <b>12/07/2021</b></span>
                    </div>
                  </div>
                </div>
              </div>


              <div className='w-100'>
                <div className={styles.InactiveIcon_main}>
                  <div className={styles.inactiveIcon_div}>
                    <div><Envelope color='#FFF' css={styles.inactiveIcon} /></div>
                  </div>
                  <div className={styles.inactiveIcon_div}>
                    <div><MessageIcon color='#FFF' css={styles.inactiveIcon} /></div>
                  </div>
                  <div className={styles.inactiveIcon_div}>
                    <div><WhatsappIcon color='#FFF' css={styles.inactiveIcon} /></div>
                  </div>
                </div>

                <textarea rows="13" placeholder='Type Your Message Here..' className={styles.textarea_css} />

              </div>


            </div>
          </div>

        </div>

      </div>
    </React.Fragment>
  )
}
export default OrderDashboard