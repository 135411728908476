import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from './LiveSearchMob.module.css'
const LiveSearchMob = ({
    results = [],
    value,
    onChange,
    onSelect,
    qtyFocus,
    setSearchItem,
    itemseacrhRef,
    handleRemoveClick,
    searchType,
    warehouse,
    setShowAddItem,
    cartType
}) => {
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const resultContainer = useRef(null)
    const [showResults, setShowResults] = useState(false)
    const [defaultValue, setDefaultValue] = useState("")
    const handleSelection = selectedIndex => {
        const selectedItem = results[selectedIndex]

        if (!selectedItem) return resetSearchComplete()
        else if (selectedItem.totalQty <= 0 && (searchType !== "purchase" && searchType !== "adjustment")) {
            alert("Out of stock")
        }
        // else if (itemsdetails.find(obj => obj.itemNo === selectedItem.itemNo && obj.warehouse === selectedItem.warehouse) && (searchType !== "purchase" && searchType !== "adjustment")) {
        //     alert("Already Added!")
        // }
        else if (cartType && selectedItem.cartType && (cartType !== selectedItem.cartType)) {
            alert("Please Choose Same Product Type")
        }
        else {
            onSelect && onSelect(selectedItem)
            resetSearchComplete()
            qtyFocus.current.focus()
        }
    }

    const resetSearchComplete = useCallback(() => {
        setFocusedIndex(-1)
        setShowResults(false)
    }, [])

    const handleKeyDown = e => {
        const { key, ctrlKey, keyCode, altKey } = e
        // console.log(keyCode)
        let nextIndexCount = 0

        // move down
        if (key === "ArrowDown")
            nextIndexCount = (focusedIndex + 1) % results.length

        // move up
        if (key === "ArrowUp")
            nextIndexCount = (focusedIndex + results.length - 1) % results.length

        // hide search results
        if (key === "Escape") {
            resetSearchComplete()
        }

        // select the current item
        if (key === "Enter") {
            e.preventDefault()
            setSearchItem("")
            handleSelection(focusedIndex);
            setShowResults(false)
        }
        if (ctrlKey && keyCode === 32) {
            setShowResults(true)
        }
        if (altKey && keyCode === 67) {
            setShowAddItem(true)
        }

        setFocusedIndex(nextIndexCount)
    }

    const handleChange = e => {
        if (searchType === "adjustment" && !warehouse) {
            alert("Please Select Warehouse")
        } else {
            setDefaultValue(e.target.value)
            onChange && onChange(e)
            setShowResults(true)
        }
    }

    useEffect(() => {
        if (!resultContainer.current) return

        resultContainer.current.scrollIntoView({
            block: "center"
        })
    }, [focusedIndex])

    useEffect(() => {
        if (value) setDefaultValue(value)
    }, [value])

    return (
        <React.Fragment>
            <div
                tabIndex={1}
                onBlur={() => {
                    resetSearchComplete()
                }}
                onKeyDown={handleKeyDown}
                className={styles.inputDiv}
            >
                <input
                    value={defaultValue}
                    onChange={handleChange}
                    type="text"
                    className={styles.inputSeacrh}
                    placeholder="Search your query..."
                    onClick={() => {
                        setShowResults(true)
                    }}
                    // autoFocus={true}
                    // ref={itemseacrhRef}
                />

                {showResults && (
                    <div className="w-100">
                        <div
                            className={styles.dealerCreateNew}
                            onClick={() => { setShowAddItem(true); setShowResults(false) }}>
                            Create New Item
                        </div>
                        <div onClick={() => setShowResults(true)} >
                            {results && results.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        onMouseDown={() => { handleSelection(index); setShowResults(false) }}
                                        ref={(index === focusedIndex && item.totalQty <= 0) ? resultContainer : null}
                                        className={styles.searchCard}
                                    >
                                        <img
                                            src={process.env.REACT_APP_S3URL + item.thumbnail}
                                            alt={item.itemNo}
                                            className={styles.searchcard_Image}
                                            width={56}
                                            height={76}
                                        />
                                        <div className={styles.searchItem_details}>
                                            <span>{item.product_name}</span>
                                            <b className={item.totalQty <= 0 && (searchType !== "purchase" && searchType !== "adjustment") ? styles.outqty : ""}>Item No: {item.itemNo}</b>
                                            <span className={item.totalQty <= 0 && (searchType !== "purchase" && searchType !== "adjustment") ? styles.outqty : ""}>warehouse: <b>{item.warehouse}</b></span>
                                            <span className={item.totalQty <= 0 && (searchType !== "purchase" && searchType !== "adjustment") ? styles.outqty : ""}>Qty: <span className="text-danger">{item.totalQty} {item.unit}</span></span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default LiveSearchMob