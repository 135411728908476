import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth';
import styles from './DishpatchManager.module.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '../../components/Paginantion'

const DispatchOrders = () => {
    const navigate = useNavigate();
    const [searchparams] = useSearchParams();
    const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;
    const { switchDisplay, managerId } = useAuth();
    const [cartData, setCartData] = useState([])
    const [pcartData, setPcartData] = useState([])
    const [allData, setAllData] = useState([])
    const [activeTab, setActiveTab] = useState("allorder")
    const [loading, setLoading] = useState(false)
    const [state, setState] = React.useState({
        totalPages: "",
        currentPage: 1
    });

    const { totalPages } = state;

    useEffect(() => {
        if (managerId && activeTab === "cart") {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + `dispatch/orders?page=${page}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setCartData(result.data)
                    }
                    if (result.pages) {
                        setState(prevState => ({
                            ...prevState,
                            totalPages: result.pages ? result.pages : "",
                        }))
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, page, activeTab])


    useEffect(() => {
        if (managerId && activeTab === "pcart") {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + `dispatch/partner-orders?page=${page}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setPcartData(result.data)
                    }
                    if (result.pages) {
                        setState(prevState => ({
                            ...prevState,
                            totalPages: result.pages ? result.pages : "",
                        }))
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, page, activeTab])

    useEffect(() => {
        if (managerId && activeTab === "allorder") {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(process.env.REACT_APP_URL + 'dispatch/allOrders?page=' + page, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setAllData(result.data)
                    }
                    if (result.pages) {
                        setState(prevState => ({
                            ...prevState,
                            totalPages: result.pages ? result.pages : "",
                        }))
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, page, activeTab])

    console.log(allData)

    return (

        <React.Fragment>
            <div className={`${!switchDisplay ? styles.bg_dark : styles.bg_white} ${styles.ff}`}>
                <div className={styles.complain_header}>
                    <h2 className={styles.heading}>Orders</h2>

                    {/* <div className={styles.complain_Sort_By}>
                        <label className={styles.complain_sortBy_txt}>Dispatch Manager</label>
                        <select
                            className={styles.complain_sortBy_select}
                        >
                            <option value='' hidden>View All</option>
                        </select>
                    </div> */}

                    <div className={styles.packing_header_select_days}>
                        <div className={`${styles.packing_days_select} ${activeTab === "allorder" && styles.packing_days_active}`} onClick={() => { setActiveTab("allorder") }} >All</div>
                        <div className={`${styles.packing_days_select} ${activeTab === "cart" && styles.packing_days_active}`} onClick={() => { setActiveTab("cart") }} >Cart</div>
                        <div className={`${styles.packing_days_select} ${activeTab === "pcart" && styles.packing_days_active}`} onClick={() => { setActiveTab("pcart") }}>Partner Cart</div>
                    </div>
                </div>
                {loading ? <div className={'loadingMain'}>
                    <img src='/wallicon.gif' alt='walliconGIF' />
                </div> :
                    <>
                        <div className={styles.dispatchHeightFix}>
                            <table className={styles.itemTable}>
                                <thead>
                                    <tr className={styles.item}>
                                        <th>Name & City</th>
                                        <th>Cart Type</th>
                                        <th>Date</th>
                                        <th>Order Id</th>
                                        <th>Dispatch Manager</th>
                                        <th>Order Manager</th>
                                        <th>Source</th>
                                        <th>Delivery Partner</th>
                                        <th>Status</th>
                                        <th>Last Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(activeTab === 'cart' ? 
                                    cartData : activeTab === 'pcart' ? pcartData : allData).map((val, index) =>
                                        <tr
                                            key={index}
                                            className={`${styles.item} ${val.reqStatus === "Pending" ? 'text-danger' : ""}`}
                                            title={val.reqStatus === "Pending" ? val.reqIssue: ""}
                                            onClick={() => navigate('/dispatchorders/' + val.orderId)}>
                                            <td className={styles.ordersName_truncate}
                                                title={val.storeName + ", " + val.city}
                                            ><span className={styles.hide}>{val.storeName + ", " + val.city}</span></td>
                                            <td>{val.cartType}</td>
                                            <td>{val.orderDate}</td>
                                            <td>{val.orderId}</td>
                                            <td>{val.ordermanager}</td>
                                            <td>{val.ordermanager}</td>
                                            <td>{ }</td>
                                            <td>{val.courierName}</td>
                                            <td className={`${val.status === 'Dispatched' || val.status === 'Packed' ?
                                                styles.table_txt_green : val.status === 'Not Packed' ?
                                                    styles.table_txt_red : (val.status === 'In Progress' || val.status === 'Pending') ?
                                                        styles.table_txt_yellow : val.status === 'New Order' ?
                                                            styles.table_txt_blue : ''}`}>
                                                {val.status}
                                            </td>
                                            <td>{val.lastAction} </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            total={totalPages}
                            current={page}
                        />
                    </>
                }
            </div>
        </React.Fragment>
    )
}
export default DispatchOrders;