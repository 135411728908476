import React, { useEffect } from 'react'
import modalcss from './DispatchModal.module.css'

const DispatchModal = ({ show, close, content, heading, closeBtn }) => {
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [show,])
    return show && (
        <div className={modalcss.modal_backdrop} onClick={() => { close() }}
        >
            <div className={modalcss.modal_content} onClick={e => { e.stopPropagation(); }}  >
                {(heading || closeBtn) &&
                    <div className={modalcss.modal_header}>
                        {heading && <h3>{heading}</h3>}
                        {closeBtn &&
                            <button
                                type="button"
                                className={modalcss.modal_close_button}
                                onClick={() => close()}
                            >
                                <span>&times;</span>
                            </button>}
                    </div>
                }

                {content}
            </div>
        </div >
    )
}

export default DispatchModal