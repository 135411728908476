import React, { createContext, useState } from 'react'

const AuthContext = createContext({});

const AuthProvider = (props) => {

    const [switchDisplay, setSwitchDisplay] = useState(false)
    const [marketingRefresh, setMarketingRefresh] = useState(false)
    const [designation, setDesignation] = useState("")
    const [managerId, setManagerId] = useState("")
    const [managerName, setManagerName] = useState("")
    const [loggedIn, setLoggedIn,] = useState(false)
    const [isRefresh, setIsRefresh,] = useState(false)
    const [updateDelete, setUpdateDelete] = useState(0);


    const authContextValue = {
        switchDisplay, setSwitchDisplay,
        marketingRefresh, setMarketingRefresh,
        designation, setDesignation,
        managerId, setManagerId,
        managerName, setManagerName,
        loggedIn, setLoggedIn,
        updateDelete, setUpdateDelete,
        isRefresh, setIsRefresh,
    }
    return <AuthContext.Provider value={authContextValue} {...props} />
}

const useAuth = () => React.useContext(AuthContext)
export {
    AuthProvider, useAuth
}