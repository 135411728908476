import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './components/context/Auth';
import Headers from './components/Headers';
import LeftMenu from './components/LeftMenu';
import AddProduct from './pages/products/AddProduct';
import SuperAdmin from './pages/dashboard/SuperAdmin';
import Kyc from './pages/kyc/Kyc'
import Dealers from './pages/kyc/Dealers';
import Products from './pages/products/Products';
import Items from './pages/products/Items';
import AddItem from './pages/products/AddItem';
import Home from './pages/Home';
import ContentDashboard from './pages/dashboard/ContentDashboard';
import OrderDashboard from './pages/dashboard/OrderDashboard';
import InventoryDashboard from './pages/dashboard/InventoryDashboard';
import DispatchDasboard from './pages/dashboard/DispatchDasboard';
import Orders from './pages/ordermanager/Orders';
import SaleOrder from './pages/ordermanager/SaleOrder';
import SaleOrderInovice from './pages/invoices/SaleOrderInovice';
import Inventory from './pages/inventorymanager/Inventory';
import InventoryCollections from './pages/inventorymanager/InventoryCollections';
import CollectionInfo from './pages/inventorymanager/CollectionInfo';
import Purchase from './pages/inventorymanager/Purchase';
import OrderDealers from './pages/ordermanager/OrderDealers';
import Adjustment from './pages/inventorymanager/Adjustment';
import PurchaseInovice from './pages/invoices/PurchaseInvoice';
import AdjustmentOrders from './pages/inventorymanager/AdjustmentOrders';
import PartnerOrders from './pages/ordermanager/PartnerOrders';
import PurchaseOrders from './pages/inventorymanager/PurchaseOrders';
import AdjustmentInvoice from './pages/invoices/AdjustmentInvoice';
import DispatchOrders from './pages/dispatchmanager/DispatchOrders';
import PackingVoucher from './pages/dispatchmanager/PackingVoucher';
import Packers from './pages/dispatchmanager/Packers';
import Packer from './pages/dispatchmanager/Packer';
import CreatePackers from './pages/dispatchmanager/CreatePackers';
import PackingOrders from './pages/packingmanager/PackingOrders';
import PackingOrder from './pages/packingmanager/PackingOrder';
import PackingHeader from './components/PackingHeader';
import Estimate from './pages/ordermanager/Estimate';
// import AllOrders from './pages/ordermanager/AllOrders';
import TransferStock from './pages/inventorymanager/TransferStock';
import EstimateOrders from './pages/ordermanager/EstimateOrders';
import TransferOrders from './pages/inventorymanager/TransferOrders';
import Managers from './pages/managers/Managers';
import AddManager from './pages/managers/AddManager';
import Complaints from './pages/superadmin/Complaints';
import AllOrders from './pages/ordermanager/AllOrders';
import Recipts from './pages/superadmin/Recipts';
import EstimateInvoice from './pages/invoices/EstimateInvoice';
import Accounts from './pages/superadmin/Accounts';
import DispatchDasboardMob from './mobile/pages/DispatchDasboardMob';
import DispatchOrder from './mobile/pages/DispatchOrder';
import Delivery from './pages/superadmin/Delivery';
import MobAllOrders from './mobile/pages/MobAllOrders';
import SaleOrderMob from './mobile/pages/SaleOrderMob';
import OutSideInventory from './pages/inventorymanager/OutSideInventory';
import OutSideIvtColl from './pages/inventorymanager/OutSideIvtColl';
import OutSideIvtCollInfo from './pages/inventorymanager/OutSideIvtCollInfo';
import MobEstimate from './mobile/pages/MobEstimate';
import MobEstimateOrders from './mobile/pages/MobEstimateOrders';
import MobPurchaseOrders from './mobile/pages/MobPurchaseOrders';
import MobPurchase from './mobile/pages/MobPurchase';
import ViewDispatchDetailsMob from './mobile/pages/ViewDispatchDetailsMob';
import ViewDispatchDetails from './pages/dispatchmanager/ViewDispatchDetails';
import InventoryMob from './mobile/pages/InventoryMob';
import InventoryCollectionsMob from './mobile/pages/InventoryCollectionsMob';
import CollectionInfoMob from './mobile/pages/CollectionInfoMob';
import OutSideInventoryMob from './mobile/pages/OutSideInventoryMob';
import OutSideIvtCollMob from './mobile/pages/OutSideIvtCollMob';
import OutSideIvtCollInfomob from './mobile/pages/OutSideIvtCollInfomob';
import TransferOrdersMob from './mobile/pages/TransferOrdersMob';
import ReciptsMob from './mobile/pages/ReciptsMob';
import Warehouse from './pages/superadmin/Warehouse';
import AddFaq from './pages/products/AddFaq';
import Jobs from './pages/superadmin/career/Jobs';
import Job from './pages/superadmin/career/Job';
import Applicants from './pages/superadmin/career/Applicants';
import Enquires from './pages/superadmin/Enquires';

function App() {
  const { loggedIn, setLoggedIn, designation, setDesignation, setManagerId, setManagerName } = useAuth()
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    // const des = sessionStorage.getItem("designation")
    const mid = sessionStorage.getItem("managerId")
    const mname = sessionStorage.getItem("name")
    if (designation) {
      setLoggedIn(true)
    }
    if (mid) {
      setManagerId(mid)
    }
    if (mname) {
      setManagerName(mname)
    }

  }, [designation, setLoggedIn, setManagerId, setManagerName])

  var designations = sessionStorage.getItem("designation");
  useEffect(() => {
    if (designations) {
      setDesignation(designations.split(',')[0]);
    }
  }, [setDesignation, designations])

  useEffect(() => {
    window.addEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
    window.removeEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
  }, [])

  return (
    <React.Fragment>

      {!loggedIn ? <BrowserRouter><Routes><Route path='/' element={<Home />} /></Routes></BrowserRouter> :
        <div className={width > 1084 ? 'main_container' : ""}>
          <BrowserRouter>
            {width > 1084 ? <Headers /> : <PackingHeader />}
            <div className={width > 1084 ? "content" : ""}>
              {width > 1084 ? <LeftMenu /> : ""}
              {/* {designation !== "packingmanager" ?
            ( width<1084 && designation === "dispatchmanager")? '': <Headers />: <PackingHeader />}
            <div className={designation !== "packingmanager" ? "content" : "PackingContent"}>
              {(designation !== "packingmanager") && 
              (width<1084 && designation === "dispatchmanager"?'':<LeftMenu />)} */}

              {designation === "superadmin" &&
                <Routes>
                  <Route path="/dashboard" element={<SuperAdmin />} />
                  <Route path="/users/:id" element={<Kyc />} />
                  <Route path="/users" element={<Dealers />} />
                  <Route path="/addproduct" element={<AddProduct />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/addfaq/:product" element={<AddFaq />} />
                  <Route path="/items/:product" element={<Items />} />
                  <Route path="/item/:product" element={<AddItem />} />
                  <Route path="/orders" element={width < 1084 ? <MobAllOrders /> : <AllOrders />} />
                  <Route path="/cartorders" element={<Orders />} />
                  <Route path="/partnerorders" element={<PartnerOrders />} />
                  <Route path="/purchaseorders" element={width < 1084 ? <MobPurchaseOrders /> :<PurchaseOrders />} />
                  <Route path="/adjustmentorders" element={<AdjustmentOrders />} />
                  <Route path="/estimateorders" element={width < 1084 ? <MobEstimateOrders /> :<EstimateOrders />} />
                  <Route path="/transferorders" element={width < 1084 ? <TransferOrdersMob /> : <TransferOrders />} />
                  <Route path="/orders/saleorder" element={width < 1084 ? <SaleOrderMob /> : <SaleOrder />} />
                  <Route path="/orders/estimate" element={width < 1084 ? <MobEstimate /> : <Estimate />} />
                  <Route path="/orders/saleinvoice" element={<SaleOrderInovice />} />
                  <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />
                  <Route path="/inventory" element={width < 1084 ? <InventoryMob /> : <Inventory />} />
                  <Route path="/inventory/:product" element={width < 1084 ? <InventoryCollectionsMob /> :<InventoryCollections />} />
                  <Route path="/inventory/:product/:collection" element={width < 1084 ? <CollectionInfoMob /> :<CollectionInfo />} />
                  <Route path="/orders/purchase" element={width < 1084 ? <MobPurchase /> : <Purchase />} />
                  <Route path="/orders/transfer" element={<TransferStock />} />
                  <Route path="/orders/purchaseinvoice/:voucherNo" element={<PurchaseInovice />} />
                  <Route path="/orders/adjustmentinvoice/:voucherNo" element={<AdjustmentInvoice />} />
                  <Route path="/orders/adjustment" element={<Adjustment />} />
                  <Route path="/orders/dealers" element={<OrderDealers />} />
                  <Route path="/dispatchorders" element={<DispatchOrders />} />
                  <Route path="/dispatchorders/:orderId" element={width < 1084 ? <DispatchOrder /> : <PackingVoucher />} />
                  <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
                  <Route path="/packingorder" element={<PackingOrders />} />
                  <Route path="/packingorder/:orderId" element={<PackingOrder />} />
                  <Route path="/packers" element={<Packers />} />
                  <Route path="/packer/:pmId" element={<Packer />} />
                  {/* <Route path="/packer" element={<Packer />} /> */}
                  <Route path="/createpacker" element={<CreatePackers />} />
                  <Route path="/managers" element={<Managers />} />
                  <Route path="/manager" element={<AddManager />} />
                  <Route path="/complaints" element={<Complaints />} />
                  <Route path="/dispatchdashboard" element={width < 1084 ? <DispatchDasboardMob /> : <DispatchDasboard />} />
                  <Route path="/recipts" element={width < 1084 ? <ReciptsMob /> : <Recipts />} />
                  <Route path="/accounts" element={<Accounts />} />
                  <Route path="/delivery" element={<Delivery />} />
                  <Route path="/outsideinventory" element={width < 1084 ? <OutSideInventoryMob /> : <OutSideInventory />} />
                  <Route path="/outsideinventory/:product" element={width < 1084 ? <OutSideIvtCollMob /> : <OutSideIvtColl />} />
                  <Route path="/outsideinventory/:product/:collection" element={width < 1084 ? <OutSideIvtCollInfomob /> : <OutSideIvtCollInfo />} />
                  <Route path="/warehouses" element={<Warehouse />} />
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/job" element={<Job />} />
                  <Route path="/applicants" element={<Applicants />} />
                  <Route path="/enquires" element={<Enquires />} />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              }
              {designation === "contentmanager" &&
                <Routes>
                  <Route path="/dashboard" element={<ContentDashboard />} />
                  <Route path="/addproduct" element={<AddProduct />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/items/:product/" element={<Items />} />
                  <Route path="/item/:product/" element={<AddItem />} />
                  <Route path="/dealers/:id" element={<Kyc />} />
                  <Route path="/dealers" element={<Dealers />} />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              }
              {designation === "ordermanager" &&
                <Routes>
                  <Route path="/dashboard" element={<OrderDashboard />} />
                  <Route path="/orders" element={width < 1084 ? <MobAllOrders /> : <AllOrders />} />
                  <Route path="/cartorders" element={<Orders />} />
                  <Route path="/partnerorders" element={<PartnerOrders />} />
                  <Route path="/estimateorders" element={width < 1084 ? <MobEstimateOrders /> :<EstimateOrders />} />
                  <Route path="/orders/saleorder" element={width < 1084 ? <SaleOrderMob /> : <SaleOrder />} />
                  <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
                  <Route path="/orders/saleinvoice" element={<SaleOrderInovice />} />
                  <Route path="/orders/dealers" element={<OrderDealers />} />
                  <Route path="/orders/estimate" element={width < 1084 ? <MobEstimate /> : <Estimate />} />
                  <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />
                  {/* <Route path="/order/:orderId" element={<Order />} /> */}
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              }
              {designation === "inventorymanager" &&
                <Routes>
                  <Route path="/dashboard" element={<InventoryDashboard />} />
                  <Route path="/orders" element={width < 1084 ? <MobAllOrders /> : <AllOrders />} />
                  <Route path="/orders/saleorder" element={width < 1084 ? <SaleOrderMob /> : <SaleOrder />} />
                  <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
                  <Route path="/cartorders" element={<Orders />} />
                  <Route path="/partnerorders" element={<PartnerOrders />} />
                  <Route path="/purchaseorders" element={width < 1084 ? <MobPurchaseOrders /> : <PurchaseOrders />} />
                  <Route path="/adjustmentorders" element={<AdjustmentOrders />} />
                  {/* <Route path="/estimateorders" element={<EstimateOrders />} /> */}
                  <Route path="/estimateorders" element={width < 1084 ? <MobEstimateOrders /> : <EstimateOrders />} />
                  <Route path="/transferorders" element={width < 1084 ? <TransferOrdersMob /> : <TransferOrders />} />
                  {/* <Route path="/orders/saleorder" element={<SaleOrder />} /> */}
                  <Route path="/orders/saleinvoice" element={<SaleOrderInovice />} />
                  <Route path="/inventory" element={width < 1084 ? <InventoryMob /> : <Inventory />} />
                  <Route path="/inventory/:product" element={width < 1084 ? <InventoryCollectionsMob /> :<InventoryCollections />} />
                  <Route path="/inventory/:product/:collection" element={width < 1084 ? <CollectionInfoMob /> :<CollectionInfo />} />
                  <Route path="/orders/purchase" element={width < 1084 ? <MobPurchase /> : <Purchase />} />
                  <Route path="/orders/purchaseinvoice/:voucherNo" element={<PurchaseInovice />} />
                  <Route path="/orders/adjustmentinvoice/:voucherNo" element={<AdjustmentInvoice />} />
                  <Route path="/orders/adjustment" element={<Adjustment />} />
                  {/* <Route path="/orders/estimate" element={<Estimate />} /> */}
                  <Route path="/orders/estimate" element={width < 1084 ? <MobEstimate /> : <Estimate />} />
                  <Route path="/orders/transfer" element={<TransferStock />} />
                  <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />
                  <Route path="/outsideinventory" element={width < 1084 ? <OutSideInventoryMob /> : <OutSideInventory />} />
                  <Route path="/outsideinventory/:product" element={width < 1084 ? <OutSideIvtCollMob /> : <OutSideIvtColl />} />
                  <Route path="/outsideinventory/:product/:collection" element={width < 1084 ? <OutSideIvtCollInfomob /> : <OutSideIvtCollInfo />} />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              }
              {designation === "dispatchmanager" &&
                <Routes>
                  <Route path="/dashboard" element={width < 1084 ? <DispatchDasboardMob /> : <DispatchDasboard />} />
                  <Route path="/dispatchorders" element={<DispatchDasboard />} />
                  <Route path="/dispatchorders/:orderId" element={width < 1084 ? <DispatchOrder /> : <PackingVoucher />} />
                  <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
                  <Route path="/packers" element={<Packers />} />
                  <Route path="/packer/:pmId" element={<Packer />} />
                  <Route path="/createpacker" element={<CreatePackers />} />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              }
              {designation === "paymentmanager" &&
                <Routes>
                  <Route path="/dashboard" element={width < 1084 ? <ReciptsMob /> : <Recipts />} />
                  <Route path="/orders/saleorder" element={width < 1084 ? <SaleOrderMob /> : <SaleOrder />} />
                  <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              }
              {designation === "packingmanager" &&
                <Routes>
                  <Route path="/dashboard" element={<PackingOrders />} />
                  {/* <Route path="/packingorder" element={<PackingOrders />} /> */}
                  <Route path="/packingorder/:orderId" element={<PackingOrder />} />
                </Routes>
              }
            </div>
          </BrowserRouter>
        </div>
      }
    </React.Fragment >
  );
}

export default App;

export const NoMatch = () => {
  let location = useLocation();
  const { managerId, switchDisplay } = useAuth()
  const navigate = useNavigate();
  useEffect(() => {
    if (managerId) {
      navigate('/dashboard')
    } else {
      navigate('/')
    }
  })
  return (
    <div className={`${switchDisplay ? 'bg_white' : 'bg_dark'} App`}>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  )
}