import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AddDealerModal from '../../components/AddDealerModal'
import { GreenOutButton, } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth'
import { AddDealerIcon, Ban, Call, Checked, Messanger, Search, WhatsApp } from '../../components/icons/Icon'
import Pagination from '../../components/Paginantion'

// import { AddDealerModal } from '../ordermanager/SaleOrders'
import styles from "../kyc/Dealer.module.css"

const Managers = () => {

    const navigate = useNavigate();
    const [searchparams] = useSearchParams();
    const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;

    const [showAddDealer, setShowAddDealer] = useState(false)
    const [dealerData, setDealerData] = useState(false)
    const { managerId, } = useAuth()
    const [managersData, setManagersData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener('resize', () => {
            return setWidth(window.innerWidth);
        })
        window.removeEventListener('resize', () => {
            return setWidth(window.innerWidth);
        })
    }, [])
    const [searchDealerData, setSearchDealerData] = useState({
        searchDealerName: '',
        sortByStatus: '',
    })
    const [state, setState] = React.useState({
        totalPages: "",
        currentPage: 1
    });

    const { totalPages, } = state;

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("ch-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "keyword": searchDealerData.searchDealerName,
                "sortKey": searchDealerData.sortByStatus
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + `managers/allManagersList?page=${page}&limit=${width > 1600 ? '18' : '15'}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.managers) {
                            setLoading(false)
                            setManagersData(result.managers)
                        }
                        if (result.pages) {
                            setState(prevState => ({
                                ...prevState,
                                totalPages: result.pages ? result.pages : "",
                            }))
                        }
                    }
                })
                .catch(error => console.log('error', error));

        }
    }, [managerId, searchDealerData.sortByStatus, searchDealerData.searchDealerName, page, width])

    useEffect(() => {
        if (dealerData.dealerId) {
            navigate("/dealers/" + dealerData.dealerId)
        }
    }, [dealerData.dealerId, navigate])

    return (
        <React.Fragment>

            <AddDealerModal
                show={showAddDealer}
                close={setShowAddDealer}
                setData={setDealerData}
            />

            <div className={styles.right_main}>
                <div className={styles.addDealers}>
                    <h1 className={styles.dealers}>
                        Managers
                    </h1>
                    <GreenOutButton btnType="button" title={<><AddDealerIcon />&nbsp;&nbsp;Add Manager</>} css={styles.addDealerButton}
                        handleSubmit={() => { navigate('/manager') }}
                    //   handleSubmit={() => { setShowAddDealer(!showAddDealer) }}
                    />
                </div>
                {
                    loading ? <div className={'loadingMain'}>
                        <img src='/wallicon.gif' alt='walliconGIF' />
                    </div> :

                        <div className={styles.Dealer_main_container}>
                            <div className={styles.dealer_header}>
                                <div
                                    className={styles.dealer_search_div}>
                                    <input type="search"
                                        placeholder='Search'
                                        className={styles.dealer_search}
                                        value={searchDealerData.searchDealerName}
                                        onChange={(e) => setSearchDealerData({ ...searchDealerData, searchDealerName: e.target.value })}
                                    />
                                    <Search color="#ffffff" css={styles.dealer_search_icon} />
                                </div>
                                <div className={styles.dealer_header_right}>
                                    <div className=''>
                                        <label className={styles.dealer_sortBy_txt}>Sort By </label>
                                        <select
                                            className={styles.dealer_sortBy_select}
                                            value={searchDealerData.sortByStatus}
                                            onChange={(e) => setSearchDealerData({ ...searchDealerData, sortByStatus: e.target.value })}
                                        >
                                            <option className={styles.dealer_sortBy_color} value="">View All</option>
                                            <option className={styles.dealer_sortBy_color} value="Referral">Referral</option>
                                            <option className={styles.dealer_sortBy_color} value="Widthout OTP">Widthout OTP</option>
                                            <option className={styles.dealer_sortBy_color} value="Managers Created">Managers Created</option>
                                            <option className={styles.dealer_sortBy_color} value="Self Created">Self Created</option>
                                            <option className={styles.dealer_sortBy_color} value="New">New</option>
                                            <option className={styles.dealer_sortBy_color} value="newestfirst">Newest First</option>
                                            <option className={styles.dealer_sortBy_color} value="Pending">Not Verified</option>
                                            <option className={styles.dealer_sortBy_color} value="Verified">Verified</option>
                                            <option className={styles.dealer_sortBy_color} value="Ascending">Ascending</option>
                                            <option className={styles.dealer_sortBy_color} value="Descending">Descending</option>
                                        </select>
                                    </div>
                                    {/* <div className={styles.dealer_header_select_days}>
                            <div className={`${styles.dealer_days_select} ${styles.days_active}`}>All</div>
                            <div className={styles.dealer_days_select}>15 Days</div>
                            <div className={styles.dealer_days_select}>15-30 Days</div>
                            <div className={styles.dealer_days_select}>30 Days</div>
                         </div> */}
                                </div>
                            </div>
                            <div className={styles.dealer_main_div}>
                                {managersData && managersData.map((item, index) =>
                                    <div className={styles.dealer_card} key={index}>
                                        <div className={styles.dealer_card_div}>
                                            <div onClick={() => { navigate('/manager?manager_Id=' + item._id) }} className={styles.cursor_pointer} >
                                                <div className={styles.dealer_status} >


                                                    {item.status === "active" ? <Checked color="#1FFC33" css={styles.dealer_status_icon} />
                                                        : <Ban color="#F93E3E" css={styles.dealer_status_icon} />
                                                    }
                                                </div>


                                                {!item.profileImage ?
                                                    <div className={styles.name_char}><span className={styles.name_char_mb}>{item && item.name.charAt(0).toUpperCase()}</span></div>
                                                    :
                                                    <img src={process.env.REACT_APP_S3URL + item.profileImage} className={styles.dealer_image} alt='dealer_image' />
                                                }

                                                <h4 className={styles.managerName}>{item.name}</h4>
                                                <div className={styles.dealeStore_owner}>{item.designation}</div>

                                            </div>
                                            <div className={styles.dealer_btn_div}>
                                                <button className={styles.dealer_Btn}>
                                                    <Messanger color="#ffffff" width={16} />
                                                </button>
                                                <a href={'tel:+91' + item.mobile} className={styles.dealer_Btn}>
                                                    <Call color="#ffffff" width={16} />
                                                </a>
                                                <a target="_blank" rel="noreferrer" href={"https://wa.me/+91" + item.whatsapp} className={styles.dealer_Btn}>
                                                    <WhatsApp width={16} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Pagination
                                total={totalPages}
                                current={page}
                            />
                        </div>
                }
            </div>
        </React.Fragment>
    )
}

export default Managers